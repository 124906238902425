import {
  AgmCoreModule,
  LAZY_MAPS_API_CONFIG,
  LazyMapsAPILoaderConfigLiteral,
} from '@agm/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GoogleMapsService } from '../apis/GoogleMaps.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicMapsAPILoaderConfig
  implements LazyMapsAPILoaderConfigLiteral
{
  public apiKey: string;
  public libraries: string[];
  public language: string;

  constructor(private googleMapsService: GoogleMapsService) {
    this.libraries = ['places', 'drawing', 'geometry'];
    this.language = localStorage.lang || 'en';
  }

  loadApiKey(): Observable<string> {
    const endpoint = 'geocode/json'; // You can adjust this according to your need
    const params = ''; // Customize with parameters if needed

    return this.googleMapsService.getSignedUrl(endpoint, params).pipe(
      map((response) => {
        const signedUrl = response.signedUrl;
        const url = new URL(signedUrl);
        return url.searchParams.get('key')!;
      })
    );
  }
}
