import { cloneDeep } from 'lodash';
import { FormatNumber } from './../../../../utils/number_formatter';
import { QuestionsUtils } from 'src/app/utils/dynamic-forms/question_utils';
import { MORPH_Product } from '../../../../utils/consts';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IonGrid, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LotAPI } from 'src/app/apis/lot.service';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { CoffeGenSelectorComponent } from '../../widgets/coffe-gen-selector/coffe-gen-selector.component';
import { FormGroup } from '@angular/forms';
import { QuestionBase } from 'src/app/utils/dynamic-forms/QuestionBase';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import { loadPlots } from 'src/app/utils/states-management/actions/plots.action';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';

interface FormConfig {
  is_form_rendered: boolean;
}

@Component({
  selector: 'app-production-editor',
  templateUrl: './porduction-editor.component.html',
  styleUrls: ['./porduction-editor.component.scss'],
})
export class PorductionEditorComponent implements OnInit, OnChanges {
  @ViewChild(IonGrid, { static: true }) content;
  @Input() isDashboardView;
  @Input() production;
  @Input() title = '';
  @Input() modalController;
  @Input() production_superficie = 0;
  total_percent = 0;
  progressModal;
  @Input() form: FormGroup;
  @Input() questions;
  formNode;
  fromObsevable$: Subscription;
  formConfig: FormConfig = {
    is_form_rendered: false,
  };
  @Input() genetics;
  constructor(
    private progressDialog: LoadingController,
    private secondaryModalController: ModalController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private lotAPI: LotAPI,
    private questionUtils: QuestionsUtils,
    private currencyPipe: FormatNumber,
    private store: Store<{ owner: Owner }>,
    private customPickerOption: PickerOptionFormater
  ) {}
  showGenetics = false;
  async ngOnInit() {
    // this.form =
    //   await this.questionUtils.prepareForm(this.production);
    this.formNode = document.getElementById(
      'form_' + this.production.morphable_id
    );

    this.form
      .get('distance_between_plants')
      ?.valueChanges.subscribe(async (value) => {
        if (value != null) {
          this.form
            .get('coffee_trees_number')
            .setValue(
              this.currencyPipe.transform(
                this.production_superficie.toFixed(2)
              ) +
                ' ha * ' +
                value +
                ' ' +
                this.translateService.instant('tree_ha') +
                ' = ' +
                Math.round(value * this.production_superficie) +
                ' ' +
                this.translateService.instant('trees')
            );
        }
      });

    this.form.get('is_productive').valueChanges.subscribe((value) => {
      this.questions.forEach((element: QuestionBase<string>) => {
        if (element.key != 'is_productive')
          this.form.get(element.key).setValue(null);
      });
    });

    this.setTitle();
    this.translateService.onLangChange.subscribe((lang) => {
      this.setTitle();
    });
    if (
      this.production?.plant_genetics &&
      typeof this.production?.plant_genetics == 'string'
    ) {
      this.genetics[this.production.id] = JSON.parse(
        this.production.plant_genetics
      );
    }
    if (Array.isArray(this.genetics))
      this.genetics[this.production?.id]?.forEach((element: any) => {
        this.total_percent += !isNaN(element?.percent_in_lot)
          ? element?.percent_in_lot
          : 0;
      });
    if (this.genetics && Array.isArray(this.genetics)) {
      this.showGenetics = true;
    }

    this.progressModal = await this.progressDialog.create({});
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.production_superficie && this.form) {
      this.form
        .get('coffee_trees_number')
        .setValue(
          this.currencyPipe.transform(this.production_superficie.toFixed(2)) +
            ' ha * ' +
            changes.production_superficie.currentValue +
            ' ' +
            this.translateService.instant('tree_ha') +
            ' = ' +
            Math.round(
              changes.production_superficie.currentValue *
                this.production_superficie
            ) +
            ' ' +
            this.translateService.instant('trees')
        );
    }
  }
  setTitle() {
    if (this.production.morphable_type == MORPH_Product)
      this.title = this.translateService.instant(
        'product.' + this.production.morphable_id
      );
    else
      this.title = this.translateService.instant(
        'material_category.' + this.production.morphable_id
      );
  }
  CanSubmit = false;
  checkCanSubmit() {
    this.production.plant_genetics = this.genetics;

    let prod = { ...this.production };
    prod.form_answer = this.form.getRawValue();
    this.CanSubmit = false;
    // if (
    //   this.total_percent != 100 &&
    //   Array.isArray(prod.plant_genetics) &&
    //   prod.plant_genetics.length > 0
    // ) {
    //   this.progressDialog.dismiss();
    //   this.showError('errors.not_100_cafe');
    //   return false;
    // }

    // if (
    //   (prod.form_answer.is_productive == false &&
    //     prod.form_answer.type_of_area == 'sowing_and_pruning' &&
    //     prod.form_answer.pruning_percent + prod.form_answer.siembra_percent !=
    //       100) ||
    //   (prod.form_answer.is_productive == true &&
    //     prod.form_answer.last_renew_reason == 'sowing_and_pruning' &&
    //     prod.form_answer.siembra_percent_productive +
    //       prod.form_answer.pruning_percent_productive !=
    //       100)
    // ) {
    //   this.progressDialog.dismiss();
    //   this.showError('errors.not_100_sowing_and_pruning');
    //   return false;
    // }
    // return;

    if (!prod.plant_genetics || prod.plant_genetics?.length < 1) {
      this.progressDialog.dismiss();
      this.showError('label.required_input_no_html');
      return false;
    }

    this.CanSubmit = true;
    return true;
  }
  public async form_answer(
    prodProps = { ...this.production },
    modalMain = null
  ) {
    this.production.plant_genetics = this.genetics;
    let prod = { ...this.production };
    prod.id = prodProps.id;
    prod.form_answer = this.form.getRawValue();
    return prod;

    if (this.checkCanSubmit()) {
      return prod;

      // this.lotAPI.updateProduction(prod).subscribe(
      //   (val) => {
      //     this.progressModal.present();
      //     this.questionUtils.resetForm();
      //     this.store.dispatch(loadPlots());
      //     this.progressModal.dismiss();
      //     // this.modalController.dismiss({ prod: val });
      //     modalMain.dismiss();
      //   },
      //   (e) => {
      //     this.progressModal.dismiss();
      //     // this.modalController.dismiss();
      //   }
      // );
    }
  }

  async showError(message: string) {
    await this.toastUtils.toastBuilder(
      await this.translateService.instant(message),
      'top',
      '',
      'danger',
      500
    );
  }

  async addCoffeProd() {
    const modal = await this.secondaryModalController.create({
      component: CoffeGenSelectorComponent,
      cssClass: ['my-custom-modal-css', 'modal-wrapper'],
      componentProps: {
        production: this.production,
        products: this.production.morphable.products,
        isDashboardView: this.isDashboardView,
        modalController: this.secondaryModalController,
        previouslySelected: this.production.plant_genetics,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (!data.canceled) {
      this.genetics[this.production.id] = data.products;
      if (
        this.genetics &&
        this.genetics[this.production.id].length > 0 &&
        this.genetics[this.production.id].length != 1
      ) {
        this.genetics[this.production.id].forEach((element: any) => {
          element['percent_in_lot'] = 0;
        });
        this.total_percent = 0;
        this.showGenetics = true;
      } else if (this.genetics[this.production.id].length == 1) {
        this.genetics[this.production.id].forEach((element: any) => {
          element['percent_in_lot'] = 100;
        });
        this.total_percent = 100;
        this.showGenetics = true;
      }
      this.scrollBottom();
    }
  }

  cancel() {
    this.modalController.dismiss();
  }

  changed() {}

  coffeTreeEventChange(value) {
    this.production.coffee_trees_number = value;
  }
  async handelPickerClicked(selected_gens) {
    let { data } = await this.customPickerOption.openPicker();
    // selected_gens.percent_in_lot = data[0].value;
    this.total_percent = 0;
    this.genetics[this.production?.id].forEach((element) => {
      if (element.id == selected_gens.id) {
        element.percent_in_lot = data[0].value;
      }
      this.total_percent += parseInt(element.percent_in_lot);
    });
    // this.percentage = data[0].value;
    // this.question.value = data[0].value;
    // this.form.get(this.question.key).setValue(data[0].value)
  }
  distanceBetweenPlantsEventChange(value) {
    this.production.distance_between_plants = value;
  }

  scrollBottom() {
    try {
      setTimeout(
        () => (this.content.el.scrollTop += this.content.el.scrollHeight)
      );
    } catch (err) {}
  }
}
