import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
  DoCheck,
  AfterViewChecked,
} from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewChecked {
  private screenHeight = 0;
  private oldScroll = 0;
  @Input('appAutofocus') content: any;
  @Input('openedFromCashflow') openedFromCashflow = false;

  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  ngAfterViewChecked(): void {
    const contentHeight = this.elementRef.nativeElement.scrollHeight;
    if (
      !this.content ||
      !this.content?.id ||
      (this.content?.id &&
        this.content?.production_per_farm &&
        !this.content?.form_answer &&
        !this.content?.accounting_entry_categories)
    ) {
      this.ngZone.runOutsideAngular(() => {
        let interval = setInterval(() => {
          if (contentHeight !== this.oldScroll) {
            this.elementRef.nativeElement.scroll({
              top: contentHeight + 700,
              behavior: 'smooth',
            });
            this.oldScroll = contentHeight;
            clearInterval(interval);
          }
        }, 300);
      });
    }
  }
}
