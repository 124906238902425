import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  DoCheck,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { UserInfo } from 'src/app/apis/users-info.service';
import {
  CashFlow,
  isArbolesTransaction,
} from 'src/app/models/cash-flow/cashFlow';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import {
  PRODCUTION_ARBOLES_CAFE,
  PRODUCTION_ARBOLES,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  CASHFLOW_FIRST_SALE_COFFEE_ID,
  CASHFLOW_SECOND_COFFEE_SELLS_ID,
  COMPRA_ARBOLES_CASHFLOW_CATEGORIES,
  COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
  VENTA_ARBOLES_CASHFLOW_CATEGORIES,
  VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
  PRODUCT_MORPHABLE,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import {
  convertToMetricSystem,
  convetToOtherUnit,
  getQtyUpdateInsertion,
} from 'src/app/utils/unit-convert';
import { dev } from '../../../../config/offlines_keys.json';
import unit from '../../../../config/unit.json';
import cashflowCategories from '../../../../config/material_categories_material_cashflow.json';
import { UnifiedCashflowInputComponentComponent } from '../../unified-cashflow-input-component/unified-cashflow-input-component.component';
import { lastValueFrom, take } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
interface FormConfig {
  show_propiedas_value_tracker: boolean;
  unavalibe_qty: boolean;
  show_arboles_section: boolean;
}

@Component({
  selector: 'app-goods-related-to-cashflow',
  templateUrl: './goods-related-to-cashflow.component.html',
  styleUrls: ['./goods-related-to-cashflow.component.scss'],
})
export class GoodsRelatedToCashflowComponent
  implements OnInit, DoCheck, OnChanges
{
  @Input() access_token;
  @Input() owner;
  @Input() model_required_object;
  @Input() cashflow;
  @Output() cashflowChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() showFormChange: EventEmitter<boolean> = new EventEmitter();
  @Output() redirectToCoffeProd: EventEmitter<void> = new EventEmitter();
  units = unit.all;
  desiredModel;
  usedQty;
  availbleQTY;
  qty_total_label;
  preselect_production: Array<ProductionsPerfarm>;
  selected_product: ProductionsPerfarm;
  formConfing: FormConfig = {
    show_propiedas_value_tracker: false,
    unavalibe_qty: false,
    show_arboles_section: false,
  };
  lang;
  COMPRA_ARBOLES_CASHFLOW_CATEGORIES = COMPRA_ARBOLES_CASHFLOW_CATEGORIES;
  COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES =
    COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES;
  PERDIDA_ARBOLES_CASHFLOW_CATEGORIES = PERDIDA_ARBOLES_CASHFLOW_CATEGORIES;
  VENTA_ARBOLES_CASHFLOW_CATEGORIES = VENTA_ARBOLES_CASHFLOW_CATEGORIES;
  VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES =
    VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES;
  coffeeGoods;
  PRODCUTION_ARBOLES_CAFE = PRODCUTION_ARBOLES_CAFE;
  PRODUCTION_ARBOLES = PRODUCTION_ARBOLES;

  goodsList;
  initial_comment = 0;
  constructor(
    private goodsAPI: GoodsAPI,
    private modalCtrl: ModalController,
    private userInfo: UserInfo,
    private productionAPI: ProductionAPI,
    private store:Store
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (this.cashflow.product_id && this.preselect_production) ||
      this.cashflow.material_product_id
    ) {
      this.getGood();
    }
  }

  async ngOnInit() {
    await this.getGood();
    this.lang = await this.userInfo.getSelectedLangage();
    this.fetchProduction();
    if (
      this.cashflow.cash_flow_expense_id ==
        COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES ||
      this.cashflow.cash_flow_categorie_id ==
        VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES
    ) {
      this.cashflow.product_id = this.model_required_object.id; // coffe
    }
    if (this.cashflow.id) {
      this.cashflow.initial_value = this.cashflow.quantity;
      this.cashflow.initial_unit = this.cashflow.unity;
    }
    this.setLabels();
  }

  fetchProduction() {
    this.productionAPI
      .getAllFarmProduction()
      .subscribe((value: Array<ProductionsPerfarm>) => {
        this.preselect_production = value;
      });
  }

  setLabels() {
    if (this.cashflow.is_a_loss) {
      this.qty_total_label = 'Cantidad restante';
    } else if (this.cashflow.is_a_loss === false) {
      this.qty_total_label = 'Cantidad total';
    }

    if (this.cashflow.is_income) {
      this.qty_total_label = 'Cantidad total';
    } else if (this.cashflow.is_income === false) {
      this.qty_total_label = 'Cantidad total';

      if (this.cashflow.is_income) {
        this.qty_total_label = 'Cantidad total';
      } else if (this.cashflow.is_income === false) {
        this.qty_total_label = 'Cantidad total';
      }
    }
  }

  async getGood() {
    //TODO
    if (!this.owner)
      this.owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
        JSON.parse(value.value)
      );
    if (!this.goodsList)
      this.goodsAPI.getGoodsByUser().subscribe((value) => {
        this.goodsList = value;
        this.findProduct();
      });
    else this.findProduct();
  }

  findProduct() {
    if (
      this.cashflow.accounting_entries_categories ===
      CAFE_ACCOUNTING_ENTRY_CATEGORY
    )
      this.cashflow.availableGoodsList = this.goodsList.find(
        (element) => element.product_id === 16
      );

    if (Array.isArray(this.goodsList))
      this.coffeeGoods = this.goodsList.filter((element) =>
        [16, 17, 18].includes(element.product_id)
      );
    if (
      this.model_required_object.model_name.includes('goods') ||
      this.model_required_object.model_name === PRODUCT_MORPHABLE
    ) {
      if (this.model_required_object.id)
        this.desiredModel = this.goodsList.find(
          (element) => element.product_id == this.model_required_object.id
        );
      else
        this.desiredModel = this.goodsList.find(
          (element) => element.product_id == this.cashflow.product_id
        );
      if (this.model_required_object.is_cafe_cereza_required) {
        this.usedQty = this.goodsList.find(
          (element) =>
            element.product_id == this.model_required_object.cafe_cereza_id
        );
        if (!this.usedQty) {
          this.usedQty = {
            qty: 0,
            qty_update: 0,
            product_id: 16,
            owner_id: this.owner?.id,
          };
        }

        if (!this.usedQty?.qty_update) {
          this.usedQty.qty_update = this.usedQty.qty;
        }
        if (this.cashflow.id)
          this.initial_comment = parseInt(this.cashflow.comment);
        this.cashflow.usedQty = this.usedQty;
        this.cashflow.usedQtyBeforeUpdate = this.usedQty.qty;
      }
    } else if (this.model_required_object.model_name == 'material_product') {
      this.desiredModel = this.goodsList.find(
        (element) =>
          element.material_product_id == this.model_required_object.id
      );

      if (
        this.desiredModel &&
        (this.desiredModel?.qty || this.desiredModel?.qty == 0)
      ) {
        this.availbleQTY = this.desiredModel.qty;
      }

      this.cashflow.material_product_id = this.model_required_object.id;
      this.cashflowChangeEmitter.emit(this.cashflow);
    }

    if (this.desiredModel) {
      this.cashflow.modelBeingUpdated = this.desiredModel;
      this.desiredModel.displayable_unit = unit.all.find(
        (elem) => elem.value === this.desiredModel.unit
      );
      this.cashflowChangeEmitter.emit(this.cashflow);
    } else {
      this.cashflow.modelBeingUpdated = null;
      this.cashflowChangeEmitter.emit(this.cashflow);
    }
  }

  configForm() {
    this.formConfing.show_propiedas_value_tracker =
      this.cashflow.accounting_entries_categories !==
        OTROS_ACCOUNTING_ENTRY_CATEGORY &&
      (this.model_required_object.model_name.includes('material_product') ||
        this.model_required_object.model_name.includes('goods')) &&
      !isArbolesTransaction(this.cashflow);

    this.formConfing.unavalibe_qty =
      this.cashflow.is_income &&
      (!this.desiredModel ||
        !this.desiredModel?.id ||
        (this.desiredModel && this.desiredModel.qty === 0)) &&
      !isArbolesTransaction(this.cashflow);

    this.formConfing.show_arboles_section = isArbolesTransaction(this.cashflow);
  }

  ngDoCheck(): void {
    this.configForm();
    this.cashflow.displayable_unit = unit.all.find(
      (eleme) => eleme.value === this.cashflow.unity
    );
    if (this.cashflow.comment == '' && this.cashflow.usedQtyBeforeUpdate == 0) {
      this.usedQty.qty = 0;
    } else {
      if (
        this.cashflow.comment &&
        this.cashflow?.usedQtyCoffe != this.cashflow.comment &&
        this.usedQty
      ) {
        this.cashflow.usedQtyCoffe = this.cashflow.comment;

        this.usedQty.qty =
          this.usedQty.qty_update -
          parseInt(this.cashflow.comment) +
          this.initial_comment;
      } else {
        if (this.usedQty?.qty_update && !this.cashflow?.comment) {
          this.usedQty.qty = this.usedQty.qty_update;
        }
        this.cashflow.usedQty = this.usedQty;
      }
    }

    if (!this.desiredModel || !this.desiredModel.id) {
      this.desiredModel = {
        qty: 0,
        unit: this.cashflow.unity,
        displayable_unit: this.cashflow.displayable_unit,
        qty_update: 0,
      };
    } else if (this.desiredModel.id && !this.desiredModel.unit)
      this.desiredModel.displayable_unit = this.cashflow.displayable_unit;

    if (!this.cashflow.quantity)
      this.desiredModel.qty_update = this.desiredModel.qty;
    if (this.cashflow.unity) this.numberTransactionForOtrosProductAgricolas();
    if (
      this.cashflow.is_income &&
      (!this.desiredModel ||
        !this.desiredModel?.id ||
        (this.desiredModel && this.desiredModel.qty === 0)) &&
      !isArbolesTransaction(this.cashflow)
    )
      this.showFormChange.emit(false);
    else this.showFormChange.emit(true);
  }

  getRealGoodTransactionValues() {
    if (
      this.desiredModel &&
      this.desiredModel.unit &&
      this.cashflow.unity &&
      this.cashflow.unity !== this.desiredModel.unit
    ) {
      const cashflowUnit = this.units.find((element) => {
        if (element && element.value)
          return element?.value === this.cashflow?.unity;
        else return undefined;
      });
      const originalUnit = this.units.find((elment) => {
        if (elment && elment.value)
          return elment?.value === this.desiredModel?.unit;
        else return undefined;
      });
      if (cashflowUnit && originalUnit) {
        const metricSystemCashflowValue = convertToMetricSystem(
          cashflowUnit,
          this.cashflow.quantity
        );
        const originalUnitCashflowValue = convetToOtherUnit(
          originalUnit,
          metricSystemCashflowValue
        );
        return originalUnitCashflowValue;
      }
    }
    return this.cashflow.quantity;
  }

  numberTransactionForOtrosProductAgricolas() {
    if (
      isArbolesTransaction(this.cashflow) &&
      Array.isArray(this.preselect_production)
    ) {
      if (
        this.cashflow.accounting_entries_categories ===
        CAFE_ACCOUNTING_ENTRY_CATEGORY
      )
        this.cashflow.product_id == 16;
      if (this.cashflow.product_id)
        this.selected_product = this.preselect_production.find(
          (element) =>
            element.morphable_id === this.cashflow.product_id &&
            !element.is_animal
        );
      else if (this.cashflow.material_categorie_id)
        this.selected_product = this.preselect_production.find(
          (element) =>
            element.morphable_id === this.cashflow.material_categorie_id &&
            element.is_animal
        );
      this.cashflow.production_per_farm = this.selected_product;
      this.cashflowChangeEmitter.emit(this.cashflow);
    }

    if (this.desiredModel && this.cashflow.unity) {
      if (this.cashflow.is_income) {
        if (
          !this.desiredModel.qty &&
          CASHFLOW_FIRST_SALE_COFFEE_ID.includes(
            this.cashflow.cash_flow_categorie_id
          )
        ) {
          this.showFormChange.emit(false);
        } else if (
          this.coffeeGoods?.length == 0 &&
          CASHFLOW_SECOND_COFFEE_SELLS_ID.includes(
            this.cashflow.cash_flow_categorie_id
          )
        ) {
          this.showFormChange.emit(false);
        } else {
          this.showFormChange.emit(true);
        }
      }
      this.desiredModel.qty_update = getQtyUpdateInsertion(this.cashflow);
    } else if (this.cashflow.unity) {
      if (this.cashflow.is_income) {
        this.showFormChange.emit(false);
      }
      this.desiredModel = {
        qty_update: 0,
        qty: 0,
        // unit: 'unit',
      };
    }
    this.cashflow.availbleQTY = this.desiredModel.qty;
  }

  async showRelatedPopover() {
    const cashFlowCategFiltred = await Storage.get({
      key: dev.CASHFLOW_CATEGORIES,
    }).then((value) =>
      JSON.parse(value.value).find(
        (element) => element.id == PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
      )
    );

    if (Array.isArray(cashFlowCategFiltred)) {
      await this.configModal(cashFlowCategFiltred);
    } else {
      // const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
      // this.offlineSync
      //   .list('CashflowAPI', 'getPropertiesCashflowCategories', {
      //     country_id: country.id,
      //   })
      //   .subscribe(async (value: any) => {
      //     await Storage.set({
      //       key: dev.PROPIEDADES_CASHFLOW_CATEGORIES_KEY,
      //       value: JSON.stringify(JSON.parse(value.value)),
      //     });
      //     await this.configModal(JSON.stringify(JSON.parse(value.value)));
      //   });
    }
  }

  async configModal(cashFlowCategFiltred) {
    const modal = await this.modalCtrl.create({
      component: UnifiedCashflowInputComponentComponent,
      componentProps: {
        cashFlow: await this.initBuySellCashflow(cashFlowCategFiltred, 0, {
          id: this.model_required_object.id,
        }),
        accountingEntryCategories: cashFlowCategFiltred,
        modalController: this.modalCtrl,
      },
      id: '2',
      cssClass: ['my-custom-modal-css', 'modal-wrapper'],
    });
    modal.present();
    await modal.onWillDismiss();
    this.goodsList = null;
    this.getGood();
  }

  addThirdModel() {
    if (this.model_required_object.model_name.includes('material_product')) {
      this.showRelatedPopover();
    } else {
      this.desiredModel.qty = 0;
      this.desiredModel.qty_update = 0;
      this.redirectToCoffeProd.emit();
    }
  }

  async initBuySellCashflow(
    categories,
    initial_value: number,
    selected_product: any
  ): Promise<CashFlow> {
    const cashflow = new CashFlow();

    if (selected_product.material_product) {
      cashflow.cash_flow_categorie_id =
        initial_value - selected_product.qty >= 0
          ? cashflowCategories[selected_product.material_product_id.toString()]
              .sell
          : cashflowCategories[selected_product.material_product_id.toString()]
              .buy;
    } else
      cashflow.cash_flow_categorie_id =
        initial_value - selected_product.qty >= 0
          ? cashflowCategories[selected_product.id.toString()].sell
          : cashflowCategories[selected_product.id.toString()].buy;

    cashflow.accounting_entries_categories =
      PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY;

    cashflow.is_income = initial_value - selected_product.qty > 0 ? 0 : 1;

    const absVal = Math.abs(initial_value - selected_product.qty);
    cashflow.quantity = absVal === 0 ? initial_value : absVal;

    cashflow.unity = 5;
    cashflow.payment_methods = 'cash';
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    cashflow.currency = country?.currency
    let farm =  await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    cashflow.farm_id =farm.id
    cashflow.date = new Date().toISOString();
    categories.forEach((element) => {
      if (element.id === cashflow.cash_flow_categorie_id)
        cashflow.cashflow_categories = element;
      else if (Array.isArray(element.cash_flow_sub_category))
        return element.cash_flow_sub_category.forEach((sub_element) => {
          if (sub_element.id === cashflow.cash_flow_categorie_id)
            cashflow.cashflow_categories = sub_element;
        });
    });
    return cashflow;
  }
}
