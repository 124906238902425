import { Network } from '@capacitor/network';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { dev } from '../../config/offlines_keys.json';
import { getOwnerById } from 'src/app/utils/states-management/actions/owner.action';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-confirm-number-update',
  templateUrl: './confirm-number-update.component.html',
  styleUrls: ['./confirm-number-update.component.scss'],
})
export class ConfirmNumberUpdateComponent implements OnInit {
  @Input() new_number;
  @Input() new_prefix;
  @Input() new_network;
  @Input() auth_id;
  @Input() owner_id;
  @Input() pin;
  @Input() pin_code;
  @Input() auth;
  @Input() owner;

  constructor(
    public translateService: TranslateService,
    private modalController: ModalController,
    private ownerAPI: OwnerAPI,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}

  ngOnInit() {}
  async reSendSms() {
    this.ownerAPI
      .updateUserMobileAndAccess(
        this.new_number,
        this.new_prefix,
        this.auth_id,
        this?.owner_id,
        this.owner?.network,
        await this.translateService.instant('label.change_number_pin')
      )
      .subscribe();
  }
  cancel() {
    this.modalController.dismiss();
  }
  async confirm() {
    if (this.pin_code == this.pin) {
      this.ownerAPI
        .confirmedUpdateUserMobile(
          this.new_number,
          this.new_prefix,
          this.new_network,
          this.auth_id,
          this.owner_id
        )
        .subscribe(async (value:any) => {
          // this.auth.mobile_number = value.mobile_number;
          // this.auth.prefix = value.prefix;
          this.owner.mobile_number = value.mobile_number;
          this.owner.network =  value.network;
          this.owner.prefix = value.prefix;
          await Storage.set({
            key: dev.USER_KEY,
            value: JSON.stringify(this.owner),
          });
          // await Storage.set({
          //   key: dev.USER_DATA,
          //   value: JSON.stringify(this.auth),
          // });
         await this.toastUtils.toastBuilder(this.translateService.instant('success_update_sharing'), '', ' ', '');            // toast
            // .toastBuilder( this.translateService.instant('errors.mobile_number_already_exist'), '', ' ', '')
            // .then((val) => val.present());

            this.store.dispatch(getOwnerById());

          this.modalController.dismiss();
        });
        
    } else {
      this.toastUtils
        .toastBuilder(
          await this.translateService
            .get('screens.sms_validation.sms_send_error')
            .toPromise(),
          '',
          '',
          ''
        );
    }
  }
}
