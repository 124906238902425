import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  @Input() confirmation_text;

  @Input() title;
  @Input() modalController: ModalController;
  @Input() cancel_text;
  @Input() submit_text;
  @Input() id;
  @Input() data;
  @Input() width;
  @Input() product_selection_choice = false;
  constructor(public translateService:TranslateService) {}

  ngOnInit() {
    if (this.data && !this.data.cashflows) {
      this.data.cashflows = [this.data];
    }
  }

  cancel() {
    this.modalController.dismiss({ confirmed: false });
  }

  async submit() {
    this.modalController.dismiss({ confirmed: true });
  }
}
