import { CountriesEffect } from './../effects/countries.effect';
import { cloneDeep } from 'lodash';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LOGOUT } from '../actions/owner.action';
import {
  MARK_OPERATION_EXECUTED,
  UPDATE_SYNC,
} from '../actions/offline.action';
import { ownerReducer } from './owner.reducer';
import { farmReducer } from './farm.reducer';
import { familyMembersReducer } from './family.reducer';
import { productSales } from './product-sales.reducer';
import { cashflowReducer } from './cashflow.reducer';
import { cashflowListReducer } from './cashflow-lists.reducer';
import { connectionReducer } from './offline.reducer';
import { agriculturalProductReducer } from './agri-product.reducer';
import { plotsReducer } from './plot.reducer';
import { units } from './units.reducer';
import { storageSync } from '../storage-sync.module';
import { partnerReducer } from './partner.reducer';
import { countriesReducer } from './countries.reducer';
import { OwnerEffect } from '../effects/owner.effect';
import { FarmEffect } from '../effects/farm.effect';
import { ProductSaleEffect } from '../effects/product-sale.effect';
import { UnitsEffects } from '../effects/units.effect';
import { CashflowEffect } from '../effects/cashflow.effect';
import { OfflineEffect } from '../effects/offline.effect';
import { AccountingEntriesEffect } from '../effects/accounting-entries.effect';
import { AgriProductEffect } from '../effects/agri-product.effect';
import { PlotsEffect } from '../effects/plots.effect';
import { EmployeeEffect } from '../effects/employee.effect';
import { PartnerEffect } from '../effects/partners.effect';
import { programReducer } from './programas.reducer';
import { DashboardEffect } from '../effects/dashboard.effect';
import { dashboardReducer } from './dashboard.reducer';
import { animalsReducer } from './animals.reducer';
import { AnimalsEffect } from '../effects/animals.effect';
import { accountingEntriesReducer } from './accounting-entries.reducer';

export function meta(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action: any) {
    const meta = action.meta || {};
    const newMeta = { ...meta, offlineId: state.connection?.nextOfflineIndex };
    const newAction = { ...action, meta: newMeta };
    if (
      action.type.includes('DELETE') &&
      !action.type.includes('SUCCESS') &&
      isNaN(action?.id) &&
      action?.id?.includes('offline')
    ) {
      let newState = deleteUnSyncedRequest(state, action);
      if (newState) {
        return reducer(newState, newAction);
      }
    } else if (
      action.type.includes('UPDATE') &&
      !action.type.includes('SUCCESS') &&
      isNaN(action?.data?.id) &&
      action?.data?.id?.includes('offline')
    ) {
      let newState = updateFormDataInUnSyncedData(state, action);
      if (newState) {
        return reducer(newState, newAction);
      }
    }

    if (action.type === UPDATE_SYNC) {
      let newState = updateSateWithSyncedData(state, action);
      return reducer(action.type === LOGOUT ? undefined : newState, newAction);
    } else if (action.type === MARK_OPERATION_EXECUTED) {
      let newState = updateSateAfterExecution(state, action);
      return reducer(action.type === LOGOUT ? undefined : newState, newAction);
    } else {
      return reducer(action.type === LOGOUT ? undefined : state, newAction);
    }
  };
}

const deleteUnSyncedRequest = (state, action) => {
  let newState = cloneDeep(state);
  let dataId = action?.id?.split('-')[1];
  if (newState['connection']?.entities[dataId]) {
    delete newState['connection'].entities[dataId];
    newState['connection'].ids = newState['connection'].ids.filter(
      (val) => val != dataId
    );

    return newState;
  } else {
    return null;
  }
};
const updateFormDataInUnSyncedData = (state, action) => {
  let newState = cloneDeep(state);
  let dataId = action?.data?.id?.split('-')[1];
  if (newState['connection']?.entities[dataId]) {
    newState['connection']?.entities[dataId].formDataObject;
    let oldRequest = newState['connection']?.entities[dataId];
    oldRequest.formDataObject = action?.data;
    newState['connection'].entities[dataId] = oldRequest;
 

    return newState;
  } else {
    return null;
  }
};
const updateSateWithSyncedData = (state, action) => {
  let newState = cloneDeep(state);
  delete newState[action.stateName].entities[action.offlineId];

  newState[action.stateName].ids[
    newState[action.stateName].ids.findIndex(
      (value) => value == action.offlineId
    )
  ] = action.data.id;

  newState[action.stateName].entities[action.data?.id] = action.data;
  return newState;
};

const updateSateAfterExecution = (state, action) => {
  let newState = cloneDeep(state);
  let dataId = 'offline-' + action.id;

  Object.keys(newState).forEach((stateName) => {
    if (
      !Array.isArray(newState[stateName]) &&
      newState[stateName]?.ids &&
      stateName != 'connection'
    ) {
      if (
        newState[stateName]?.ids.findIndex((value) => value == dataId) != -1
      ) {
        delete newState[stateName].entities[dataId];

        newState[stateName].ids[
          newState[stateName].ids.findIndex((value) => value == dataId)
        ] = action.data.id;

        newState[stateName].entities[action.data?.id] = action.data;
      }
    }
  });

  return newState;
};

export const storageSyncReducer = storageSync({
  keys: [
    'familyMembers',
    'cashflowList',
    'farm',
    'owner',
    // 'accountingEntries',
    'TotalAccountings',
    'agriculturalProduct',
    'animals',
    'plots',
    'units',
    'partners',
    'countries',
    'programas',
    'dashboard',
    'connection',
  ], // Only sync the `collection` state
  ignoreActions: [],
  hydratedStateKey: 'hydrated',
  onSyncError: onSyncError, // If a sync fails
});

export function onSyncError(err) {
  console.log(err);
}

export function storageMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export const metaReducers: MetaReducer<any>[] = [meta, storageMetaReducer];

//Store Reducer Init

export const reducers: ActionReducerMap<any> = {
  accountingEntries: accountingEntriesReducer,
  owner: ownerReducer,
  farm: farmReducer,
  familyMembers: familyMembersReducer,
  productSales: productSales,
  cashflow: cashflowReducer,
  cashflowList: cashflowListReducer,
  connection: connectionReducer,
  agriculturalProduct: agriculturalProductReducer,
  animals: animalsReducer,
  plots: plotsReducer,
  units: units,
  partners: partnerReducer,
  countries: countriesReducer,
  programas: programReducer,
  dashboard: dashboardReducer,
};

export const effects = [
  OwnerEffect,
  FarmEffect,
  ProductSaleEffect,
  UnitsEffects,
  CashflowEffect,
  OfflineEffect,
  AccountingEntriesEffect,
  AgriProductEffect,
  AnimalsEffect,
  PlotsEffect,
  EmployeeEffect,
  PartnerEffect,
  CountriesEffect,
  DashboardEffect,
];
