import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAllUnits } from 'src/app/utils/states-management/reducers/units.reducer';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { orderAlphabaticallyTitles } from 'src/app/utils/array-filtrer';
import { loadUnit } from 'src/app/utils/states-management/actions/units-action';

// export class MaxTwoDecimal {
//   static formatInputValue(value: string): string {
//     let numericValue = value.replace(/[^\d.]/g, '');
//     numericValue = numericValue.replace(/,/g, '.');

//     const decimalIndex = numericValue.indexOf('.');
//     if (decimalIndex !== -1) {
//       const integerPart = numericValue.slice(0, decimalIndex);
//       let decimalPart = numericValue.slice(decimalIndex + 1);
//       decimalPart = decimalPart.slice(0, 2);
//       numericValue = integerPart + '.' + decimalPart;
//     }

//     return numericValue;
//   }
// }

export class MaxTwoDecimal {
  static formatInputValue(value: any): string {
    if (value && !isNaN(value)) {
      value = value.toString();
    }else{
      value = '0'
    }

    // if(numericValue.indexOf('.')==-1&& numericValue.indexOf(',')==-1){
    let numericValue = value?.replace(/[^\d,.]/g, '');
    //  }
    numericValue = value?.replace(' ', '');

    numericValue = numericValue.replace(/,/g, '.'); // Replace commas with dots for consistency

    // Find the decimal index
    const decimalIndex = numericValue.indexOf('.');

    // If there's a decimal point

    if (decimalIndex !== -1) {
      const integerPart = numericValue.slice(0, decimalIndex);
      let decimalPart = numericValue.slice(decimalIndex + 1);

      decimalPart = decimalPart.slice(0, 2);
      let numberValue = integerPart.replace(' ', '');
      //  numericValue = this.addThousandSeparator(integerPart)  + ',' + decimalPart;
      numericValue = integerPart + ',' + decimalPart;
    } else {
      let numberValue = numericValue.replace(' ', '');

      // numericValue = this.addThousandSeparator(numericValue);
      numericValue = numericValue;
    }

    return numericValue;
  }

  static addThousandSeparator(value: string): string {
    // Add space as thousands separator
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}

@Component({
  selector: 'app-calculette-component',
  templateUrl: './calculette-component.component.html',
  styleUrls: ['./calculette-component.component.scss'],
})
export class CalculetteComponentComponent implements OnInit {
  unitSystem = [];
  allowedDesitinationUnit = [];
  value: string = '';
  originalUnit;
  destinationUnit;
  unitType;
  UnitList;
  constructor(
    private store: Store,
    public translateService: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.store.select(selectAllUnits).subscribe(async (value) => {
      if (!value.length) {
        this.store.dispatch(loadUnit());
      }
      this.UnitList = cloneDeep(value.filter((element) => element.type != 'n'));
      this.sortUnitList();
    });

    this.translateService.onLangChange.subscribe((value) => {
      this.sortUnitList();
    });
  }

  async sortUnitList() {
    this.UnitList.forEach((element) => {
      element[
        this.translateService.currentLang == 'es'
          ? 'title'
          : 'title_' + this.translateService.currentLang
      ] = this.translateService.instant(element.value);
    });
    this.UnitList = await orderAlphabaticallyTitles(
      this.UnitList,
      this.translateService.currentLang
    );
    this.handelUnitTypeChange(this.unitType);
  }

  handelUnitTypeChange(e) {
    let val;
    if (e?.target?.value) {
      val = e?.target?.value;
    } else {
      val = e;
    }
    this.unitSystem = this.UnitList.filter((element) => element.type == val);
    this.value = null;
    this.originalUnit = null;
    this.destinationUnit = null;
  }

  originalUnitChanged(event) {
    this.allowedDesitinationUnit = this.unitSystem.filter(
      (element) =>
        element.type == event.target.value.type &&
        element.id != event.target.value.id
    );
  }

  formatInputValue(value: string): string {
    return MaxTwoDecimal.formatInputValue(value);
  }
  handleInput(event) {
    this.value =event
  //   const target = event.target;
  //   if (target instanceof HTMLInputElement) {
  //     this.value = this.formatInputValue(target.value);
  //     // this.value= this.currencyPipe.transform(
  //     //   target.value  ,
  //     //   undefined,
  //     //   '',
  //     //   '1.2-2',
  //     //   'fr-FR'
  //     // ); // | currency : undefined : "" : "1.2-2" : "fr-FR"
  //   }
  // }
}
}
