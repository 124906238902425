import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { FilterChoice } from 'src/app/modules/dashboard/cashflow/filters/analyses-filter/analyses-filter.component';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';

@Component({
  selector: 'app-filter-widget',
  templateUrl: './filter-widget.component.html',
  styleUrls: ['./filter-widget.component.scss'],
})
export class FilterWidgetComponent implements OnInit {
  @Input('filter') filter: FilterChoice = {
    sort_by: 'cashflowcategories',
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date(new Date().getFullYear(), 11, 31).toISOString(),
    monthly_yearly: 'm-Y',
  };
  @Input('accountingCategories')
  // accountingCategories: Array<AccountingEntryCategorie>;
  accountingCategories: Array<any>;
  @Input('cropConfig') cropConfig: Array<any>;
  @Input() modalController: ModalController;

  filterCategory = [];
  handelChange(event) {
    // this.handelFilterChange.emit({
    //   filterCategory: this.filterCategory,
    //   startDate: this.filter.start_date,
    //   endDate: this.filter.end_date,
    // });
  }
  onClose() {
    this.modalController.dismiss();
  }
  confirm() {
    this.modalController.dismiss({
      filterCategory: this.filterCategory,
      startDate: this.filter.start_date,
      endDate: this.filter.end_date,
    });
  }
  reset() {
    this.filterCategory = [];
    this.filter.start_date = new Date(
      new Date().getFullYear(),
      0,
      1
    ).toISOString();
    this.filter.end_date = new Date(
      new Date().getFullYear(),
      11,
      31
    ).toISOString();
  }
  constructor(private pickerOption: PickerOptionFormater) {}

  listOptions = [];
  ngOnInit() {

    if (this.cropConfig) {
      this.cropConfig.forEach((val) => {
        if (val?.products?.length > 0) {
          this.listOptions = this.listOptions.concat(val?.products);
        }
      });
    } else if (this.accountingCategories?.length > 0) {
      this.accountingCategories?.forEach((val) =>
        this.filterCategory.push(val.id)
      );
    }
  }
}
