import { cloneDeep } from 'lodash';
import {
  addOfflineOperation,
  updateConnectionStatus,
} from './../utils/states-management/actions/offline.action';
import { ConnectionState } from './../utils/states-management/reducers/offline.reducer';
import { Store } from '@ngrx/store';
import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap, tap, take, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { dev } from '../config/offlines_keys.json';
import { ToastUtils } from '../utils/toast_builder';
import { Router } from '@angular/router';
import { Network } from '@capacitor/network';
import { LOGOUT } from '../utils/states-management/actions/owner.action';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authSessionRepository: Observable<string> = null;
  private isOnline: boolean = false;
  connectionObserver;
  constructor(
    public router: Router,
    private store: Store<{ connection: ConnectionState }>,
    private tokenExtractor: HttpXsrfTokenExtractor
  ) {
    this.store.select('connection').subscribe((value) => {
      this.isOnline = value.isOnline;
    });
  }

  private static performRequestWithAuthorization(
    request: HttpRequest<any>,
    next: HttpHandler,
    tokenExtractor: HttpXsrfTokenExtractor
  ) {
    let authReq = request.clone({
      withCredentials: true,
    });

    const headerName = 'X-XSRF-TOKEN';
    let token = tokenExtractor.getToken() as string;
    if (token !== null) {
      authReq = request.clone({
        headers: request.headers.set(headerName, token),
        withCredentials: true,
      });
    }

    return request.clone(authReq);
  }

  addOfflineOperation(request) {
    if (
      request.method === 'DELETE' &&
      (request.url.includes('offline-') || request.url.includes('undefined'))
    )
      return;

    let formDataObject = null;

    if (request?.body instanceof FormData) {
      formDataObject = {};
      for (const [key, value] of request?.body?.entries()) {
        formDataObject[key] = value;
      }
    }

    if (formDataObject) {
      request.formDataObject = formDataObject;
      if (
        (request.method === 'POST' || request.method === 'PUT') &&
        formDataObject?.id?.includes('offline-')
      )
        return;
    }

    this.store.dispatch(addOfflineOperation({ operation: request }));
  }

  intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('https://us1.locationiq.com/v1/reverse')) {
      return next.handle(request);
    }
    let authReq = request.clone({
      withCredentials: true,
    });
    if (!this.isOnline && ['POST', 'DELETE', 'PUT'].includes(request.method)) {
      this.addOfflineOperation(request);
      return;
    } else if (this.isOnline) {
      const headerName = 'X-XSRF-TOKEN';

      let token = this.tokenExtractor.getToken() as string;
      if (token !== null) {
        authReq = authReq.clone({
          headers: authReq.headers.set(headerName, token),
        });
      }

      return next.handle(authReq).pipe(
        tap({
          error: (error: HttpErrorResponse) => {
            if (error.status == 401) {
              // this.toastUtils
              //   .toastBuilder(
              //     await this.translateService.instant('errors.token_not_valid'),
              //     null,
              //     null,
              //     null
              //   )
              //   .then((value) => value.present());
              this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
              return of(false);
            }
          },
        })
      );
    }
  }
  //  getCookie(name) {
  //   const value = `; ${document.cookie}`;
  //   const parts = value.split(`; ${name}=`);
  //   if (parts?.length === 2) return parts.pop().split(';').shift();
  // }
  getCookie(name: string): string | null {
    const match = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]+)')
    );
    return match ? decodeURIComponent(match[2]) : null;
  }
}
