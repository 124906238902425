import { cloneDeep } from 'lodash';

import {
  on,
  createReducer,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  addAnimals,
  addAnimalsSuccess,
  deleteAnimalGood,
  deleteSelectedAnimal,
  loadAnimal,
  loadAnimalSuccess,
  loadSelectedAnimalSuccess,
} from '../actions/animals.action';
export interface AnimalsState extends EntityState<any> {
  selectedProducts: any[];
  loading: boolean;
  error: any;
}
export function selectProductById(a): number {
  return a.id ? a.id : 0;
}

export const animalsAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: selectProductById,
});

export const initialState: AnimalsState = animalsAdapter.getInitialState({
  // additional entity state properties
  selectedProducts: [],
  loading: false,
  error: null,
});

export const animalSelector = createFeatureSelector<AnimalsState>('animals');
export const selectAllAnimals = createSelector(
  animalSelector,
  animalsAdapter.getSelectors().selectAll
);
export const selectedAnimalProducts = createSelector(
  animalSelector,
  (state) => state.selectedProducts
);

export const animalsReducer = createReducer(
  initialState,
  on(loadAnimal, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(loadAnimalSuccess, (state, action) => {
    return animalsAdapter.setAll(action.animals, {
      ...state,
      loading: false,
    });
  }),

  on(loadSelectedAnimalSuccess, (state, action: any) => {
    return {
      ...state,
      loading: false,
      selectedProducts: action.selectedAnimals,
      error: null,
    };
  }),
  on(deleteSelectedAnimal, (state, action) => {
    // console.log(action)
    // console.log(action?.product?.morphable?.material_categorie_id)
    let newEntities = cloneDeep(state.entities);
    // newEntities[action.product.morphable.material_categorie_id]?.products.push(
    //   action.product
    // );
    return {
      ...state,
      loading: false,
      entities: newEntities,
      selectedProducts: state.selectedProducts.filter(
        (value: any) => value.morphable_id != action.product.morphable_id
      ),
      error: null,
    };
  }),
  on(deleteAnimalGood, (state, action) => {
    let selectedProducts = cloneDeep(state.selectedProducts);
    let production = selectedProducts.find(
      (val) => val.id == action.product.id
    );

    production.goods = production.goods.filter(
      (val) => val.id != action.good.id
    );
    selectedProducts[
      selectedProducts.findIndex((val) => val.id == action.product.id)
    ] = production;

    return {
      ...state,
      loading: false,
      selectedProducts: selectedProducts,
      error: null,
    };
  }),

  on(addAnimals, (state, action: any) => {
    let newEntities = cloneDeep(state.entities);
    let newProduction = cloneDeep(action.productions);

    newProduction.forEach((element) => {
      const { id } = element.morphable;
      if (element?.morphable?.cash_flow_categories) {
        element['cash_flow_categories'] =
          element?.morphable?.cash_flow_categories;
      }
      element['id'] = 'offline';

      delete newEntities[id];
    });

    var selectedProducts = state.selectedProducts.concat(newProduction);

    return {
      ...state,
      loading: true,
      entities: newEntities,
      selectedProducts: selectedProducts,
      error: null,
    };
  }),
  on(addAnimalsSuccess, (state, action: any) => {
    return {
      ...state,
      loading: false,
      selectedProducts: action.productions,
      error: null,
    };
  })
);
