import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-analysis-inputs-usage',
  templateUrl: './analysis-inputs-usage.component.html',
  styleUrls: ['./analysis-inputs-usage.component.scss'],
})
export class AnalysisInputsUsageComponent implements OnInit, AfterViewInit {
  @Input() datas;
  @Input() unitType;
  @Input() inputName;
  @ViewChild('barChart') lineChartCanvas: ElementRef;
  lineChart;
  constructor(
    private translateService: TranslateService,
    private modalController: ModalController
  ) {}

  ngOnInit() {}
  ngAfterViewInit(): void {
    this.drawLineChart();
  }
  async drawLineChart() {
    let label = '';
    if (this.unitType == 'v') label = this.translateService.instant('litro');
    else if (this.unitType == 'p')
      label = this.translateService.instant('kilo');
    else label = this.translateService.instant('unit');

    if (this.lineChart) this.lineChart.destroy();
    var dataHarvested = {
      data: new Array(),
      lineTension: 0,
      fill: false,
      borderColor: 'green',
      backgroundColor: 'green',
      pointBorderColor: 'green',
      pointBackgroundColor: 'green',
    };
  

    const monthes = await this.translateService.instant('monthNames');

    const labels = this.datas.map((element) => {

      let year = element?.date.split('-')[0];
      let month = monthes[parseInt(element?.date.split('-')[1]) - 1].slice(
        0,
        3
      );
      let day = element?.date.split('-')[2];

      let label = day + '-' + month + '-' + year;

      dataHarvested.data.push(element.quantity);

      return label;
    });

    var speedData = {
      labels: labels,
      datasets: [dataHarvested],
    };
    this.lineChart = new Chart(this.lineChartCanvas.nativeElement, {
      type: 'bar',
      data: speedData,
      options: {
        scales: {
          y: {
            position: 'right',
            title: {
              color: 'green',
              text: label,
              display: true,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
          },
          x: {
            title: {
              color: 'green',
              text: await this.translateService.instant('label.time'),
              display: false,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  dismissPressed() {
    this.modalController.dismiss();
  }
}
