import { RoutingConfig } from 'src/app/utils/routing-config';
import { updateFarm } from './../../../../utils/states-management/actions/farm.action';
import { MapComponent } from './../../map/map.component';
import { ToastUtils } from './../../../../utils/toast_builder';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { Farm } from '../../../../models/farm/farm.model';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { FarmAPI } from '../../../../apis/farm.service';
import { Geolocation } from '@capacitor/geolocation';
import { ActivatedRoute, Router } from '@angular/router';
import routes from '../../../../config/routing.json';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { cloneDeep } from 'lodash';
import {
  loadFarm,
  loadFarmFromLocalStorage,
} from '../../../../utils/states-management/actions/farm.action';
import Owner from '../../../../models/owner.model';
import { loadAgriProduct } from 'src/app/utils/states-management/actions/agri-product.action';

@Component({
  selector: 'app-farm-address-details',
  templateUrl: './farm-address-details.component.html',
  styleUrls: ['./farm-address-details.component.scss'],
})
export class FarmAddressDetailsComponent implements OnInit {
  selectedLangage;
  label;
  for_style;
  @Input() nav;
  @Input() rootStack;
  altitude_default_value;
  natures = [];
  isDashboardView;
  farm: Farm = new Farm();
  lastSelectedPlace: any = null;
  zoomOn = [0, 0];
  location_changed = false;
  owner;
  is_edit_enabled = false;
  formConfig;
  map_problem = false;
  nextButtonClicked: boolean = false;
  browserPositionEnabled: boolean = false;
  loadingModal;
  constructor(
    private store: Store<{ owner: Owner; farm: Farm }>,
    private router: Router,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    private farmAPI: FarmAPI,
    private routingConfig: RoutingConfig,
    private ModalController: ModalController,
    private toastUtils: ToastUtils,
    private loadingCtrl: LoadingController,
    private platform: Platform
  ) {
    this.store.dispatch(loadFarm());

    this.route.params.subscribe(async (val) => {
      await this.ngOnInit();
    });
    this.translateService
      .get('label')
      .subscribe((value) => (this.label = value));
  }
  show_map_button = false;

  async ngOnInit() {
    // // console.log(this.platform.is('ios'));
    if (this.platform.is('ios') || this.platform.is('android')) {
      await this.checkMapPermissions();
    } else {
      this.checkBrowserLocationPermission();
    }


    this.loadingModal = await this.loadingCtrl.create({
      spinner: 'bubbles',
      mode: 'ios',
    });
    let ClassName = 'FarmAddressDetailsComponent';
    this.formConfig = (
      await this.routingConfig.getPageFormConfig(ClassName)
    )?.fields;

    this.store.select('owner').subscribe((value: any) => {
      if (value.id) this.owner = cloneDeep(value);
    });
    this.store.select('farm').subscribe(async (value: any) => {
      let farm = cloneDeep(value);
      this.farm = new Farm();
      this.farm.instantiateFromObject(farm);
      if (
        this.farm?.latitude &&
        this.farm?.longitude &&
        this.farm?.latitude != 'null' &&
        this.farm?.longitude != 'null'
      ) {
        this.location_changed = true;
      } else {
        let longLat = await JSON.parse(
          (
            await Storage.get({ key: 'longLat' })
          )?.value
        );

        if (!longLat?.long || !longLat?.lat) {
          const coordinates = await Geolocation.getCurrentPosition();
          this.farm.longitude = +coordinates.coords.longitude;
          this.farm.latitude = +coordinates.coords.latitude;
          Storage.set({
            key: 'longLat',
            value: JSON.stringify({
              long: this.farm.longitude,
              lat: this.farm.latitude,
            }),
          });
        } else {
          this.farm.longitude = longLat?.long;
          this.farm.latitude = longLat?.lat;
        }
        this.location_changed = false;
      }
      if (this.farm.id && this.farm.name) {
        this.nextButtonClicked = true;
      }
    });
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
  }
  async checkMapPermissions() {
    const permission = await Geolocation.checkPermissions();

    if (permission.location == 'granted') {
      this.show_map_button = true;
    } else {
      const request = await Geolocation.requestPermissions();
      if (request.location == 'granted') {
        this.show_map_button = true;
      } else {
        this.show_map_button = false;
      }
    }

  }

  checkBrowserLocationPermission() {
    navigator.geolocation.watchPosition(
      (position) => {
        if (position) this.show_map_button = true;
      },
      (error) => {
      }
    );
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
  async handelShowModal() {
    let componentProps = {
      modalController: this.ModalController,
      title: this.label.locate_me,
      allow_draw: false,
      address_view: true,
      long: null,
      lat: null,
      farm: this.farm,
      MapHeigth: '65vh',
      popOverMsg: 'infobull.infobull_8',
      showFooter: true,
    };
    let modal = await this.toastUtils.modalBuilder(
      MapComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );
    const { data } = await modal.onWillDismiss();
    if (data?.long && data?.lat) {
      this.zoomOn = [data.long, data.lat];
      this.farm.latitude = data.lat;
      this.farm.longitude = data.long;
      this.store.dispatch(updateFarm({ data: this.farm, id: this.farm.id }));

      this.geocodeLocation(data.long, data.lat);
      this.location_changed = true;
    }
  }

  async submitFormDashboardViewForm() {
    const farm = this.farm;
    const id = this.farm;
    this.store.dispatch(updateFarm({ data: farm, id: this.farm.id }));

  }

  geocodeLocation(longitude, latitude) {
    this.farm = cloneDeep(this.farm);
    this.farmAPI
      .reverseGeocodeLocation(longitude, latitude)
      .subscribe(async (val: any) => {
        let value = JSON.parse(val.address);
        this.farm.longitude = longitude;
        this.farm.latitude = latitude;
        this.farm.postal_code = value?.address?.postcode;
        this.farm.street = value?.address?.road;
        this.farm.department = value?.address?.state;
        this.farm.municipality = value?.address?.city_district;
        this.farm.province = value?.address?.state;
        if (val.height && Array.isArray(val.height)) {
          this.farm.altitude = val.height[0];
        }
      });
  }

  locationChangedEvent(value) {
    // this.geocodeLocation(value[1], value[0]);
  }

  async submit() {
    if (
      !this.nextButtonClicked &&
      !this.location_changed &&
      this.show_map_button
    ) {
      this.nextButtonClicked = true;
      return;
    }
    this.loadingModal.present();

    this.saveFarm()
      .pipe(
        map((farm) => farm),
        switchMap(async (farm: any) => {
          // this.store.dispatch(loadAgriProduct());
          await this.loadingModal.dismiss();

          return from(
            Storage.set({ key: dev.FARM_KEY, value: JSON.stringify(farm) })
          );
        }),
        switchMap((value) => from(this.routingConfig.getNextUrl())),
        map((value: any) => this.router.navigateByUrl(value))
      )
      .subscribe();
  }

  async previous() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
    let profile;
    if (this.owner?.profile_info)
      profile = JSON.parse(this.owner?.profile_info);
    if (profile.aaa_program)
      this.router.navigateByUrl(await this.routingConfig.getBackUrl());
    else this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  setAltitude(event) {
    this.farm.altitude = event;
  }

  enableEdit() {
    this.is_edit_enabled = !this.is_edit_enabled;
  }

  saveFarm(): Observable<Object> {
    if (this.farm.id) {
      this.store.dispatch(updateFarm({ data: this.farm, id: this.farm.id }));
      return of(this.farm);
    } else {
      return this.farmAPI.insertFarmAndLinkItToUser({
        farm: this.farm,
        ownerId: this.owner.id,
      });
    }
    
    // return this.farm.id
    //   ? (updateFarm({ data: this.farm, id: this.farm.id });return)
    //   : this.farmAPI.insertFarmAndLinkItToUser({
    //       farm: this.farm,
    //       ownerId: this.owner.id,
    //     });
  }
  farmNameChanged(event) {
    this.farm.name = event.target.value;
  }
}
