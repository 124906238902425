import { ANIMALES_ACCOUNTING_ENTRY_CATEGORY } from './../../../../utils/consts';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { ANIMAL_IDS } from '../../../../utils/consts';
import { Component, Input, OnInit } from '@angular/core';
import { IonNav, ModalController, PopoverController } from '@ionic/angular';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { AnimalesFormComponent } from '../animals-form/animals-form.component';
import { UpdateAnimalPropretyComponent } from '../update-animal-proprety/update-animal-proprety.component';
import routes from '../../../../config/routing.json';
import { Router } from '@angular/router';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { ProductionSalesOtherComponent } from 'src/app/modules/dashboard/cashflow/production-sales-other/production-sales-other.component';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { Good } from 'src/app/models/good.model';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { deleteAnimalGood, deleteSelectedAnimal, loadAnimal, loadSelectedAnimal } from 'src/app/utils/states-management/actions/animals.action';
import { selectedAnimalProducts } from 'src/app/utils/states-management/reducers/animals.reducer';
import Owner from 'src/app/models/owner.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-animals',
  templateUrl: './animals.component.html',
  styleUrls: ['./animals.component.scss'],
})
export class AnimalesComponent implements OnInit {
  owner;
  productList: Array<ProductionsPerfarm>;
  lang;
  isDashboardView;
  @Input() nav: IonNav;
  @Input() rootStack;
  is_loading = true;
  update_at;
  base_url = 'assets';

  constructor(
    private modalController: ModalController,
    private router: Router,
    private popoverController: PopoverController,
    private goodsAPi: GoodsAPI,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private productionAPI: ProductionAPI,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private store: Store<{
      animals: any;
      owner:Owner
    }>
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    Storage.get({ key: dev.LANGAGE_KEY }).then((value) => {
      this.lang = value.value;
    });


    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    this.store.dispatch(loadAnimal());
    this.store.dispatch(loadSelectedAnimal());

    this.fetchAnimals()
  }

  getLastUpdate(products) {
    if (products.length > 0) {
      var maxDate = new Date(
        Math.max(
          ...products.map((element) => {
            return new Date(element.created_at);
          })
        )
      );
      this.update_at = maxDate;
    }
  }

  async add() {
    let componentProps = {
      modalController: this.modalController,
      lists: this.productList,
    };
    let modal = await this.toastUtils.modalBuilder(
      AnimalesFormComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    await modal.onWillDismiss();
    // this.store.dispatch(loadAnimal());
    // this.store.dispatch(loadSelectedAnimal());
    // this.fetchAnimals();
  }

  fetchAnimals() {
    this.is_loading = true;
    this.store.select(selectedAnimalProducts).subscribe(animals=>{
      this.productList = animals;
      this.getLastUpdate(this.productList);
      this.is_loading = false;
    })
    // return this.productionAPI.getProductionByFarmAndType(1).pipe(
    //   map((values: Array<ProductionsPerfarm>) => {
    //     this.productList = values;
    //     this.getLastUpdate(this.productList);
    //     this.is_loading = false;
    //   })
    // );
  }

  async updateGood(good) {
    let componentProps = {
      modalController: this.modalController,
      owner: this.owner,
      entry: good?.entry,
    };
    let modal = await this.toastUtils.modalBuilder(
      UpdateAnimalPropretyComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '2'
    );

    await modal.onWillDismiss();
    this.fetchAnimals();
  }

  async getCashflowRequestConfirmation(msg): Promise<Boolean> {
    const modal = await showConfirmModal(
      this.popoverController,
      msg,
      null,
      this.lang === 'es' ? 'Sí' : 'Yes',
      'No'
    );

    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  async showOtherInputComponent(products: ProductionsPerfarm) {
    const cashFlowCategFiltred = JSON.parse(
      (
        await Storage.get({
          key: dev.CASHFLOW_CATEGORIES,
        })
      ).value
    );

    let componentProps = {
      accountingEntryCategories: cashFlowCategFiltred.find(
        (value) => value.id === ANIMALES_ACCOUNTING_ENTRY_CATEGORY
      ),
    };
    let modal = await this.toastUtils.modalBuilder(
      UnifiedCashflowInputComponentComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '1'
    );

    await modal.onWillDismiss();
  }
  async requestUserToInsertCashflow(
    msg: string,
    farmProduction: ProductionsPerfarm
  ) {
    const modal = await showConfirmModal(
      this.popoverController,
      msg,
      null,
      await this.translateService.get('yes').toPromise(),
      await this.translateService.get('no').toPromise()
    );
    const { data } = await modal.onWillDismiss();
    if (data?.confirmed) {
      await this.showOtherInputComponent(farmProduction);
    }
    return data?.confirmed;
  }

  async deletePressed(object,animal) {
    let farmProduction: ProductionsPerfarm = animal;
    let goodTobeDeleted: Good = object.item;

    // if (this.isDashboardView) {
    //   let value = true;

    //   if (object?.product?.qty != 0) {
    //     value = await this.requestUserToInsertCashflow(
    //       await this.translateService
    //         .get('redirect_cashflow_entry_animal')
    //         .toPromise(),
    //       farmProduction
    //     );
    //   } else {
    //     this.deleteEntry(farmProduction, goodTobeDeleted);
    //   }
    // } else if (!this.isDashboardView) {
      this.deleteEntry(farmProduction, goodTobeDeleted);
    // }
  }

  deleteEntry(farmProduction, goodTobeDeleted) {
    if (
      ANIMAL_IDS.includes(goodTobeDeleted.material_product_id) ||
      (farmProduction?.goods && farmProduction?.goods.length === 1)
    ) {
      this.deleteProduction(farmProduction);
    } else {
      this.deleteFromGoodsTable(goodTobeDeleted,farmProduction);
    }
  }
  deleteProduction(productionDelete: any) {
    this.store.dispatch(deleteSelectedAnimal({ product :productionDelete}))
    // this.productionAPI.deleteFarmProduction(productionDelete.id).subscribe(
    //   (value) => {
    //     this.fetchAnimals()
    //   },
    //   (e: HttpErrorResponse) => {
    //     if (e.status == 200) this.fetchAnimals()
    //   }
    // );
  }
  deleteFromGoodsTable(good: Good,productionDelete:any) {
    this.store.dispatch(deleteAnimalGood({ product :productionDelete,good:good}))

    // this.goodsAPi
    //   .deleteGood(good.id)
    //   .pipe(
    //     map((deleteResult) => deleteResult),
    //     map((deleteResult) => {
    //       return loadAnimal();
    //     }),
    //     map((finalResult) => loadSelectedAnimal())
    //   )
    //   .subscribe();
  }

  async redirect() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  detailsClicked(item) {
    this.nav.setRoot(ProductionSalesOtherComponent, {
      nav: this.nav,
      product: item,
    });
  }
}
