import { element } from 'protractor';
import { ProductionsPerfarm } from '../../../../models/farm/production_per_farm';
import { AnimalesFormComponent } from '../../animals/animals-form/animals-form.component';
import {
  MORPH_MaterialCategorie,
  PRODUCT_MORPHABLE,
  MORPH_Product,
} from 'src/app/utils/consts';
import { ProductFormComponent } from '../../others-agricol-product-components/product-form/product-form.component';
import { PopoverController } from '@ionic/angular';
import { ConfirmModalComponent } from 'src/app/widgets/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import routes from '../../../../config/routing.json';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { ListableProduction } from 'src/app/models/farm/production_per_farm';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import {
  mapLangeTitleObjects,
  orderAlphabaticallyByAttribute,
} from 'src/app/utils/array-filtrer';
import { Good } from 'src/app/models/good.model';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { environment } from '../../../../../environments/environment';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { map, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { Observable, forkJoin, lastValueFrom, of } from 'rxjs';
import { cloneDeep, merge } from 'lodash';
import {
  AgriculturalProductState,
  selectedAgriProduct,
} from 'src/app/utils/states-management/reducers/agri-product.reducer';
import { selectedAnimalProducts } from 'src/app/utils/states-management/reducers/animals.reducer';

@Component({
  selector: 'app-lot-production-selector',
  templateUrl: './lot-production-selector.component.html',
  styleUrls: ['./lot-production-selector.component.scss'],
})
export class LotProductionSelectorComponent implements OnInit {
  productions: Array<ListableProduction>;
  @Input() title;
  @Input() modalController;
  @Input() modalMain;
  @Input() selected_products;
  @Input() popOverMsg;
  @Output() handelElementChecked: EventEmitter<any> = new EventEmitter();

  base_url = 'assets';
  isDashboardView;
  lang;
  products: ProductionsPerfarm[] = new Array();
  owner;
  show = false;
  MORPH_Product = MORPH_Product;
  MORPH_MaterialCategorie = MORPH_MaterialCategorie;
  constructor(
    private router: Router,
    private productionAPI: ProductionAPI,
    private popover: PopoverController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private userInfo: UserInfo,
    private routingConfig: RoutingConfig,
    private store: Store<{ agriculturalProduct: AgriculturalProductState }>
  ) {}

  ElementChecked(item) {
    item.is_checked = !item.is_checked;
    this.handelElementChecked.emit(
      this.productions.filter((val) => val.is_checked)
    );
  }
  async ngOnInit() {
    // await this.showPopOver();

    this.lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
    this.owner = await this.userInfo.getOwner().then((value) => value);

    // await this.reset();
    await this.fetchProductionsPerFarm();
  }

  async showPopOver() {
    let componentProps = { key1: this.popOverMsg };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  async fetchProductionsPerFarm() {
    const selectedAnimalSubscription$: Observable<any[]> = this.store.select(
      selectedAnimalProducts
    );
    const selectedAgriProductSubscription$: Observable<any[]> =
      this.store.select(selectedAgriProduct);

    // Clone selectedAnimalSubscription & selectedAgriProductSubscription using spread operator
    of(null)
      .pipe(
        // Using of(null) to trigger the observable and start the merging process
        mergeMap(() =>
          selectedAnimalSubscription$.pipe(map((array) => [...array]))
        ),
        mergeMap((clonedArray1) =>
          selectedAgriProductSubscription$.pipe(
            map((array) => [...array, ...clonedArray1]) // Clone array2 and merge it with clonedArray1
          )
        )
      )
      .subscribe(
        (value) => {
          let clonedResult = cloneDeep(value);
          let items = clonedResult.filter(
            (element) =>
              element.morphable_type.toLowerCase().includes('product') ||
              element.morphable_type.toLowerCase().includes('materialcategorie')
          );

          items.forEach(async (element) => {
            if (element.morphable_type.toLowerCase().includes('product')) {
              element['title'] = this.translateService.instant(
                'product.' + element.morphable_id
              );
            } else if (
              element.morphable_type.toLowerCase().includes('materialcategorie')
            ) {
              element['title'] = this.translateService.instant(
                'material_category.' + element.morphable_id
              );
            }
          });

          items.forEach((val) => {
            if (val.comment) {
              val.title = val.comment;
            }
          });

          this.productions = mapLangeTitleObjects(
            items,
            'title',
            this.translateService
          );

          this.productions.forEach((value) => {
            value.is_checked = this.selected_products.some(
              (val) =>
                val.morphable_type == value.morphable_type &&
                val.morphable_id == value.morphable_id
            );
          });

          this.show = true;
        },
        (e) => {
          this.show = true;
        }
      );
  }

  async submit() {
    const return_products = new Array();
    this.productions.forEach((element) => {
      if (element.is_checked) return_products.push(element);
    });
    if (Array.isArray(return_products) && return_products.length > 0)
      this.modalController.dismiss({
        products: return_products,
      });
    else
      await this.toastUtils.toastBuilder(
        await this.translateService.instant(
          'errors.select_agri_animal_product'
        ),
        null,
        null
      );
  }

  cancel() {
    this.modalController.dismiss({
      products: null,
    });
  }

  async reset() {
    this.productions.forEach((element) => (element.is_checked = false));
  }

  async goBack() {
    const modal = await this.popover.create({
      component: ConfirmModalComponent,
      componentProps: {
        title: '',
        modalController: this.popover,
        confirmation_text: await this.translateService.instant(
          'agricultural_product_or_animals'
        ),
        cancel_text: await this.translateService.instant(
          'titles.other_prod_title'
        ),
        submit_text: await this.translateService.instant('breed'),
        product_selection_choice: true,
      },
      cssClass: ['pop-over-style-confirm-logout'],
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data.confirmed === true) {
      this.showAnimalModal();
    } else if (data.confirmed === false && data.confirmed != undefined) {
      this.showProductAgricultureModal();
    }
  }

  async showProductAgricultureModal() {
    let componentProps = {
      modalController: this.modalController,
      categorie_id: 1,
      products: this.products,
      is_preproduct_view: true,
      formConfig: (
        await this.routingConfig.getPageFormConfig(
          'PreProductSelectorComponent'
        )
      ).modal_config,
      title: await this.translateService
        .get('titles.other_prod_title')
        .toPromise()
        .then((value) => value),
    };
    let modal = await this.toastUtils.modalBuilder(
      ProductFormComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();

    if (Array.isArray(data?.products) && data?.products.length > 0) {
      await this.saveProductions(data.products);
    }
  }

  async saveProductions(products) {
    const farm = await this.store.select(getFarm).pipe(take(1)).toPromise();
    const owner_id = this.owner.id;
    if (Array.isArray(products) && products.length > 0) {
      products = products.map((element) => {
        let productions = new ProductionsPerfarm();
        productions.goods = new Array<Good>();
        productions.goods.push(new Good());
        productions.morphable_type = MORPH_Product;
        productions.morphable_id = element.product_id;
        if (element.trees && element.trees.has_trees) {
          productions.trees_numbers = element.trees.trees_number;
        } else {
          productions.trees_numbers = -1;
        }
        if (element.qty) {
          productions.goods[0].qty = element.qty;
        }
        if (element.unit) {
          productions.goods[0].unit = element.unit;
        }
        if (element.comment) productions.comment = element.comment;
        else productions.comment = null;
        productions.goods[0].owner_id = owner_id;
        productions.is_crop = element.is_cafe ? 1 : 0;
        productions.is_animal = 0;
        productions.farm_id = farm.id;
        return productions;
      });
    }

    this.productionAPI
      .insertMultipleProduction(products)
      .then(async (value: ProductionsPerfarm[]) => {
        this.products = value;
        await Storage.set({
          key: dev.PRESELECTED_PRODUCT_KEY,
          value: JSON.stringify(value),
        });
        setTimeout(() => {
          this.fetchProductionsPerFarm();
        }, 500);
      });
  }

  async showAnimalModal() {
    let componentProps = {
      modalController: this.modalController,
      owner: this.owner,
      lists: this.products,
    };
    let modal = await this.toastUtils.modalBuilder(
      AnimalesFormComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    await modal.onWillDismiss();
    this.fetchProductionsPerFarm();
  }
}
