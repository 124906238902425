import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { Farm } from '../models/farm/farm.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FarmAPI {
  base_url = environment.appRoot + '/api/';
  constructor(private http: HttpClient) { }

  insertFarmAndLinkItToUser(data) {
    const { ownerId } = data;
    const farm = new Farm();
    farm.instantiateFromObject(data.farm);
    const url = this.base_url + dev.endpoint.farm.insert_farm;
    let postData = farm?.prepareFormData(ownerId);
    return this.http.post(url, postData);
  }
  getFarm() {
    const url = this.base_url + dev.endpoint.farm.getFarm;
    return this.http.get(url);
  }


  reverseGeocodeLocation(longi, lati) {
    const url = this.base_url + dev.endpoint.farm.getPositionExternalApi;
    return this.http.get(url + '/' + longi + '/' + lati);
  }


  farmAltitude(longi, lati) {
    const url = "http://api.open-meteo.com/v1/elevation?latitude=" + lati + "&longitude=" + longi
    return this.http.get(url);
  }
  
  upDateFarm(data: Farm) {
    const farm = new Farm();
    farm.instantiateFromObject(data);
    const url = this.base_url + dev.endpoint.farm.update_farm;
    let postData = farm?.prepareFormData(data.id);
    return this.http.post(url, postData);
  }

  upDateFarmCluster(data) {
    const { farmId, clustId, clusterNumber, ambassadorId, owner_id } =
      data;
    const url = this.base_url + dev.endpoint.farm.updateFarmCluster;
    const formDate = new FormData();

    formDate.append('id', farmId);
    formDate.append('owner_id', owner_id);
    formDate.append('cluster_number', clusterNumber);
    formDate.append('cluster_id', clustId);
    formDate.append('ambassador_id', ambassadorId);
    return this.http.post(url, formDate);
  }

}
