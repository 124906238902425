import { cloneDeep } from 'lodash';
import { updateOwnerAndFarmCluster } from 'src/app/utils/states-management/actions/owner.action';
import { LoadingController, IonContent } from '@ionic/angular';
import { Farm } from './../../../models/farm/farm.model';
import { CAFE_IDS } from './../../../utils/consts';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import routes from '../../../config/routing.json';
import { TranslateService } from '@ngx-translate/core';
import Owner from 'src/app/models/owner.model';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import {
  getAllCounties,
  getCountry,
} from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent implements OnInit {
  profile_info: any = {
    role: 'agricultur',
    aaa_program: null,
    has_family: null,
    has_crop: true,
    cropConfig: [],
  };
  @ViewChild('content', { static: false }) content: IonContent;

  label;
  isDashboardView;
  country;
  has_showed_popover = false;
  formConfig;
  cropConfig;
  cropProducts = [];
  animalCrop;
  has_crop;
  submited;
  farm: any = new Farm();
  loadingModal;
  owner: Owner;
  constructor(
    private router: Router,
    private toastUtils: ToastUtils,
    public translateService: TranslateService,
    private routingConfig: RoutingConfig,
    private toastController: ToastUtils,
    private loadingController: LoadingController,
    private store: Store<{ farm: Farm; owner: Owner }>
  ) {
    this.store.select('owner').subscribe((value: any) => {
      this.owner = cloneDeep(value);
      if (value.farm && value?.farm.id) this.farm = cloneDeep(value.farm);
    });
  }

  async handelChange(product) {
    if (product?.is_checked) {
      this.profile_info.has_crop = true;
      this.cropProducts.push({
        id: product?.crop_id,
        entity: product?.crop_entity,
        crop_img: product?.crop_img,
        hasPlotDetails: product?.crops_form?.length > 0,
      });
    } else {
      this.cropProducts = this.cropProducts.filter((val) => {
        return !(
          val?.id == product?.crop_id && val.entity == product?.crop_entity
        );
      });

      if (!this.has_showed_popover) {
        // product.is_checked = true;
        await this.showPopOver();
        this.has_showed_popover = true;
      } else {
        this.profile_info.has_crop = false;
      }
    }
  }

  handelProgChange(val) {
    this.profile_info.aaa_program = JSON.parse(val.target.value);
  }

  async ngOnInit() {
    let ClassName = 'ProfileInfoComponent';
    this.formConfig = (
      await this.routingConfig.getPageFormConfig(ClassName)
    )?.fields;
    this.cropConfig = await this.routingConfig.getCropFormConfig();
    this.loadingModal = await this.loadingController.create();
    this.owner = cloneDeep(
      await lastValueFrom(this.store.select('owner').pipe(take(1)))
    );

    if (this.owner?.profile_info)
      this.profile_info = JSON.parse(this.owner?.profile_info);

    if (this.owner && this.owner?.profile_info) {
      this.cropConfig.forEach((cat) => {
        cat.products.forEach((element) => {
          element['is_checked'] = this.profile_info?.cropConfig.find(
            (val) =>
              val?.id == element?.crop_id && val?.entity == element?.crop_entity
          )
            ? true
            : false;
        });
      });
      // this.cropProducts = JSON.parse(this.owner?.profile_info)?.cropConfig;
    } else {
      // this.cropProducts = await JSON.parse(
      //   (
      //     await Storage.get({ key: dev.CROP_PRODUCT })
      //   )?.value
      // );
    }

    this.isDashboardView = this.router.url.includes(routes.dashboard.home);

    // if (this.cropProducts) {
    //   this.cropProducts?.forEach((crop) => {
    //     if (this.profile_info.cropConfig.find((val) => val.id === crop?.id)) {
    //       crop.is_checked = true;
    //     } else {
    //       crop.is_checked = false;
    //     }
    //   });
    // }

    this.has_showed_popover = false;
  }

  async goBack() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  async submitOwnerForm() {
    this.submited = true;

    this.profile_info.cropConfig = this.cropProducts;

    // return;
    this.owner.profile_info = JSON.stringify(this.profile_info);
    let countrie = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    this.owner.country_id = countrie?.id;

    if (
      (!this.farm?.cluster_number ||
        !this.farm?.cluster_id ||
        !this.farm?.ambassador_id) &&
      this.profile_info?.aaa_program
    ) {
      this.presentToast(
        await this.translateService.get('errors.all_fields').toPromise()
      );
      this.submited = false;
    } else {
      if (this.has_showed_popover || this.isDashboardView) {
        const farmId = this.farm?.id;
        const farmCluster_id = this.farm?.cluster_id;
        const farmCluster_number = this?.farm?.cluster_number;
        const farmAmbassador_id = this?.farm.ambassador_id;

        this.store.dispatch(
          updateOwnerAndFarmCluster({
            data: {
              farmId: farmId,
              clustId: farmCluster_id,
              clusterNumber: farmCluster_number,
              ambassadorId: farmAmbassador_id,
              owner_id: this.owner.id,
            },
            profile_info: this.owner.profile_info,
          })
        );
        if (!this.isDashboardView) {
          this.router.navigateByUrl(await this.routingConfig.getNextUrl());
        }
      } else this.showPopOver();
    }
  }

  async presentToast(message) {
    await this.toastController.toastBuilder('', 'bottom', message, 'danger');
  }

  async showPopOver(has_showed_popover = true) {
    let componentProps = {
      key1: await this.translateService.instant('info_bull'),
      close_button: await this.translateService.instant('submit'),
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
    this.has_showed_popover = has_showed_popover;
    this.submited = false;
  }

  async submitFormDashboardViewForm() {
    this.submitOwnerForm();
  }
}
