import {
  mapLangeTitleObjects,
  pushOtrosToEndByAttributeName,
} from './../../../utils/array-filtrer';
import { orderAlphabaticallyByAttribute } from 'src/app/utils/array-filtrer';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Partner from 'src/app/models/partner.model';
import { MyPartnersAPI } from '../../../apis/partners.service';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { ToastUtils } from '../../../utils/toast_builder';
import routes from '../../../config/routing.json';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, take, filter } from 'rxjs/operators';
import { from, lastValueFrom, of } from 'rxjs';
import { PhotoEditorComponent } from 'src/app/components/personal-data/profile-photo/photo-editor/photo-editor.component';
import { PhotoService, PhotoTaken } from 'src/app/services/photo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Store, props } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import {
  addPartner,
  loadPartnerType,
  updatePartner,
} from 'src/app/utils/states-management/actions/partner.action';
import { cloneDeep } from 'lodash';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
@Component({
  selector: 'app-modal-partner',
  templateUrl: './modal-partner.component.html',
  styleUrls: ['./modal-partner.component.scss'],
  providers: [MyPartnersAPI],
})
export class ModalPartnerComponent implements OnInit {
  partner_types: any[];
  owner_id: number;
  country;
  @Input() item: Partner;
  @Input() cropProduct;
  isDashboardView;
  partner = new Partner();
  formConfig;
  selectedPartnerType: any;
  selectedCrop: any;
  selectedPartner: any;
  partner_list;
  headerLabel;
  add_partner: boolean = false;
  emptyArray = false;
  owner: Owner;
  hasSelectedPhoto;
  @Input() openedFromCashflow = false;

  constructor(
    public modalController: ModalController,
    public translateService: TranslateService,
    private router: Router,
    private partnersAPI: MyPartnersAPI,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private store: Store<{ owner: Owner; partners: Partner }>
  ) {}

  addPartner() {
    this.selectedPartner = null;
    this.add_partner = true;
    this.partner.id = null;
    this.partner.name = null;
  }

  async translate() {
    this.headerLabel =
      (await lastValueFrom(
        this.translateService.get('titles.partner_form_title')
      )) +
      ' (' +
      (await lastValueFrom(
        this.translateService.get(
          'accounting_entries.' + this.cropProduct.accounting_id
        )
      )) +
      ')';
  }
  existedPartner = [];
  async ngOnInit() {
    //TODO change crop name in header
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    this.translate();
    this.translateService.onLangChange.subscribe((value: string) => {
      this.translate();
    });
    let ClassName = 'ModalPartnerComponent';
    this.formConfig = (
      await this.routingConfig.getPageFormConfig(ClassName)
    ).modal;
    this.store.dispatch(loadPartnerType());
    this.store
      .select('partners')
      .pipe(
        switchMap(async (values: any) => {
          let filtred_partner_types = cloneDeep(values.partnersType);
          this.existedPartner = Object.values(values.entities);
          // .filter(
          //   (val) =>
          //     val.partners_types_association.some(
          //       ({ morphable_id, morphable_type }) =>
          //         this.cropProduct.id == morphable_id &&
          //         morphable_type.includes(this.cropProduct.morph)
          //     )
          // );
          filtred_partner_types.forEach(
            (value) =>
              (value['title'] = this.translateService.instant(
                'partners_types.' + value.id
              ))
          );
          this.partner_types = mapLangeTitleObjects(
            filtred_partner_types,
            'title',
            this.translateService
          );

          this.partner_types = await pushOtrosToEndByAttributeName(
            this.partner_types,
            'title',
            this.translateService.instant('other_key')
          );
          values;
          return of(values);
        }),
        // switchMap((values) =>
        //   this.id ? this.partnersAPI.getOnePartner(this.id) : of(values)
        // ),
        switchMap((values: any) => {
          if (this.item?.id) {
            this.selectedPartnerType = this.partner_types.find(
              (element) => element.id == this.item.partner_type.id
            );

            this.partner = this.item;
            if (this.partner.file)
              this.partner.image_path =
                environment.appRoot + this.partner.file.path;
            else this.partner.image_path = '../../assets/empty_avatar.png';

            // this.selectedCrop = this.cropProduct?.products.find(
            //   (val) =>
            //     val?.crop_id == this.partner?.morphable_id &&
            //     this.partner?.morphable_type
            //       ?.toLowerCase()
            //       ?.includes(val?.crop_entity)
            // );
          }
          return of(values);
        })
      )
      .subscribe((values: any) => {});
    this.selectedCrop = this.cropProduct.products[0];

    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
  }

  confirm_method() {
    this.partner.partners_type_id = this.selectedPartnerType?.id;
    if (this.item?.id == undefined) {
      this.submitForm();
    } else {
      this.updateForm();
    }
  }
  handelSelectedPartner(event) {
    if (this.selectedPartner) {
      this.partner.id = this.selectedPartner.id;
      this.partner.name = this.selectedPartner.name;
      this.partner.partners_type_id = this.selectedPartner.partners_type_id;
      this.partner.file_id = this.selectedPartner.file_id;
      this.partner.personal_identification_number =
        this.selectedPartner.personal_identification_number;
    }
  }
  image_path;
  async submitForm() {
    if (this.image_path) {
      this.partner.blobFile = await fetch(this.image_path).then((result) =>
        result.blob()
      );
    }
    this.partner.file = this.image_path;
    const error_all_fields = await this.translateService
      .get('titles.partner_modal.partner_error')
      .toPromise()
      .then((value) => value);
    const partnerExist = await this.translateService
      .get('titles.partner_modal.partner_exist')
      .toPromise()
      .then((value) => value);
    if (!this.add_partner && this.selectedPartner) {
      this.partner.id = this.selectedPartner.id;
      this.partner.name = this.selectedPartner.name;
      this.partner.partners_type_id = this.selectedPartner.partners_type_id;
      this.partner.file_id = this.selectedPartner.file_id;
    }
    let existedPartners = this.selectedPartnerType?.partners?.concat(
      this.existedPartner
    );

    if (
      this.partner.name &&
      existedPartners?.findIndex(
        (val) => val?.name?.toLowerCase() == this.partner?.name?.toLowerCase()
      ) != -1 &&
      !this.partner.id
    ) {
      await this.toastUtils.toastBuilder(partnerExist, '', '', 'dark', 1000);
      return;
    }
    // if(this.selectedPartnerType?.partners.findIndex((val)=>val?.name?.toLowerCase()==this.partner?.name?.toLowerCase() ) && !this.partner?.id){
    //   this.toastUtils
    //   .toastBuilder(partnerExist, partnerExist, '')
    //   .then((value) => value?.present());
    //   return;
    // }

    if (
      !this.selectedPartnerType ||
      (this.add_partner && this.partner.name?.trim() === '') ||
      (this.add_partner && !this.partner.name)
    ) {
      await this.toastUtils.toastBuilder(
        error_all_fields,
        error_all_fields,
        ''
      );
      return this.selectedPartnerType;
    } else if (
      ((this.add_partner && this.partner?.type === '') ||
        (this.add_partner && !this.partner?.type)) &&
      this.selectedPartnerType?.is_commentable
    ) {
      await this.toastUtils.toastBuilder(
        error_all_fields,
        error_all_fields,
        ''
      );
    } else if (!this.add_partner && !this.selectedPartner) {
      await this.toastUtils.toastBuilder(
        error_all_fields,
        error_all_fields,
        ''
      );
      return;
    }
    // if (this.add_partner && !this.selectedCrop) {
    //   await this.toastUtils.toastBuilder(
    //     error_all_fields,
    //     error_all_fields,
    //     ''
    //   );
    //   return;
    // } else
    else {
      console.log(this.selectedCrop);
      this.partner.owner_id = this.owner?.id;
      this.partner.country_id = this.owner?.country_id;
      this.partner.morphable_id = this.selectedCrop?.crop_id;
      this.partner.morphable_type =
        'App\\Models\\' +
        this.capitalizeFirstLetter(this.selectedCrop?.crop_entity);

      let props = {
        file: this.image_path,
        hasSelectedPhoto: this.partner.hasSelectedPhoto,
      };
      this.store.dispatch(
        addPartner({
          data: this.partner,
          props: props,
        })
      );
      this.modalController.dismiss({
        dismissed: true,
      });
    }
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  async updateForm() {
    const error_all_fields = await this.translateService
      .get('titles.partner_modal.partner_error')
      .toPromise();

    if (this.partner.name?.trim() === '' || !this.partner.name) {
      await this.toastUtils.toastBuilder(
        '',
        error_all_fields,
        error_all_fields,
        ''
      );
    } else if (
      (this.partner.type === '' || !this.partner.type) &&
      this.selectedPartnerType.is_commentable
    ) {
      await this.toastUtils.toastBuilder(
        '',
        error_all_fields,
        error_all_fields,
        ''
      );
    } else {
      const file = await fetch(this.image_path).then((result) => result.blob());
      this.partner.file = this.image_path;
      this.image_path = this.selectedPartner?.file;
      let props = {
        file: this.image_path,
        hasSelectedPhoto: this.partner.hasSelectedPhoto,
      };
      this.partner.morphable_id = this.selectedCrop?.crop_id;
      this.partner.morphable_type =
        'App\\Models\\' +
        this.capitalizeFirstLetter(this.selectedCrop?.crop_entity);

      this.store.dispatch(
        updatePartner({
          data: this.partner,
        })
      );
      this.modalController.dismiss({ dismissed: true });

      // this.partnersAPI.updatePartner(this.partner, null).pipe(
      //   switchMap(async (value: any) => {
      //     if (value.success) {
      //       if (value.data.file && this.image_path) {
      //         return this.partnersAPI.updatePartnerMemberPhoto(value.data.file.id, file);
      //       }
      //     }
      //     return of(value);
      //   })

      // ).subscribe(
      //   (value) => {
      //     this.modalController.dismiss({ dismissed: true });
      //   },
      //   (e) => console.log(e)
      // );
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
    this.hasSelectedPhoto = true;
  }

  valueChanged() {
    this.emptyArray = true;
    this.selectedPartner = null;
  }

  async addPhotoToGallery() {
    await this.photoService
      .addNewToGallery()
      .then(async (value: PhotoTaken) => {
        const blob = await fetch(value?.webviewPath).then((response) =>
          response.blob()
        );
        if (value.webviewPath) {
          await this.showModificationModal(
            this.sanitizer.bypassSecurityTrustResourceUrl(value?.webviewPath)
          );
        }
      });
  }

  async showModificationModal(image_path) {
    let componentProps = {
      image_path: image_path,
      modalController: this.modalController,
      cancel: await this.translateService.get('cancel').toPromise(),
      confirm: await this.translateService.get('confirm').toPromise(),
    };
    let modal = await this.toastUtils.modalBuilder(
      PhotoEditorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    if (data?.cancel) {
      this.cancelar();
    }
    if (data?.image && data?.img64String) {
      if (data.img64String.length * 2 > 10 * 2 ** 40) {
        await this.toastUtils.toastBuilder(
          '',
          'bottom',
          await this.translateService.get('errors.img_size').toPromise(),
          'danger'
        );
      } else {
        this.image_path = data?.image;
        this.partner.hasSelectedPhoto = true;
      }
    }
  }

  async cancelar() {
    this.partner.image_path = await Storage.get({ key: dev.FILE_KEY }).then(
      (value) => {
        const savedPath = JSON.parse(value.value);
        if (savedPath?.path) {
          return environment.appRoot + JSON.parse(value.value).path;
        } else return 'assets/empty-profile-avatar.svg';
      }
    );
    this.hasSelectedPhoto = false;
  }
}
