import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from '../../../utils/toast_builder';
import { DomSanitizer } from '@angular/platform-browser';
import { PhotoEditorComponent } from 'src/app/components/personal-data/profile-photo/photo-editor/photo-editor.component';
import { PhotoService, PhotoTaken } from '../../../services/photo.service';
import { Component, OnInit, Injectable } from '@angular/core';
import {
  ModalController,
  NavParams,
  AlertController,
  ToastController,
  LoadingController,
} from '@ionic/angular';

import { dev } from '../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-team-modal',
  templateUrl: './team-modal.page.html',
  styleUrls: ['./team-modal.page.scss'],
  providers: [EmployeesAPI],
})
export class TeamModalPage implements OnInit {
  componentSource;
  modalTitle: string;
  modelId: number;
  actionModal: string;
  farm;
  isDashboardView;
  remunerado;
  file;
  connectedUser;
  is_permanent: any;
  countrie;
  nameEmp;
  gender;
  sex;
  model;
  image_path;
  hasSelectedPhoto = false;
  loadingModal;
  token;
  lang;
  formConfig;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public alertController: AlertController,
    public toastController: ToastController,
    public empAPI: EmployeesAPI,
    private router: Router,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private loadingController: LoadingController,
    public translateService: TranslateService,
    private toastUtil: ToastUtils,
    private routingConfig: RoutingConfig,
    private store:Store
  ) {}

  async ngOnInit() {
    this.loadingModal = await this.loadingController.create();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.modelId = this.navParams?.data?.paramID?.id;
    this.model = this.navParams?.data?.paramID;
    let ClassName= 'TeamModalPage'
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName)
    this.modalTitle = this.navParams.data.paramTitle;
    this.componentSource = this.navParams.data.componentSource;
    this.actionModal = this.navParams.data.action;
    this.farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    this.connectedUser = JSON.parse(
      (await Storage.get({ key: dev.USER_KEY })).value
    ).id;


    this.token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
    if (this.actionModal == 'update') {
      var emp;
      if (this.componentSource == 'family') {
        emp = {
          farm_id: this.farm.id,
          morphable_id: this.modelId ? this.modelId : this.connectedUser,
          morphable_type: this.modelId ? 'FamilyMember' : 'Owner',
        };
      } else if (
        this.componentSource == 'temporal' ||
        this.componentSource == 'permanent'
      ) {
        emp = {
          farm_id: this.farm.id,
          id: this.modelId,
        };
      }
      this.loadData(this.model);
    }
    this.langageChanger(
      await Storage.get({
        key: dev.LANGAGE_KEY,
      }).then((value) => value.value)
    );
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
  }

  loadData(employes) {
    this.remunerado = employes.is_receiving_salary;
    if (employes.file) {
      this.file = employes.file;
    }
    this.nameEmp = employes.name;
    if (employes.sex == 'man') {
      this.gender = 1;
    } else if (employes.sex == 'woman') {
      this.gender = 2;
    } else {
      this.gender = 3;
    }
  }

  langageChanger(lang) {}

  confirm() {
    this.sendForm();
  }

  async choosePhoto() {
    await this.photoService
      .addNewToGallery()
      .then(async (value: PhotoTaken) => {
        const blob = await fetch(value?.webviewPath).then(response => response.blob());
        if(value.webviewPath){
          await this.showModificationModal(
            this.sanitizer.bypassSecurityTrustResourceUrl(value?.webviewPath)
          );
        }
      });
    this.hasSelectedPhoto = true;
  }

  async showModificationModal(image_path) {
    let componentProps = {
      image_path: image_path,
      modalController: this.modalController,
      cancel: await this.translateService
        .get('screens.person_employe_modal.cancel')
        .toPromise(),
      confirm: await this.translateService
        .get('screens.person_employe_modal.confirm')
        .toPromise(),
    };
    let modal = await this.toastUtil.modalBuilder(
      PhotoEditorComponent,
      componentProps
    );

    const { data } = await modal.onWillDismiss();
    if (data?.image && data?.img64String) {
      if (data.img64String.length * 2 > 10 * 2 ** 40) {
       await this.toastUtil
          .toastBuilder(
            '',
            'bottom',
            await this.translateService
              .instant('screens.person_employe_modal.errors.img_size'),
            'danger'
          );
      } else {
        this.image_path = data?.image;
      }
    }
  }
  async sendForm() {
    this.loadingModal.present();
    const form = {
      name: this.nameEmp, 
      sex: this.sex, 
      farm_id: this.farm.id, 
      is_receiving_salary: this.remunerado || 0, 
      is_permanent: null, 
      morphable_type: null, 
      morphable_id: null, 
      id_update: null,
      file: this.file,
      is_admin: 0
    };
    if (this.nameEmp) {
      if (this.gender == 1) {
        form.sex = 'man';
      } else if (this.gender == 2) {
        form.sex = 'woman';
      } else {
        form.sex = 'other';
      }
      if (this.componentSource == 'permanent') {
        form.is_permanent = 1;
        form.id_update = this.modelId;
      }
      if (this.modelId && this.componentSource == 'family') {
        form.morphable_type = 'FamilyMember';
        form.morphable_id = this.modelId;
      } else if (this.modelId == null && this.componentSource == 'family') {
        form.morphable_type = 'Owner';
        form.morphable_id = this.connectedUser;
      }
      if (this.componentSource == 'temporal') {
        form.is_permanent = 0;
        form.id_update = this.modelId;
      }

      if (!form.id_update) {
        this.empAPI.saveEmployee(form).subscribe((val) => {
          if (this.hasSelectedPhoto) this.insertImage(val, 'store');
          else this.closeModal(val, 'store');
        },
        (err) => {
          console.log(err);
        })
      } else {
        form['id'] = this.modelId;
        this.empAPI.updateEmployee(form).subscribe((value: any) => {
          if (this.hasSelectedPhoto) {
            if (form?.file?.id) {
              this.updateImage(form?.file?.id, value);
            } else if (this.hasSelectedPhoto && !form?.file?.id) {
              this.insertImage(form, 'update');
            }
          } else {
            this.closeModal(form, 'update');
          }
        });
      }
    } else {
    await  this.toastUtil
        .toastBuilder(
          '',
          'bottom',
          await this.translateService
            .instant('errors.all_fields'),
          'danger'
        );
      this.loadingModal.dismiss();
    }
  }

  async insertImage(empl, role) {
    const file = await fetch(this.image_path).then((result) => result.blob());
    let id = empl.id;

    this.empAPI.storeEmployeeProfilePhoto(id, file).subscribe(
      (value) => {
        this.closeModal(value, role);
      },
      (err) => {
        this.closeModal(err, 'err');

        console.log(err);
      }
    );
  }

  async updateImage(id, oldData) {
    this.file = await fetch(this.image_path).then((result) => result.blob());

    this.empAPI.updateEmployeeMemberPhoto(id, this.file).subscribe(
      async (value) => {
  
        oldData['file'] = value;
        this.closeModal(oldData, 'update');
      },
      (err) => {
        this.closeModal(err, 'err');
        console.log(err);
      }
    );
  }
  async presentToast(message, type) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1000,
      position: 'top',
      color: type,
    });
    toast.present();
  }

  async closeModal(val, role) {
    const onClosedData: any = { value: val, role: role };
    this.loadingModal.dismiss();
    await this.modalController.dismiss(onClosedData);
  }
}
