import { cloneDeep } from 'lodash';
import {
  addAgriProduct,
  loadAgriProduct,
} from './../../../../utils/states-management/actions/agri-product.action';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductAPI } from 'src/app/apis/product.service';
import routes from '../../../../config/routing.json';
import {
  mapLangeTitleObjects,
  orderAlphabaticallyNames,
} from 'src/app/utils/array-filtrer';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, take } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Store } from '@ngrx/store';
import { selectAllAgriProducts } from 'src/app/utils/states-management/reducers/agri-product.reducer';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Good } from 'src/app/models/good.model';
import { MORPH_Product } from 'src/app/utils/consts';
@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
})
export class ProductFormComponent implements OnInit {
  @Input() modalController;
  @Input() is_preproduct_view;
  @Input() title;
  isDashboardView;
  categories;
  productsWithTrees = [40, 41, 42, 43, 44, 45, 49, 16];
  displayableCategories;
  is_list_view = true;
  selected_categories: any[] = null;
  lang;
  country;
  is_loading = true;
  formConfig;

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private userInfo: UserInfo,
    private translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private store: Store<{ agriculturalProduct: any }>
  ) {}
  customPopoverOptions: any = {
    cssClass: 'popover-wide',
  };

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
    const cropConfig = await this.routingConfig.getCropFormConfig();
    this.lang = this.translateService.currentLang;
    this.country = await this.userInfo.getCountry();
    let ClassName = 'PreProductSelectorComponent';

    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);

    this.store.select('agriculturalProduct').subscribe((value) => {
      this.is_loading = value.loading;
       this.categories = cloneDeep(Array.from(Object.values(value.entities)));
      let existedProduct = cloneDeep(value.selectedProducts);
      // if (this.categories.length == 0) {
      //   this.store.dispatch(loadAgriProduct());
      // }
      // console.log(this.categories)
      if (Array.isArray(this.categories)) {
        this.categories.forEach((element) => {
          element.categories_name = this.translateService.instant(
            'category.' + element.id
          );
        });
        this.categories = mapLangeTitleObjects(
          this.categories,
          'categories_name',
          this.translateService
        );


        this.categories.forEach((category) => {
        category.products = category?.products.filter(
          (prod) => !existedProduct.some((val) => prod.product_id == val?.id)
        );
      })
        this.categories = this.categories.map((category) => {
       
          category?.products.map(
            (value) =>
              (value.name = this.translateService.instant(
                'product.' + value.product_id
              ))
          );
          category.products = mapLangeTitleObjects(
            category.products,
            'name',
            this.translateService
          );
          return category;
        });

        // if (cropConfig?.crop_entity?.includes('product'))
        //   this.markCrop(cropConfig);
      }
    });
    // this.productAPI
    //   .filteredProduct()
    //   .pipe(
    //     map((categories) => {
    //       return categories;
    //     }),
    //     take(1),
    //     switchMap((categories: any[]) => {
    //       return of(categories);
    //     }),
    //     take(1)
    //   )
    //   .subscribe((e) => {
    //     console.log(e)
    //     this.categories = e;
    //     if (Array.isArray(this.categories)) {
    //       this.categories = this.categories.map((category) => {
    //         category.products = mapLangeTitleObjects(
    //           category.products,
    //           'name',
    //           this.lang
    //         );
    //         return category;
    //       });
    //       this.categories = mapLangeTitleObjects(
    //         this.categories,
    //         'categories_name',
    //         this.lang
    //       );
    //       if (cropConfig.crop_entity.includes('product'))
    //         this.markCrop(cropConfig);
    //     }
    //   });
  }

  markCrop(cropConfig) {
    this.categories.forEach((category) => {
      category.products = category.products.map((element) => {
        if (cropConfig.crop_id.includes(element.product_id)) {
          element.is_checked = true;
          element.is_crop = true;
        }
        return element;
      });
    });
  }

  cancel() {
    this.modalController.dismiss({ canceled: true });
  }

  async submit() {
    if (this.is_list_view) {
      await this.sortSelectedList();
      if (this.isDashboardView || !this.formConfig.quantities_required)
        if (this.OtherProductsIsSelectedAndEmpty() == true) {
          this.displayErrorToast();
        } else {
          this.dismissAndReturnsValues();
        }
    } else {
      var has_error = false;
      this.displayableCategories.forEach((element) => {
        if (!element.unit && element.qty) has_error = true;
        if (element.comment == '') has_error = true;
      });
      if (has_error) {
        this.toastUtils.toastBuilder(
          this.translateService.instant('errors.missing_fields'),
          '',
          ''
        );
        return;
      } else {
        this.dismissAndReturnsValues();
      }
    }
  }

  OtherProductsIsSelectedAndEmpty() {
    for (const element of this.displayableCategories) {
      if (
        element.is_commentable == 1 &&
        (typeof element.comment == 'undefined' || element.comment === '')
      ) {
        return true;
      }
    }
    return false;
  }

  async displayErrorToast() {
    this.toastUtils.toastBuilder(
      this.translateService.instant('label.required_input_no_html'),
      '',
      '',
      ''
    );

    this.is_list_view = true;
  }

  async sortSelectedList() {
    var i = 0;
    this.displayableCategories = new Array();
    this.categories.filter((element) => {
      if (Array.isArray(element.products)) {
        element.products.forEach((product) => {
          product.category_id = element.id;
          if (product.is_checked) {
            if (product.comment) {
              product.name = product.comment;
              product.name_en = product.comment;
            }
            i++;
            if (this.productsWithTrees.includes(product.product_id)) {
              product.trees = { has_trees: true, trees_number: 0 };
            }
            this.displayableCategories.push(product);
          }
        });
      }
      this.displayableCategories = orderAlphabaticallyNames(
        this.displayableCategories,
        this.lang
      );
    });
    if (i > 0) {
      this.is_list_view = !this.is_list_view;
    } else {
      this.toastUtils.toastBuilder(
        this.translateService.instant('errors.no_product'),
        '',
        ''
      );
    }
  }

  async dismissAndReturnsValues() {
    const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));
    const owner_id = await this.userInfo.getOwner().then((value) => value.id);
    if (
      Array.isArray(this.displayableCategories) &&
      this.displayableCategories.length > 0
    ) {
      this.displayableCategories = this.displayableCategories.map((element) => {
        let productions = new ProductionsPerfarm();
        productions.goods = new Array<Good>();
        productions.goods.push(new Good());
        productions.morphable_type = MORPH_Product;
        productions.morphable = element;
        productions.morphable_id = element.product_id;
        if (element.trees && element.trees.has_trees) {
          productions.trees_numbers = element.trees.trees_number;
        } else {
          productions.trees_numbers = -1;
        }
        if (element.qty) {
          productions.goods[0].qty = element.qty;
        }
        if (element.unit) {
          productions.goods[0].unit = element.unit;
        }
        if (element.comment) productions.comment = element.comment;
        else productions.comment = null;
        productions.goods[0].owner_id = owner_id;
        productions.is_crop = element.is_crop ? 1 : 0;
        productions.is_animal = 0;
        productions.farm_id = farm.id;
        return productions;
      });
    }
    this.store.dispatch(
      addAgriProduct({ productions: this.displayableCategories })
    );
    setTimeout(() => {
      this.modalController.dismiss({
        products: this.displayableCategories,
        canceled: false,
      });
    }, 500);
  }

  async sortList() {
    this.categories.forEach((categorie, index1) => {
      categorie.products.forEach((product, index) => {
        if (
          product.name
            .toLowerCase()
            .includes(this.translateService.instant('other_key'))
        ) {
          categorie.products.splice(index, 1);
          categorie.products.push(product);
        }
      });
      categorie.is_expanded = false;
      if (
        categorie.categories_name
          .toLowerCase()
          .includes(this.translateService.instant('other_key'))
      ) {
        this.categories.splice(index1, 1);
        this.categories.push(categorie);
      }
    });
  }

  async reset() {
    this.categories.map((element, index) => {
      element.products.forEach((item) => {
        if (item.product_id != 16) item.is_checked = false;
      });
      setTimeout(() => {
        if (element.has_checked) {
          element.has_checked = false;
          element.is_expanded = false;
          this.categories[index] = element;
        }
      }, 100);
    });

    setTimeout(() => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  unitChanged(event, product) {
    product.unit = event.target.value.value;
  }
}
