import { take, switchMap, map } from 'rxjs/operators';
import { from, lastValueFrom, of } from 'rxjs';
import { MaterialProductAPI } from 'src/app/apis/material_product.service';
import { ProductAPI } from 'src/app/apis/product.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
// import { appConfig } from '../config/app-config';
import { dev } from '../config/offlines_keys.json';
import routes from '../config/routing.json';
import { ProgramasAPI } from '../apis/programas.service';
import { Store } from '@ngrx/store';
import Owner from '../models/owner.model';
import { cloneDeep } from 'lodash';
import {
  getAppConfig,
  getCountry,
} from './states-management/selector/countries.selector';
import { MORPH_MaterialCategorie, MORPH_Product } from './consts';
import { appConfig } from '../config/app-config-ivorycoast';

@Injectable({ providedIn: 'root' })
export class RoutingConfig {
  constructor(
    private router: Router,
    private ProductAPI: ProductAPI,
    private materialProductAPI: MaterialProductAPI,
    private programAPI: ProgramasAPI,
    private store: Store<{ owner: Owner }>
  ) {}
  landingProgress;
  appConfig: any;
  routingConfig: any;
  cropProducts;
  cropConfig;

  async getNextUrl() {
    await this.getConfigObject();
    if (this.routingConfig) {
      let index =
        this.routingConfig.findIndex((element) => {
          return element.route.includes(this.router.url);
        }) + 1;
      return this.routingConfig[index]?.route;
    } else return null;
  }

  async getBackUrl() {
    await this.getConfigObject();
    if (this.routingConfig) {
      let index =
        this.routingConfig.findIndex((element) => {
          return element.route.includes(this.router.url);
        }) - 1;

      return this.routingConfig[index]?.route;
    } else return null;
  }

  async getLandingProgress() {
    await this.getConfigObject();
    if (this.routingConfig) {
      let routeIndex = this.routingConfig.findIndex((element) => {
        return (
          element.route == this.router.url ||
          (element.route.includes(this.router.url) &&
            !this.router.url.includes('/person'))
        );
      });

      if (routeIndex > 1 && routeIndex != this.routingConfig.length - 2) {
        this.landingProgress = routeIndex / this.routingConfig.length;
        return this.landingProgress;
      } else if (routeIndex == this.routingConfig.length - 2) {
        return 1;
      } else if (routeIndex == -1) {
        return routeIndex;
      } else {
        return 0;
      }
    } else {
      return null;
    }
  }

  // crop config
  async getCropFormConfig() {
    await this.getConfigObject();
    if (this.appConfig) {
      this.cropConfig = this.appConfig.cropConfig;
      return this.appConfig.cropConfig;
    } else return null;
  }

  //dashboard  config
  async getDashboardConfig() {
    await this.getConfigObject();
    return this.appConfig.dashboard;
  }

  //landing header config
  async getLandingRoutingConfig() {
    await this.getConfigObject();

    return this.getRoutingFilter();
  }
  async getProfileFormConfig() {
    await this.getConfigObject();

    return this.appConfig != null ? this.appConfig.profileConfig : null;
  }
  async getMenuFormConfig() {
    await this.getConfigObject();

    return this.appConfig != null ? this.appConfig.menuConfig : null;
  }
  async getPageFormConfig(component_name = '') {
    let isDashboardView = this.router.url === '/' + routes.dashboard.profile;
    let config;
    await this.getConfigObject();

    if (isDashboardView || component_name) {
      config = this.routingConfig?.find((element) => {
        if (element?.component_name) {
          return element?.component_name == component_name;
        } else return false;
      });
    } else {
      config = this.routingConfig.find((element) => {
        return element.route.includes(this.router.url);
      });
    }
    return config?.formConfig;
  }

  async getConfigObject() {
    let APP_CONFIG =  await lastValueFrom(this.store.select(getAppConfig).pipe(take(1)))
    // let APP_CONFIG = appConfig;
    if (APP_CONFIG) {
      this.appConfig = APP_CONFIG;
      this.routingConfig = this.appConfig.landing.routingConfig;
      await this.getRoutingFilter();
      return this.appConfig;
    }
    return null;
  }

  async getRoutingFilter() {
    let owner = cloneDeep(
      await lastValueFrom(this.store.select('owner').pipe(take(1)))
    );
    if (owner && owner?.profile_info) {
      this.cropProducts = JSON.parse(owner?.profile_info)?.cropConfig;
      if (this.cropProducts && this.cropProducts?.length == 0) {
        this.routingConfig = this.routingConfig?.filter((element) => {
          return element?.route != '/partner';
        });
      }
    }

    return this.routingConfig;
  }

  async getListProgrammesConfig() {
    await this.getConfigObject();
    let country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    var programmes = await this.programAPI
      .getProgramasByCountryID(country.id)
      .toPromise();

    var vote = await this.programAPI.getVotes().toPromise();
    if (vote) {
      programmes.forEach((prog) => {
        var exist = vote?.find((el) => el.programas_id === prog.id);
        if (exist) {
          prog.checked = true;
          prog.vote = exist;
          prog.stars = Array(parseInt(prog.vote?.stars_number));
        } else {
          prog.checked = false;
          prog.vote = null;
          prog.stars = Array();
        }
      });
    }

    if (programmes && programmes.length > 0) {
      programmes.forEach((prog) => {
        prog.config = this.appConfig?.programmes?.programmesConfig?.find(
          (p) => p.programme_id == prog.id
        );
      });
    }
    console.log('programmes inside getListProgrammesConfig', programmes);

    return programmes;
  }

  async saveCropProductListToStorage() {
    await this.getCropFormConfig();

    this.ProductAPI.getProductById(this.cropConfig?.crop_id)
      .pipe(
        take(1),
        switchMap((products: any) => {
          products.map((value) => {
            value.morph = 'App\\Models\\Product';
          });
          this.cropProducts = products;
          return of(products);
        }),
        switchMap(async (value) => {
          if (this.cropConfig?.material_category_crop_id) {
            return this.materialProductAPI.getAllAnimalCategories();
          } else {
            return of(value);
          }
        }),
        switchMap((element) => element),
        map(async (val: any) => {
          if (
            this.cropConfig &&
            Array.isArray(this.cropConfig?.material_category_crop_id) &&
            this.cropConfig?.material_category_crop_id[0] &&
            val
          ) {
            var animal = val.find(
              (category: any) =>
                category?.id == this.cropConfig?.material_category_crop_id[0]
            );
            let animalProduct = animal?.childrens.find(
              (child) => child?.id == this.cropConfig?.material_crop_id[0]
            );
            animalProduct.material_category_id =
              this.cropConfig?.material_category_crop_id[0];
            animalProduct.morph = 'App\\Models\\MaterialCategorie';
            this.cropProducts.push(animalProduct);
            return of(this.cropProducts);
          }
        })
      )
      .subscribe(async (data: any) => {
        await Storage.set({
          key: dev.CROP_PRODUCT,
          value: JSON.stringify(this.cropProducts),
        });
      });
  }
}
