import { RoutingConfig } from 'src/app/utils/routing-config';
import { UserInfo } from './../../apis/users-info.service';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { Platform } from '@ionic/angular';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import routes from '../../config/routing.json';
import { IonButton } from '@ionic/angular';
import Owner from 'src/app/models/owner.model';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { setCheckPointRoute } from 'src/app/utils/states-management/actions/owner.action';
import { lastValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss'],
})
export class LandingHeaderComponent implements OnInit, OnChanges {
  tab_names: any[] = [];
  hideAll = true;
  @ViewChild('scrollable', { static: false }) scrollable: ElementRef;
  @ViewChildren(IonButton) scrollTo;
  @Input() current_flow;
  @Input() current_tab;
  checkpoint = 0;
  owner: Owner;
  screenWidth;
  itemWidth = 0;
  appLandingConfig;
  cropProducts;
  currentRoute;
  isDashboardView;

  landingTabsLabel = [
    {
      label: 'landingTabsLabel.profile',
      selected: false,
      index: 0,
      current: false,
    },
    {
      label: 'landingTabsLabel.family',
      selected: false,
      index: 1,
      current: false,
    },
    {
      label: 'landingTabsLabel.farm',
      selected: false,
      index: 2,
      current: false,
    },
    {
      label: 'landingTabsLabel.team',
      selected: false,
      index: 3,
      current: false,
    },
    {
      label: 'landingTabsLabel.partners',
      selected: false,
      index: 4,
      current: false,
    },
    {
      label: 'landingTabsLabel.goods',
      selected: false,
      index: 5,
      current: false,
    },
    {
      label: 'landingTabsLabel.debts',
      selected: false,
      index: 6,
      current: false,
    },
    {
      label: 'landingTabsLabel.programs',
      selected: false,
      index: 7,
      current: false,
    },
  ];
  constructor(
    private store: Store<{ owner: Owner }>,
    private router: Router,
    public translateService: TranslateService,
    platform: Platform,
    private routingConfig: RoutingConfig
  ) {
    this.isDashboardView = this.router.url === '/' + routes.dashboard.profile;
    platform.ready().then(() => {
      this.screenWidth = platform.width();
    });
  }
  // ngDoCheck(): void {
  //   // this.updateView();
  // }

  async init() {
    await this.routingConfig
      .getLandingRoutingConfig()
      .then((value) => (this.appLandingConfig = value));
    const tabIndex = Array.from(
      new Set(this.appLandingConfig?.map((item) => item.tabIndex))
    );


    if (this.landingTabsLabel?.length > 0)
      this.tab_names = this.landingTabsLabel?.filter((item) =>
        tabIndex?.includes(item?.index)
      );
    this.updateView();

    this.router.events.subscribe((val) => {
      this.updateView();
    });
    await this.saveRoute(this.router.url);
  }
  async ngOnInit() {
    await this.init();

    this.router.url.includes('/home')
      ? (this.hideAll = true)
      : (this.hideAll = false);
  }

  updateView() {
    if (this.appLandingConfig?.length > 0) {
      this.current_tab = this.appLandingConfig.find(
        (val) => val.route == this.router.url
      )?.tabIndex;
    }
    this.tab_names.forEach((element, index) => {
      if (index < this.current_tab) {
        element.selected = true;
        element.current = false;
        element.selected_current = true;
      } else if (index == this.current_tab) {
        element.selected = true;
        element.current = true;
        element.selected_current = false;
      } else if (index > this.current_tab) {
        element.selected = false;
        element.current = false;
        element.selected_current = false;
      }
    });
    this.itemWidth = this.screenWidth / this.tab_names.length;

    if (this.current_tab > this.tab_names.length / 2 - 1) {
      setTimeout(() => {
        this.scrollable.nativeElement.scrollTo(2000, 2000);
      }, 200);
    } else {
      setTimeout(() => {
        this.scrollable.nativeElement.scrollTo(0, 0);
      }, 200);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.updateView();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updateView();
  }
  navTo(label) {
    this.current_tab = label.index;
    switch (label?.index) {
      case 0:
        this.router.navigateByUrl('/person/profile-information');
        break;
      case 1:
        this.router.navigateByUrl(
          routes.family.main_routes + '/' + routes.family.family_members
        );

        break;
      case 2:
        this.router.navigateByUrl(routes.farm.farm_address_details);
        break;
      case 3:
        this.router.navigateByUrl(routes.team.main_routes);
        break;
      case 4:
        this.router.navigateByUrl('/partner');
        break;
      case 5:
        this.router.navigateByUrl(routes.goods.main_routes);
        break;

      case 6:
        this.router.navigateByUrl(routes.debts.main_routes);
        break;

      case 7:
        this.router.navigateByUrl('/prog');
        break;
    }
    this.updateView();
  }

  async saveRoute(route) {
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    if (this.owner?.id && !this.isDashboardView && this.current_tab > 0) {
      this.store.dispatch(setCheckPointRoute({ url: route }));
    }
  }
}
