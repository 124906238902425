import {
  addAgriProduct,
  addAgriProductSuccess,
  deleteSelectedAgriProduct,
  deleteSelectedAgriProductSuccess,
  loadAgriProduct,
  loadAgriProductSuccess,
  loadSelectedAgriProduct,
  loadSelectedAgriProductSuccess,
} from './../actions/agri-product.action';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProductAPI } from 'src/app/apis/product.service';
import { dataSyncWithServerError } from '../actions/owner.action';
import { of } from 'rxjs';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';

@Injectable()
export class AgriProductEffect {
  constructor(
    private actions$: Actions,
    private productAPI: ProductAPI,
    private productionAPI: ProductionAPI //   public translateService: TranslateService,
  ) {}
  loadAgriProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAgriProduct),
      switchMap((action) => {
        return this.productAPI.filteredProduct(0);
      }),
      map((data: any) => {
        return loadAgriProductSuccess({ agriculturalProducts: data });
      }),
      catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  loadSelectedAgriProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectedAgriProduct),
      switchMap((action) => {
        return this.productionAPI.getProductionByFarmAndType(0);
      }),
      map((data: any) => {
        return loadSelectedAgriProductSuccess({
          selectedAgriculturalProducts: data,
        });
      })
      //   catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  deleteSelectedAgriProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSelectedAgriProduct),
      switchMap((action) => {
        return this.productionAPI.deleteFarmProduction(action?.product?.production_per_farm_id);
      }),
      map((data: any) => {
        return deleteSelectedAgriProductSuccess();
      }),
    //   catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  addAgriProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAgriProduct),
      switchMap((action) => {
        return this.productionAPI.insertMultipleProduction(action.productions);
      }),
      map((data: any) => {
        return addAgriProductSuccess({
          productions: data.filter((value) => value.is_animal == 0),
        });
      }),
      // catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
}
