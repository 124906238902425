import { map } from 'rxjs/operators';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { MatStepper } from '@angular/material/stepper';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { IonNav, ModalController, PopoverController } from '@ionic/angular';
import { MyPartnersAPI } from '../../../apis/partners.service';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { ModalPartnerComponent } from '../modal-partner/modal-partner.component';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import routes from '../../../config/routing.json';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import Partner from 'src/app/models/partner.model';
import {
  deletePartner,
  loadPartner,
  loadPartnerType,
} from 'src/app/utils/states-management/actions/partner.action';
import { take } from 'rxjs';
import { selectAllPartners } from 'src/app/utils/states-management/selector/partners.selector';
import Owner from 'src/app/models/owner.model';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-partner-view-one',
  templateUrl: './partner-view-one.component.html',
  styleUrls: ['./partner-view-one.component.scss'],
  providers: [MyPartnersAPI],
})
export class PartnerViewOneComponent implements OnInit {
  langage_package;
  title;
  partner_add_btn;
  owner_id: number;
  titlemodal: string;
  next;
  return;
  bubble_desc;
  isDashboardView;
  @Input() nav: IonNav;
  @Input() rootStack;
  @Input() cropProduct;
  @Input('steps') steps: MatStepper;
  cropConfig;
  cropId;
  base_url = environment.appRoot;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public translateService: TranslateService,
    private partnerAPI: MyPartnersAPI,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private popover: PopoverController,
    private store: Store<{ partners: Partner; owner: Owner }>
  ) {
    this.store.dispatch(loadPartnerType());
    this.store.dispatch(loadPartner());
  }

  async presentModal() {
    let modal = await this.toastUtils.modalBuilder(
      ModalPartnerComponent,
      {
        cropProduct: this.cropProduct,
      },

      ['my-custom-modal-css ', 'modal-wrapper']
    );

    modal.onDidDismiss().then(() => this.get_partners());
  }
  async presentupdateModal(item) {
    let modal = await this.toastUtils.modalBuilder(
      ModalPartnerComponent,
      { item: item, cropProduct: this.cropProduct },
      ['my-custom-modal-css ', 'modal-wrapper']
    );

    modal.onDidDismiss().then(() => this.get_partners());
  }
  list = [];
  cropLabel = '';
  async getowner_id() {
    this.store
      .select('owner')
      .pipe(take(1))
      .subscribe((value) => (this.owner_id = value.id));
  }
  async ngOnInit() {
    this.cropConfig = await this.routingConfig.getCropFormConfig();
    await this.langageChanger();
    this.translateService.onLangChange.subscribe(async (value) => {
      await this.langageChanger();
    });
    await this.getowner_id();
    this.get_partners();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);

    // this.cropId = this.cropProduct.id;
    // this.cropLabel =
    //   this.cropProduct.morph == "App\\Models\\Product"
    //     ? 'product.'
    //     : 'material_category.';
  }
  async showPopOver() {
    let componentProps = { key1: this.bubble_desc };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!this.isDashboardView && this.steps.selectedIndex === 1) {
      this.showPopOver();
    }
  }
  async langageChanger() {
    this.next = await this.translateService.get('next').toPromise();
    this.return = await this.translateService.get('return').toPromise();
    this.title = await this.translateService
      .get('titles.partner_list_title')
      .toPromise();
    if (this.translateService.currentLang == 'fr') {
      this.cropProduct.id == 1
        ? (this.title += ' vos')
        : this.cropProduct.id == 16
        ? (this.title += ' votre')
        : '';
    }

    this.bubble_desc = await this.translateService
      .get('bubble_desc_partner')
      .toPromise();
  }
  get_partners() {
    this.store.select('partners').subscribe((value: any) => {
      this.list = [];

      cloneDeep(Object.values(value?.entities)).forEach((partner: any) => {
        let exist = this.cropProduct.products.some(
          (val) =>
            val?.crop_id == partner?.morphable_id &&
            partner?.morphable_type?.toLowerCase()?.includes(val?.crop_entity)
        );

        if (exist) {
          this.list.push(partner);
        }
      });
    });
  }
  async delete(id: number) {
    let modal = await showConfirmModal(
      this.popover,
      await this.translateService
        .get('confirm_delete_buyer')
        .toPromise()
        .then((value) => value),
      null,
      await this.translateService.get('confirm').toPromise(),
      await this.translateService.get('cancel').toPromise()
    );

    const { data } = await modal.onWillDismiss();
    if (data?.confirmed) {
      this.store.dispatch(deletePartner({ id: id }));

      // this.partnerAPI.deletePartner(id).subscribe(
      //   (value) => {
      //     value;
      //     this.get_partners();
      //   },
      //   (e) => {
      //     if (e.status != 200) console.log(e);
      //     else this.get_partners();
      //   }
      // );
    }
  }

  updateForm(id) {
    this.presentupdateModal(id);
  }

  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  returnToDashboard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
}
