import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit, AfterViewInit, Input, NgZone, Output, EventEmitter, ViewChild } from '@angular/core';
import * as turf from '@turf/turf';
import { ModalController, PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../../../widgets/popover/popover.component';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation } from '@capacitor/geolocation';
import { IntroductoryvideoComponent } from '../../introductoryvideo/introductoryvideo.component';
import { MapComponent as MapGmComponent } from '../widgets/map/map.component';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  markers = [];

  @Input() modalController!: ModalController;
  @Input() title;
  @Input() lotType;
  @Input() allow_draw = true;
  @Input() address_view = false;
  perimeters = null;
  calculated_area = 0;
  location_changed;
  isDashboardView;
  @Input() farm;
  @Input() showFooter = false;
  @Input() long: any;
  @Input() lot: any;
  @Input() lat: any;
  @Input() MapHeigth: any;
  @Input() popOverMsg;
  @Output() isNextClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('mapCmp') mapComponent:MapGmComponent ;
  show_superficie_input = true;
  show_map = false;
  perimeter;
  constructor(
    private router: Router,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    public ngZone: NgZone,
    public popoverController: PopoverController,

  ) {
 
  }
  async delimitationOption(data) {
    if (data && data?.data?.closed) {
      if (data.data.option == '1') {
        const popover = await this.popoverController.create({
          component: IntroductoryvideoComponent,
          cssClass: 'pop-over-style-video-prefix',
          event: null,
          translucent: true,
          componentProps: { optionSrc :'/assets/gifs/delimitation-option1.gif' },

        });
        popover.present();
        const { data } = await popover.onDidDismiss();
        this.MapHeigth = '100%';
        this.show_superficie_input = false;
        this.show_map = true;
        this.allow_draw = true;

      } else if (data.data.option == '2') {
        const popover = await this.popoverController.create({
          component: IntroductoryvideoComponent,
          cssClass: 'pop-over-style-video-prefix',
          event: null,
          translucent: true,
          componentProps: { optionSrc :'/assets/gifs/delimitation-option2.gif' },

        });
        popover.present();
        const { data } = await popover.onDidDismiss();
        this.MapHeigth = '100%';
        this.show_superficie_input = false;
        this.show_map = true;
        this.allow_draw = false;
      } else if (data.data.option == '3') {
        this.show_superficie_input = true;
        this.show_map = false;
        this.allow_draw = false;
      } else if (data?.data?.option == null && !this.address_view) {
        this.modalController.dismiss({
          dismissed: true,
          superficie: 0,
          points: [],
          perimeters: 0,
        });
        // this.show_superficie_input = true

      }
    } else {
      this.modalController.dismiss({
        dismissed: true,
        superficie: 0,
        points: [],
        perimeters: 0,
      });
    }
  }

  resetDelimitation(){
    this.mapComponent?.removeMarkers();
  }
  async ngOnInit() {
    if(this.lot?.id && this.lot?.superficie){
      this.calculated_area = this.lot.superficie
    }

    if(!this.lot?.id)
    await this.showPopOver();

    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
  }

  geolocationPermitted = false;
  delimitationPermitted = false;
  async showPopOver() {
    const permission = await Geolocation.checkPermissions();
    if (
      permission?.location !== 'granted' &&
      permission?.coarseLocation !== 'granted'
    ) {
      // let permission = await Geolocation.requestPermissions();
      this.geolocationPermitted = false;
    } else {
      this.geolocationPermitted = true;
    }
    if (
      this.geolocationPermitted ||
      (this.farm?.latitude && this.farm?.longitude && this.farm?.latitude!="0" && this.farm?.longitude!="0")
    ) {
      this.delimitationPermitted = true;
    } else {
      this.delimitationPermitted = false;
    }
    let componentProps = {
      key1: this.popOverMsg,
      is_html_inner: true,
      geolocationPermitted: this.geolocationPermitted,
      delimitationPermitted: this.delimitationPermitted,
      is_confirm_needed:this.address_view,
      is_info_popover:false
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
    const data = await popover.onWillDismiss();
    this.delimitationOption(data);
  }

  reset() {
    this.modalController.dismiss({
      dismissed: true,
      superficie: 0,
      points: [],
      perimeters: 0,
    });
  }
  locationChangedEvent(value) {
    this.geocodeLocation(value[1], value[0], value[2]);
  }

  geocodeLocation(longitude, latitude, altitude) {
    this.location_changed = true;
    this.long = longitude;
    this.lat = latitude;
  }
  async submit() {
    if (
      !this.calculated_area &&
      this.allow_draw &&
      !this.show_superficie_input
    ) {
      
        await this.toastUtils.toastBuilder(
          await this.translateService
            .instant('errors.please_enter_the_total_area'),
          null,
          null
        )
     
      this.show_superficie_input = true;
      this.show_map = false;
    }

    if (this.calculated_area) {
      this.isNextClicked.emit({
        dismissed: true,
        superficie: this.calculated_area,
        points: this.markers,
        perimeters: this.perimeters,
        long: this.long,
        lat: this.lat,
      })
      // this.modalController.dismiss({
      //   dismissed: true,
      //   superficie: this.calculated_area,
      //   points: this.markers,
      //   perimeters: this.perimeters,
      //   long: this.long,
      //   lat: this.lat,
      // });
    } else if (!this.allow_draw) {
      this.modalController.dismiss({
        dismissed: true,
        long: this.long,
        lat: this.lat,
      });
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      long: this.long,
      lat: this.lat,
    });
  }

  markedPointsChangeEvents(val) {
    this.calculated_area = (val * 100) / 10000 / 100;
  }
  perimeterChanged(val) {
    this.perimeter = val;
  }
  showSurfaceInputEvent(val) {
    this.show_superficie_input = true;
    this.show_map = false;
  }
  drawChangeEvent(val) {
    let line = [];
    this.markers = new Array();
    if (Array.isArray(val)) {
      val.forEach((element) => {
        this.markers.push({ longitude: element.lng, latitude: element.lat });
        line.push([element.lng, element.lat]);
      });
    }
    if (line.length > 2) {
      let lineString = turf.lineString(line);
      this.perimeters = turf.length(lineString, { units: 'meters' }).toFixed(2);
    }
  }
  doLater(event) {
    this.show_superficie_input = true;
    this.show_map = false;
  }
  setAmount(event) {
    this.calculated_area = event;
  }
}
