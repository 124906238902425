import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController, PopoverController } from '@ionic/angular';
import { ProgramasAPI } from 'src/app/apis/programas.service';
import { RatingModalComponent } from 'src/app/widgets/rating-modal/rating-modal.component';
import { dev } from '../../../config/offlines_keys.json';
import Vote from 'src/app/models/vote.model';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
@Component({
  selector: 'app-programs-modal',
  templateUrl: './programs-modal.component.html',
  styleUrls: ['./programs-modal.component.scss'],
})
export class ProgramsModalComponent implements OnInit {
  @Input() modalController: ModalController;
  @Input() programasAPI: ProgramasAPI;
  @Input() prog;
  @Input() vote;
  owner;
  array;
  partners;
  formConfig;
  constructor(
    private secondaryController: PopoverController,
    private routingConfig: RoutingConfig,
    public translateService: TranslateService,
    private store: Store<{ owner: Owner }>
  ) {}
  public stars: boolean[] = Array(5).fill(false);

  async ngOnInit() {
    let ClassName = 'ProgramsModalComponent';
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);

    if (!this.vote?.stars_number) {
      this.vote = new Vote();
    } else {
      for (let i = 0; i < this.vote?.stars_number; i++) {
        this.stars[i] = true;
      }
    }
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    this.partners = JSON.parse(this.prog.partners);
  }

  onModelChange(ev) {
    console.log(ev);
  }
  confirm_method() {
    if (this.vote.id) {
    } else {
      this.vote.programas_id = this.prog?.id;
      this.vote.owner_id = this.owner?.id;
    }
    if (!this.vote.comment || this.vote.comment == '') {
      this.vote.comment = null;
    }
    this.modalController.dismiss({ vote: this.vote, submitted: true });
  }

  public rate(rating: number) {
    this.stars = this.stars.map((_, i) => rating > i);
    this.vote.stars_number = rating;
  }
  // async rate() {
  //   const modal = await this.secondaryController.create({
  //     component: RatingModalComponent,
  //     componentProps: {
  //       modalController: this.secondaryController,
  //     },
  //     cssClass: 'popover-custom-rating',
  //   });
  //   modal.present();
  //   const { data } = await modal.onWillDismiss();
  //   this.vote.stars_number = data?.vote;
  //   this.array = new Array(this.vote.stars_number);
  // }
  dismiss() {
    this.modalController.dismiss({ vote: this.vote, canceled: true });
  }
}
