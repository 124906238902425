import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { CashflowCategorie } from 'src/app/models/cash-flow/cashflow_categories';
import {
  mapLangeTitleObjects,
  orderAlphabaticallyByAttribute,
  pushOtrosToEndByAttributeName,
} from 'src/app/utils/array-filtrer';

interface FormConfig {
  has_sub_categories: boolean;
  has_expenses: boolean;
  show_payment_form: boolean;
}

@Component({
  selector: 'app-cashflow-categories-cashflow-expenses-selectors',
  templateUrl:
    './cashflow-categories-cashflow-expenses-selectors.component.html',
  styleUrls: [
    './cashflow-categories-cashflow-expenses-selectors.component.scss',
  ],
})
export class CashflowCategoriesCashflowExpensesSelectorsComponent
  implements OnInit, OnChanges
{
  @Input() cashflow: CashFlow;
  @Input() is_salary_needed: boolean;
  @Output() cashflowCategoriesChange: EventEmitter<CashFlow> =
    new EventEmitter<CashFlow>();
  @Output() fromulaireRequirementChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() resetForm: EventEmitter<void> = new EventEmitter();
  @Input() categories: Array<CashflowCategorie>;
  @Input() show_title: boolean = false;
  selected_categorie: CashflowCategorie;
  formConfig: FormConfig = {
    has_sub_categories: false,
    has_expenses: false,
    show_payment_form: false,
  };

  constructor(public translateService: TranslateService) {}
  resetCategoryForm() {
    this.resetForm.emit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();

    if (
      changes.categories &&
      changes.categories.isFirstChange() == false &&
      !this.cashflow.id
    ) {
      this.selected_categorie = null;
      this.formConfig.has_sub_categories = false;
      this.formConfig.has_expenses = false;
      this.cashflow.cash_flow_categorie_id = null;
      this.cashflow.unity = null;
      this.loadData();

      this.cashflowCategoriesChange.emit(this.cashflow);
      this.fromulaireRequirementChange.emit(null);
    }
  }

  ngOnInit() {}
  loadAndTreeCategories() {
    this.categories = cloneDeep(this.categories);

    this.categories.forEach(
      (element) =>
        (element.title = this.translateService.instant(
          'cashflow_categories.' + element.id
        ))
    );
    this.categories = mapLangeTitleObjects(
      this.categories,
      'title',
      this.translateService
    );
    this.categories = pushOtrosToEndByAttributeName(
      this.categories,
      'title',
      this.translateService.instant('other_key')
    );
  }
  onChangeCategory(value: CashflowCategorie) {
    this.selected_categorie = value;
    this.resetForm.emit();
    if (
      Array.isArray(value.cash_flow_sub_category) &&
      value.cash_flow_sub_category.length > 0
    ) {
      this.formConfig.has_sub_categories = true;
    } else if (
      Array.isArray(value.cash_flow_expenses) &&
      value.cash_flow_expenses.length > 0
    ) {
      this.cashflow.cash_flow_categorie_id = value.id;
      this.cashflow.cashflow_categories = value;
      this.cashflowCategoriesChange.emit(this.cashflow);
      this.formConfig.has_expenses = true;
    } else {
      this.cashflow.cash_flow_categorie_id = value.id;
      this.cashflow.cashflow_categories = value;
      this.cashflowCategoriesChange.emit(this.cashflow);
    }

    if (this.cashflow.id && value.id == 299) {
      return;
    }
    if (value.formulaire_requirement)
      this.fromulaireRequirementChange.emit(value.formulaire_requirement);
  }

  childChange(cashflow: CashFlow) {
    this.cashflow = cashflow;
    this.cashflowCategoriesChange.emit(this.cashflow);
  }
  childFormChange(value) {
    if (typeof this.cashflow.cash_flow_categorie_id == 'number') {
      this.fromulaireRequirementChange.emit(value);
    }
  }

  async loadData() {
    this.loadAndTreeCategories();

    this.categories?.forEach((cashflowCategorie: CashflowCategorie) => {
      if (cashflowCategorie.id == this.cashflow.cash_flow_categorie_id) {
        this.onChangeCategory(cashflowCategorie);
      } else {
        if (Array.isArray(cashflowCategorie.cash_flow_sub_category)) {
          cashflowCategorie.cash_flow_sub_category.forEach(
            (cashflowSubCategorie: CashflowCategorie) => {
              if (
                cashflowSubCategorie.id == this.cashflow.cash_flow_categorie_id
              ) {
                this.onChangeCategory(cashflowCategorie);
              } else {
                if (
                  Array.isArray(cashflowSubCategorie.cash_flow_sub_category)
                ) {
                  cashflowSubCategorie.cash_flow_sub_category.forEach(
                    (cashflowSubSubCategorie: CashflowCategorie) => {
                      if (
                        cashflowSubSubCategorie.id ==
                        this.cashflow.cash_flow_categorie_id
                      ) {
                        this.onChangeCategory(cashflowCategorie);
                      }
                    }
                  );
                }
              }
            }
          );
        }
      }
    });
  }
}
