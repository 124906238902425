import {
  addPlot,
  updatePlot,
} from './../../../../utils/states-management/actions/plots.action';
import { PorductionEditorComponent } from './../../others-agricol-product-components/porduction-editor/porduction-editor.component';
import { MORPH_Product } from './../../../../utils/consts';
import { take, map, filter } from 'rxjs/operators';
import { environment } from './../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { PhotoEditorComponent } from './../../../../components/personal-data/profile-photo/photo-editor/photo-editor.component';
import { PhotoService, PhotoTaken } from './../../../../services/photo.service';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { MORPH_Lot } from '../../../../utils/consts';
import { Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import {
  IonContent,
  IonSlides,
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { MapComponent } from '../../map/map.component';
import { LotAPI } from '../../../../apis/lot.service';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { ToastUtils } from '../../../../utils/toast_builder';
import { Lot, USAGE_TYPE } from '../../../../models/lots.model';
import routes from '../../../../config/routing.json';
import { Router } from '@angular/router';
import { LotProductionSelectorComponent } from '../../widgets/lot-production-selector/lot-production-selector.component';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import {
  BUY_AGRICOLAS_LOT,
  BUY_OTRAS_USE_LOT,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { ListableProduction } from 'src/app/models/farm/production_per_farm';
import { TranslateService } from '@ngx-translate/core';
import { QuestionsUtils } from 'src/app/utils/dynamic-forms/question_utils';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { from, lastValueFrom, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import Owner from 'src/app/models/owner.model';
import { cloneDeep } from 'lodash';
import { PopoverInfoComponent } from 'src/app/widgets/popover-info/popover-info.component';
import {
  mapLangeTitleObjects,
  pushOtrosToEndByAttributeName,
} from 'src/app/utils/array-filtrer';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';
@Component({
  selector: 'app-lots-creator',
  templateUrl: './lots-creator.component.html',
  styleUrls: ['./lots-creator.component.scss'],
  providers: [LotAPI],
})
export class LotsCreatorComponent implements OnInit, DoCheck {
  @ViewChild(MapComponent) mapComponent: MapComponent;
  @ViewChild(PorductionEditorComponent)
  productionEditorComponent: PorductionEditorComponent;

  @Input() used_for: string;
  @Input() modalMain: ModalController;
  @Input() lot: Lot;
  @Input() title;
  @Input() title_header;
  @Input() just_end = true;
  add_prod_button_text;
  isDashboardView;
  modal;
  USAGE_TYPE = USAGE_TYPE;
  products: Array<ListableProduction> = [];
  currentYear;
  dateString;
  progressDialog;
  totalPercent = 0;
  lang;
  formConfig;
  hasSelectedPhoto = false;
  image_path;
  plot_delimited = false;
  img_updated = false;
  farm;
  initialSuperficie;
  showHelpDialog: boolean = false;
  @ViewChild('content', { static: false }) content: IonContent;
  totalContentHeight;
  owner: Owner;
  @ViewChild('Slider', { static: true }) slider: IonSlides;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false,
  };
  step;
  isCropFormNeeded = false;

  constructor(
    private modalController: ModalController,
    private popover: PopoverController,
    private router: Router,
    private progressContoler: LoadingController,
    private lotAPI: LotAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    public questionUtils: QuestionsUtils,
    private store: Store<{ owner: Owner; plots: any }>,
    private popoverController: PopoverController,
    private customPickerOption: PickerOptionFormater
  ) {}
  existCrop;
  forms: FormGroup[] = [];
  genetics = [];
  formsQuestions;

  async next() {
    if (this.step === 0) {
      this.mapComponent.submit();
    }

    this.existCrop = cloneDeep(
      this.products?.filter(
        (production) =>
          production.morphable_type === MORPH_Product &&
          this.crop.some((value) => value.id === production.morphable_id)
      )
    );
    if (this.existCrop.length > 0) {
      this.existCrop.forEach(async (element) => {
        if (!this.forms[element.id]) {
          this.forms[element.id] = await this.questionUtils.prepareForm(
            element
          );

          this.forms[element.id]['questions'] =
            await this.questionUtils.prepareForm(element, true);
        }
      });
    }
    if ((this.step === 2 || this.step === 3) && this.products.length === 1) {
      this.reset();
      if (this.existCrop.length == 0) {
        this.products[0].percent_in_lot = 100;
        this.products[0].used_surface = parseFloat(
          this.lot.superficie.toFixed(2)
        );
        this.existCrop = this.products[0];
        this.totalPercent = 100;
        this.totalSurface = this.lot.superficie;
        await this.submit();
        return;
      } else {
        this.isCropFormNeeded = true;
        this.products[0].percent_in_lot = 100;
        this.products[0].used_surface = parseFloat(
          this.lot.superficie.toFixed(2)
        );
        // this.existCrop = this.products[0];
        this.totalPercent = 100;
        this.totalSurface = this.lot.superficie;

        // this.slider.slideNext();
        setTimeout(() => {
          this.slider.slideNext();
        }, 100);

        return;
      }
    }

    if (this.step === 1 && this.used_for === USAGE_TYPE.other) {
      await this.submit();
      return;
    }

    if (await this.slider.isEnd()) {
      await this.submit();
    } else {
      this.slider.slideNext();
    }
  }

  prev() {
    if (this.step == 0) {
      this.mapComponent.calculated_area = 0;
      this.mapComponent?.calculated_area;
      this.mapComponent.resetDelimitation();
      this.lot.superficie = 0;
      this.lot.lands = [];
    } else {
      this.slider.slidePrev();
    }
  }

  pinFormatter(value: number) {
    return `${value}%`;
  }
  totalSurface;
  setSurface(percent_in_lot, product) {
    product.percent_in_lot = percent_in_lot;
    product.used_surface = (percent_in_lot / 100) * this.lot.superficie;
    this.totalPercent = 0;
    this.totalSurface = 0;
    this.products.forEach((element) => {
      this.totalPercent += element.percent_in_lot;
      this.totalSurface += element.used_surface;
    });
  }
  isDisabled = false;
  lastUsedStep = 0;
  ngDoCheck(): void {
    if (
      this.lot?.id ||
      (!this.lot?.id &&
        ((this.step == 0 && this.mapComponent?.calculated_area) ||
          (this.step == 1 &&
            this.used_for === USAGE_TYPE.agricol &&
            (this.lot?.planting_type ||
              (this.lot?.nature == 'other' && this.lot?.comment != ''))) ||
          (this.step == 2 && this.products?.length) ||
          (this.step == 3 && this.totalPercent == 100) ||
          (this.step >= 4 && this.products.length) ||
          this.used_for === USAGE_TYPE.other)) ||
      this.step < this.lastUsedStep
    ) {
      this.isDisabled = false;
      this.lastUsedStep < this.step ? (this.lastUsedStep = this.step) : '';
    } else {
      this.isDisabled = true;
    }
  }
  crop;

  async ngOnInit() {
    this.farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));
    this.owner = cloneDeep(
      await lastValueFrom(this.store.select('owner').pipe(take(1)))
    );
    this.crop = JSON.parse(this.owner.profile_info).cropConfig;
    this.products =
      this?.lot?.id && this?.lot?.productions
        ? cloneDeep(this?.lot?.productions)
        : [];

    this.title_header = 'titles.lot_creator_title_map';

    this.step = await this.slider.getActiveIndex();
    this.slider.ionSlideDidChange.subscribe(async (value) => {
      this.step = await this.slider.getActiveIndex();
      switch (true) {
        case this.step == 0:
          this.title_header = 'titles.lot_creator_title_map';
          break;
        case this.step == 1:
          this.title_header = 'titles.lot_creator_title_form';
          break;
        case this.step == 2:
          this.title_header = 'titles.lot_creator_title_products_selection';
          break;
        case this.step == 3:
          this.title_header = 'titles.lot_creator_title_repartition';
          break;
        case this.step >= 4:
          this.title_header = 'titles.lot_creator_title_detail';
          break;

        default:
          break;
      }
    });

    this.lang = this.translateService.currentLang;
    let ClassName = 'LotsCreatorComponent';
    this.formConfig = (
      await this.routingConfig.getPageFormConfig(ClassName)
    ).modal;
    if (this.lot?.file?.path) {
      this.image_path = environment.appRoot + this.lot?.file?.path;
      this.lot.file = this.lot?.file;
    }

    if (this.lot?.id) {
      this.initialSuperficie = this.lot?.superficie;
      this.loadPlotOwnerShip(this.lot?.nature);
      this.slider.slideTo(1, 400);
    }
    this.progressDialog = await this.progressContoler
      .create()
      .then((value) => value);
    this.totalPercent = 0;
    this.totalSurface = 0;
    if (Array.isArray(this.lot.productions))
      this.lot.productions.forEach((element: any) => {
        this.totalPercent += element.percent_in_lot;
        this.totalSurface += element.used_surface;
      });
    if (this.lot.comment == 'Not mentioned') {
      this.lot.comment = '';
    }
    this.currentYear = new Date();
    this.dateString = new Date(
      this.currentYear.getTime() - this.currentYear.getTimezoneOffset() * 60000
    );
    this.dateString = this.dateString.toISOString().split('T')[0];

    this.lot.type = this.used_for;
    await this.setFarmId();

    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
  }

  loadPlotOwnerShip(key) {
    this.list.forEach((level1) => {
      if (level1?.sub_options?.length > 0) {
        level1?.sub_options.forEach((level2) => {
          if (level2?.sub_options?.length > 0) {
            level2?.sub_options.forEach((level3) => {
              if (level3.key == key) {
                this.selectedLevel3 = level3;
                this.selectedLevel2 = level2;
                this.selectedLevel1 = level1;
              }
            });
          } else {
            if (level2.key == key) {
              this.selectedLevel2 = level2;
              this.selectedLevel1 = level1;
            }
          }
        });
      } else {
        if (level1.key == key) {
          this.selectedLevel1 = level1;
        }
      }
    });
  }
  selectedLevel1: any;
  selectedLevel2: any;
  selectedLevel3: any;

  onLevel1Change() {
    this.selectedLevel2 = null;
    this.selectedLevel3 = null;
    if (this.selectedLevel1?.sub_options?.length == 0) {
      this.lot.nature = this.selectedLevel1.key;
    } else {
      let filtred = this.selectedLevel1?.sub_options?.filter(
        (value) =>
          value?.country_id?.includes('*') ||
          value?.country_id?.includes(this.owner.country_id)
      );

      if (filtred.length == 0) {
        this.lot.nature = this.selectedLevel1.key;
      }
    }
  }
  onLevel2Change() {
    this.selectedLevel3 = null;
    if (this.selectedLevel2.sub_options.length == 0)
      this.lot.nature = this.selectedLevel2.key;

    if (this.selectedLevel2?.sub_options?.length == 0) {
      this.lot.nature = this.selectedLevel2.key;
    } else {
      let filtred = this.selectedLevel2?.sub_options?.filter(
        (value) =>
          value?.country_id?.includes('*') ||
          value?.country_id?.includes(this.owner.country_id)
      );
      if (filtred.length == 0) {
        this.lot.nature = this.selectedLevel1.key;
      }
    }
  }

  onLevel3Change() {
    // Do something with the selectedLevel3 value
    const level2Options = this.selectedLevel1.sub_options;
    for (const option of level2Options) {
      if (option.sub_options.includes(this.selectedLevel3)) {
        this.selectedLevel2 = option;
        break;
      }
    }
    if (this.selectedLevel3.sub_options.length == 0)
      this.lot.nature = this.selectedLevel3.key;
  }

  displayList(level) {
    let filtred = this[level]?.sub_options?.filter(
      (value) =>
        value?.country_id?.includes('*') ||
        value?.country_id?.includes(this.owner.country_id)
    );
    return this[level]?.sub_options.length > 0 && filtred.length > 0;
  }

  list = [
    {
      key: 'community_access_right',
      country_id: [5, 9],
      has_info: false,
      sub_options: [],
      info_key: '',
      is_filled: false,
    },
    {
      key: 'tenant',
      country_id: ['*'],
      has_info: true,
      sub_options: [],
      info_key: 'infobull_tenant',
      is_filled: false,
    },
    {
      key: 'owner',
      country_id: ['*'],
      has_info: false,
      is_filled: false,
      sub_options: [
        {
          key: 'farming_on_the_plot',
          country_id: ['*'],
          has_info: false,
          sub_options: [],
        },
        {
          key: 'leasing_the_plot',
          country_id: ['*'],
          has_info: false,
          sub_options: [],
        },
        {
          key: 'share_cropping',
          country_id: ['*'],
          has_info: false,
          sub_options: [
            {
              key: 'share_cropping_owner_aboussan',
              country_id: [5],
              has_info: true,
              sub_options: [],
              info_key: 'infobull_share_cropping_owner_aboussan',
            },
            {
              key: 'share_cropping_owner_abusa',
              country_id: [9],
              has_info: true,
              sub_options: [],
              info_key: 'infobull_share_cropping_owner_abusa',
            },
            {
              key: 'share_cropping_owner_abougnon',
              country_id: [5],
              has_info: true,
              sub_options: [],
              info_key: 'infobull_share_cropping_owner_abougnon',
            },
            {
              key: 'share_cropping_owner_abunu',
              country_id: [9],
              has_info: true,
              sub_options: [],
              info_key: 'infobull_share_cropping_owner_abunu',
            },
          ],
        },
      ],
    },
    {
      key: 'sharecropper',
      country_id: ['*'],
      has_info: true,
      is_filled: false,
      info_key: 'infobull_sharecropper',
      sub_options: [
        {
          key: 'share_cropping_aboussan',
          country_id: [5],
          has_info: true,
          sub_options: [],
          info_key: 'infobull_share_cropping_abusa',
        },
        {
          key: 'share_cropping_abusa',
          country_id: [9],
          has_info: true,
          sub_options: [],
          info_key: 'infobull_share_cropping_aboussan',
        },
        {
          key: 'share_cropping_abougnon',
          country_id: [5],
          has_info: true,
          sub_options: [],
          info_key: 'infobull_share_cropping_abougnon',
        },
        {
          key: 'share_cropping_abunu',
          country_id: [9],
          has_info: true,
          sub_options: [],
          info_key: 'infobull_share_cropping_abunu',
        },
      ],
    },
    {
      key: 'other',
      is_filled: false,
      country_id: ['*'],
      has_info: false,
      sub_options: [],
    },
  ];

  async handelChangePlotOwnership(ev: any, list, level) {
    let FiltredList = list.filter(
      (value) =>
        value?.country_id?.includes('*') ||
        value?.country_id?.includes(this.owner.country_id)
    );
    FiltredList.forEach(
      (value) => (value['title'] = this.translateService.instant(value.key))
    );
    FiltredList = mapLangeTitleObjects(
      FiltredList,
      'title',
      this.translateService
    );

    this.lot.nature = null;
    const popover = await this.popoverController.create({
      component: PopoverInfoComponent,
      cssClass: 'pop-over-style-info',
      event: ev,
      translucent: true,
      componentProps: { data: FiltredList, component: 'create-account2' },
    });
    popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      // this.SubListPlotOwnershipValue = data?.data?.key;
      this[level] = data?.data;

      switch (level) {
        case 'selectedLevel1':
          this.onLevel1Change();
          break;
        case 'selectedLevel2':
          this.onLevel2Change();

          break;
        case 'selectedLevel3':
          this.onLevel3Change();
          break;
        default:
          break;
      }
    } else {
      this.lot.nature = '';
    }
  }

  async choosePhoto() {
    await this.photoService
      .addNewToGallery()
      .then(async (value: PhotoTaken) => {
        const blob = await fetch(value?.webviewPath).then((response) =>
          response.blob()
        );
        if (value.webviewPath) {
          await this.showModificationModal(
            this.sanitizer.bypassSecurityTrustResourceUrl(value?.webviewPath)
          );
        }
      });
    this.lot.hasSelectedPhoto = true;
  }

  async showModificationModal(image_path) {
    let componentProps = {
      image_path: image_path,
      modalController: this.modalController,
      cancel: await this.translateService.instant('cancel'),
      confirm: await this.translateService.instant('confirm'),
    };
    let modal = await this.toastUtils.modalBuilder(
      PhotoEditorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    if (data?.image && data?.img64String) {
      if (data.img64String.length * 2 > 10 * 2 ** 40) {
        this.toastUtils.toastBuilder(
          '',
          'bottom',
          await this.translateService.instant('errors.img_size'),
          'danger'
        );
      } else {
        let props = {
          hasSelectedPhoto: this.lot.hasSelectedPhoto,
        };
        this.image_path = data?.image;
      }
    }
  }

  async setFarmId() {
    if (this.farm?.id) {
      this.lot.farm_id = this.farm.id;
    } else {
      await this.toastUtils.toastBuilder(
        await this.translateService.instant('errors.no_farm'),
        'top',
        '',
        'danger'
      );
      this.lot.farm_id = 0;
    }
  }

  async submitMap(data) {
    if (data && data?.superficie) {
      if (this.lot?.id && data?.superficie != this.initialSuperficie) {
        this.reset();
      }
      this.lot.superficie = parseFloat(data?.superficie.toFixed(2));
      this.lot.lands = data?.points;
      this.lot.perimeters = data?.perimeters;
      this.plot_delimited = true;
    }
  }

  async addProduction(data) {
    this.totalPercent = 0;
    this.totalSurface = 0;
    if (Array.isArray(data)) {
      this.products = cloneDeep(data);
      this.reset();
    }
  }

  filterResult(products) {
    if (Array.isArray(this.products) && this.products.length > 1) {
      products.forEach((element) => {
        var query_result = this.products.find(
          (new_insertion) => new_insertion.id === element.id
        );
        if (!query_result) this.products.push(element);
      });
    } else this.products = cloneDeep(products);
  }

  async submit() {
    this.progressDialog.present();

    if (this.lot?.hasSelectedPhoto) {
      this.lot.blobFile = await fetch(this.image_path).then((result) =>
        result.blob()
      );
      this.lot.file = this.image_path;
    }
    this.lot.productions = this.products;
    if (USAGE_TYPE.other == this.used_for && !this.lot.comment) {
      this.lot.comment = 'Not mentioned';
    } else if (
      USAGE_TYPE.agricol == this.used_for &&
      this.lot.nature != 'other'
    ) {
      this.lot.comment = '';
    }

    if (USAGE_TYPE.other == this.used_for) {
      this.lot.planting_type = '';
      this.lot.nature == null;
    }

    if (
      (USAGE_TYPE.agricol == this.used_for && this.lot.nature != 'other') ||
      USAGE_TYPE.other == this.used_for
    ) {
      this.lot.nature_comment = '';
    }

    if (Array.isArray(this.lot.productions))
      this.lot.productions = this.remove0PercentProd(this.lot.productions);

    const dataCheck = this.checkData(this.lot);
    if (dataCheck !== 'ok') {
      this.progressDialog.dismiss();
      this.showToastError(dataCheck.toString());
      return;
    } else if (
      this.totalPercent != 100 &&
      USAGE_TYPE.agricol == this.used_for
    ) {
      this.progressDialog.dismiss();
      this.showToastError('errors.the_total_area_must_be_equal');
      return;
    }

    if (this.step > 4 && !this.productionEditorComponent.checkCanSubmit()) {
      this.progressDialog.dismiss();
      return;
    }

    if (!this.lot?.id) {
      if (this.step >= 4) {
        for (const crop of this.existCrop) {
          // Find the index of the crop in productions
          const cropIndex = this.lot.productions.findIndex((val) => {
            return val.id === crop.id;
          });

          if (cropIndex !== -1) {
            this.lot.productions.forEach((element) => {
              element['form_answer'] = this.forms[element.id]?.getRawValue();
              element['plant_genetics'] = this.genetics[element.id];
            });
          }
        }
      }

      this.store.dispatch(addPlot({ data: this.lot, props: {} }));

      this.progressDialog.dismiss();

      this.modalMain.dismiss({
        is_agricol: this.lot.type === this.USAGE_TYPE.agricol,
      });
      // if (this.hasSelectedPhoto) this.insertImage();

      // this.lotAPI.insertLot(this.lot).subscribe(
      //   async (value: any) => {
      //     if (this.hasSelectedPhoto) this.insertImage();
      //     let lot = value;
      //     await this.saveToLocalStorageAndRedirect(value);
      //     this.progressDialog.dismiss();

      //     if (this.step == 4) {
      //       let productionProps = value.productions.find((val) => {
      //         return val.morphable_id == this.existCrop.morphable_id;
      //       });
      //       this.productionEditorComponent.submit(
      //         productionProps,
      //         this.modalMain
      //       );
      //     }

      //       this.modalMain.dismiss({
      //         is_agricol: this.lot.type === this.USAGE_TYPE.agricol,
      //         lot: lot,
      //       });
      //   },
      //   (e) => {
      //     this.progressDialog.dismiss();
      //     this.modalMain.dismiss();
      //   }
      // );
    } else {
      const dataCheck =
        !this.lot.lot_number ||
        ((!this.lot.productions ||
          (Array.isArray(this.lot.productions) &&
            this.lot.productions.length == 0)) &&
          this.lot.type != USAGE_TYPE.other) ||
        !this.lot.lands ||
        !this.lot.superficie ||
        !this.lot.nature
          ? 'label.required_input_no_html'
          : 'ok';

      if (dataCheck !== 'ok') {
        this.progressDialog.dismiss();
        this.showToastError(dataCheck.toString());
        return;
      } else if (
        this.totalPercent != 100 &&
        USAGE_TYPE.agricol == this.used_for
      ) {
        this.progressDialog.dismiss();
        this.showToastError('errors.the_total_area_must_be_equal');
        return;
      }

      if (this.step >= 4) {
        for (const crop of this.existCrop) {
          // Find the index of the crop in productions

          const cropIndex = this.lot.productions.findIndex((val) => {
            return val.id === crop.id;
          });

          if (cropIndex !== -1) {
            // Use a copy of the productions array to ensure immutability

            this.lot.productions.forEach((element) => {
              element['form_answer'] = this.forms[element?.id]?.getRawValue();
            });
          }
        }
      }
      this.store.dispatch(updatePlot({ data: this.lot, props: {} }));
      this.progressDialog.dismiss();
      this.modalMain.dismiss();

      // TODO fix plot morph popover and img

      // this.lotAPI.insertLot(this.lot).subscribe(
      //   async (value: any) => {
      //     if (this.hasSelectedPhoto) this.insertImage();
      //     this.lot = value;
      //     await this.saveToLocalStorageAndRedirect(value);
      //     this.progressDialog.dismiss();
      //     let existCrop = this.lot.productions.find(
      //       (production) =>
      //         production.morphable_type == MORPH_Product &&
      //         production.morphable_id == 16
      //     );
      //     if (existCrop) {
      //       await this.showCropModal(existCrop);
      //     }

      //     if (this.isDashboardView && !this.just_end)
      //       await this.lotAdded(value);
      //     else
      //       this.modalMain.dismiss({
      //         is_agricol: this.lot.type === this.USAGE_TYPE.agricol,
      //         lot: this.lot,
      //       });
      //   },
      //   (e) => {
      //     this.progressDialog.dismiss();
      //     this.modalMain.dismiss();
      //     console.log(e);
      //   }
      // );
    }
  }
  checkData(lot): String {
    return !lot.lot_number ||
      ((!lot.productions ||
        (Array.isArray(lot.productions) && lot.productions.length == 0)) &&
        lot.type != USAGE_TYPE.other) ||
      !lot.lands ||
      !lot.superficie ||
      (USAGE_TYPE.agricol == this.used_for && !lot.nature)
      ? 'label.required_input_no_html'
      : 'ok';
  }
  remove0PercentProd(productions: Array<any>) {
    return productions
      .map((production) => {
        if (production.percent_in_lot > 0) return production;
      })
      .filter((e) => e !== undefined);
  }

  prepareForm;
  getQuestionForm;
  selectedProduction;
  async showCropModal(existCrop) {
    // this.isCropFormNeeded = true;
    this.prepareForm = await this.questionUtils.prepareForm(existCrop);
    this.getQuestionForm = await this.questionUtils.getQuestionForm();
    return existCrop;
    // this.isCropFormNeeded = existCrop ? true : false;
    // setTimeout(() => {
    //   this.slider.slideNext();
    // }, 500);
    // this.slider.slideNext();

    // this.productionEditorComponent.form = await this.questionUtils.prepareForm(existCrop);
    // this.productionEditorComponent.questions = await this.questionUtils.getQuestionForm();
    // this.productionEditorComponent.production = existCrop;
  }
  async showToastError(message: string) {
    await this.toastUtils.toastBuilder(
      await this.translateService.instant(message),
      'top',
      '',
      'danger',
      500
    );
  }
  async updateLocalStorage(porudction, lot) {
    lot.productions.forEach((element, index) => {
      if (element.id === porudction.id) {
        lot.productions[index] = porudction;
      }
    });
    const lots = JSON.parse((await Storage.get({ key: dev.LOT_KEY })).value);

    lots.forEach((element, index) => {
      if (element.id === lot.id) {
        lots[index] = lot;
      }
    });
    await Storage.set({ key: dev.LOT_KEY, value: JSON.stringify(lots) });
    // this.ngOnInit();
  }

  async insertImage() {
    const file = await fetch(this.image_path).then((result) => result.blob());
    this.lotAPI.storeLotPhoto(this.lot.id, file).subscribe(async (value) => {
      this.lot.file = value;
      return value;
    });
  }
  async saveToLocalStorageAndRedirect(value) {
    let result = await Storage.get({
      key: dev.LOT_KEY,
    }).then((value) => JSON.parse(value.value));

    if (Array.isArray(result)) {
      result.push(value);
    } else {
      result = [];
      result.push(value);
    }
    await Storage.set({ key: dev.LOT_KEY, value: JSON.stringify(result) });
  }

  async updateImage(id) {
    const file = await fetch(this.image_path).then((result) => result.blob());
    this.lotAPI.updateLotPhoto(id, file).subscribe(async (value) => {
      this.lot.file = value;
    });
  }

  async updateExistingLot() {
    this.store.dispatch(updatePlot({ data: this.lot, props: {} }));
    this.progressDialog.dismiss();
    this.modalMain.dismiss({ value: this.lot });
    // this.lotAPI
    // .updateLot(this.lot)
    // .pipe(
    //   take(1),
    //   map(async (value) => {
    //     if (this.hasSelectedPhoto) {
    //       if (this.lot?.file?.id) {
    //         this.updateImage(this.lot?.file?.id);
    //       } else {
    //         return this.insertImage();
    //       }
    //     }
    //     return value;
    //   })
    // )
    // .subscribe(async (value: any) => {
    //   let lot = await value.then((value) => value);
    //   let result = JSON.parse(
    //     (
    //       await Storage.get({
    //         key: dev.LOT_KEY,
    //       })
    //     ).value
    //   );

    //   // setTimeout(() => {
    //   lot.file = this.lot?.file;

    //   if (Array.isArray(result)) {
    //     result.forEach((element, index) => {
    //       if (element.id === lot.id) {
    //         result[index] = lot;
    //       }
    //     });
    //   } else {
    //     result = [];
    //     result.push(lot);
    //   }
    //   // }, 800);

    //   await Storage.set({ key: dev.LOT_KEY, value: JSON.stringify(result) });
    //   this.progressDialog.dismiss();
    //   this.modalMain.dismiss({ value: value });
    // });
  }

  cancel() {
    this.modalMain.dismiss();
  }

  changed() {
    this.totalPercent = 0;
    this.products.forEach((element) => {
      this.totalPercent += element.percent_in_lot;
    });
  }

  reset() {
    this.products.forEach((element) => {
      element.percent_in_lot = 0;
      element.used_surface = 0;
    });
  }

  deleteProduct(product) {
    this.products.forEach((element, index) => {
      if (element.name === product.name) {
        this.products.splice(index, 1);
      }
    });
    this.changed();
  }

  async showPopOver(message) {
    const popover = await this.popover.create({
      component: PopoverComponent,
      cssClass: ['popover-css-class'],

      animated: true,
      componentProps: { key1: message },
    });
    popover.present();
    await popover.onWillDismiss();
  }

  async lotAdded(event) {
    // const cashflow_need = await this.cashflowInsertion();
    // if (cashflow_need) {
    //   await this.showRelatedPopover(event.id);
    // }
    await this.modalController.dismiss(
      { lot: event, inserted_lot: event },
      null,
      '2'
    );
  }

  async cashflowInsertion(): Promise<Boolean> {
    const modal = await showConfirmModal(
      this.popover,
      await this.translateService.instant('add_cashflow_entry'),
      null,
      'Sí',
      'No'
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  async initBuyCashflow(categories, id): Promise<CashFlow> {
    const cashflow = new CashFlow();
    cashflow.morphable_id = id;
    cashflow.morphable_type = MORPH_Lot;

    cashflow.is_income = 0;
    cashflow.quantity = 1;
    // cashflow.unity = 'unit';
    cashflow.payment_methods = 'cash';
    let country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    cashflow.currency = country.currency;
    cashflow.farm_id = this.farm.id;
    cashflow.date = new Date().toISOString();
    cashflow.cash_flow_categorie_id =
      this.used_for === USAGE_TYPE.agricol
        ? BUY_AGRICOLAS_LOT
        : BUY_OTRAS_USE_LOT;
    cashflow.accounting_entries_categories =
      PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY;
    categories.forEach((element) => {
      if (Array.isArray(element.cash_flow_sub_category))
        element.cash_flow_sub_category.forEach((ele) => {
          if (ele.id === cashflow.cash_flow_categorie_id)
            cashflow.cashflow_categories = ele;
        });
    });
    return cashflow;
  }

  async showRelatedPopover(id) {
    const cashFlowCategFiltred = await Storage.get({
      key: dev.CASHFLOW_CATEGORIES,
    }).then((value) =>
      JSON.parse(value.value).find(
        (element) => element.id == PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
      )
    );

    let componentProps = {
      cashFlow: await this.initBuyCashflow(cashFlowCategFiltred, id),
      accountingEntryCategories: cashFlowCategFiltred,
      modalController: this.modalController,
      no_post_submit_action: true,
    };
    let modal = await this.toastUtils.modalBuilder(
      UnifiedCashflowInputComponentComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '2'
    );

    await modal.onWillDismiss();
  }

  showHideHelpDialog() {
    this.showHelpDialog = !this.showHelpDialog;
  }

  async handelPickerClicked(product) {
    let { data } = await this.customPickerOption.openPicker(
      product.percent_in_lot
    );
    let percentage = data[0].value;
    this.setSurface(percentage, product);
  }
}
