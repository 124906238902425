import { QuestionControlService } from 'src/app/utils/dynamic-forms/QuestionControlService';
import {
  DateBoxQuestion,
  DropdownQuestion,
  NumberBoxQuestion,
  NumberPrecisionWidget,
  QuestionBase,
  SliderBoxQuestion,
  TextboxQuestion,
} from './QuestionBase';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Injectable } from '@angular/core';
import cropConfig from '../../config/dummmy.json';
@Injectable({ providedIn: 'root' })
export class QuestionsUtils {
  questions: QuestionBase<string>[] | null = [];

  constructor(
    private routingConfig: RoutingConfig,
    private qcs: QuestionControlService
  ) {
    this.questions = [];
  }

  resetForm() {
    this.questions = new Array();
  }
  async prepareForm(production, getQuestion = false): Promise<any> {
    let oldAsnwer = null;
    var form = null;
    this.questions = [];
    if (production.form_answer && typeof production.form_answer == 'string') {
      if (typeof production.form_answer === 'string')
        oldAsnwer = JSON.parse(production.form_answer);
      if (typeof oldAsnwer === 'string') oldAsnwer = JSON.parse(oldAsnwer);
    } else if (
      production.form_answer &&
      typeof production.form_answer != 'string'
    ) {
      oldAsnwer = production.form_answer;
    }
    // form = cropConfig.crops_form[0].fields;
    let Crops = await this.routingConfig.getCropFormConfig();
    var cropConfiig;
    Crops.forEach((element) => {
      element.products.forEach((val) => {
        if (
          val.crop_id == production.morphable_id &&
          production.morphable_type.toLowerCase().includes(val.crop_entity)
        ) {
          cropConfiig = val;
          return;
        }
      });
    });
    if (
      cropConfiig &&
      cropConfiig?.crops_form.length > 0 &&
      cropConfiig?.crops_form[0].fields
    ) {
      form = cropConfiig.crops_form[0].fields;
    } else return null;

    form.forEach((field) => {
      if (oldAsnwer) field.value = oldAsnwer[field.key];
      else field.value = null;
      if (field.type === 'DropdownQuestion')
        this.questions.push(new DropdownQuestion(field));
      else if (field.type === 'number')
        this.questions.push(new NumberBoxQuestion(field));
      else if (field.type === 'date')
        this.questions.push(new DateBoxQuestion(field));
      else if (field.type === 'slider')
        this.questions.push(new SliderBoxQuestion(field));
      else if (field.type === 'number_prcision')
        this.questions.push(new NumberPrecisionWidget(field));
      else if (field.type === 'text')
        this.questions.push(new TextboxQuestion(field));
    });
    if (getQuestion) {
      let q = this.questions;

      this.questions = [];
      return q;
    }
    // form = this.qcs.toFormGroup(this.questions as QuestionBase<string>[]);
    return this.qcs.toFormGroup(this.questions as QuestionBase<string>[]);
  }

  async getQuestionForm() {
    if (this.questions) return this.questions;
    else return {};
  }
}
