import { accountingEntriesLoading } from './../../utils/states-management/actions/accountings-entries.action';
import { AccountingEntryCategory } from './../../models/cash-flow/cashflow_categories';
import {
  getOwnerById,
  updateOwnerData,
} from './../../utils/states-management/actions/owner.action';
import { TermsModalPage } from './../../components/terms-modal/terms-modal.page';
import { CashflowSignleEntrySummaryComponent } from './cashflow/cashflow-signle-entry-summary/cashflow-signle-entry-summary.component';
import { LangPopoverComponent } from '../../widgets/lang-popover/lang-popover.component';
import { TeamInterfaceLiteComponent } from './../team/team-interface-lite/team-interface-lite.component';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { CommunityPermissionComponentComponent } from '../community/community-permission-component/community-permission-component.component';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Pipe,
  ViewChild,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import {
  IonBackButton,
  IonNav,
  MenuController,
  ViewWillLeave,
} from '@ionic/angular';
import { PersonalDataComponent } from '../../components/personal-data/personal-data.component';
import { ProfilePhotoComponent } from '../../components/personal-data/profile-photo/profile-photo.component';
import { environment } from '../../../environments/environment';
import { UpdateAccessWidgetComponent } from 'src/app/widgets/update-access-widget/update-access-widget.component';
import { DeleteAccountComponent } from 'src/app/components/delete-account/delete-account.component';
import { PopoverController } from '@ionic/angular';
import { FamilyViewTwoComponent } from '../family/family-view-two/family-view-two.component';
import { TeamFamilyComponent } from '../team/team-family/team-family.component';
import { TeamPermanenteComponent } from '../team/team-permanente/team-permanente.component';
import { TeamTemporelComponent } from '../team/team-temprel/team-temporel.component';
import { MaterialGoodsComponent } from '../goods/material-goods/material-goods.component';
import { BankAccountsComponent } from '../goods/bank-accounts/bank-accounts.component';
import { MoneyLoanComponent } from '../goods/money-loan/money-loan.component';
import { GarenteeComponent } from '../goods/garentee/garentee.component';
import { DebtFromComponent } from '../debts/debt-from/debt-from.component';
import { LicenseAgreementComponent } from 'src/app/widgets/license-agreement/license-agreement.component';
import { PriorDashboardComponent } from '../prior-dashboard/prior-dashboard.component';
import { Router } from '@angular/router';
import { FamilyAccessComponent } from 'src/app/components/family-access/family-access.component';
import { SummaryComponent } from 'src/app/modules/dashboard/summary/summary.component';
import { ProgramsComponent } from '../programs/programs.component';
import { BookComponent } from './cashflow/book/book.component';
import { AmbassadorCofidentialityComponent } from '../ambassador-cofidentiality/ambassador-cofidentiality.component';
import { ConfirmModalComponent } from 'src/app/widgets/confirm-modal/confirm-modal.component';
import { getCurrentMonthAndYear } from 'src/app/utils/pipes-utils/date_formatter';
import { USAGE_TYPE } from 'src/app/models/lots.model';
import { PensionPlanTrackerComponent } from './widgets/pension-plan-tracker/pension-plan-tracker.component';
import { ProfileInfoComponent } from 'src/app/components/personal-data/profile-info/profile-info.component';
import { Observable, Subscription, of, take } from 'rxjs';
import { AnimalesComponent } from '../farm/animals/animals/animals.component';
import { CoffeProductionViewComponent } from './coffee-production-view/coffee-production-view.component';
import { UserInfo } from 'src/app/apis/users-info.service';
import { AnimationUtils } from 'src/app/utils/animations';
import { TranslateService } from '@ngx-translate/core';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { FarmAddressDetailsComponent } from '../farm/farm-details/farm-address-details/farm-address-details.component';
import { PreProductSelectorComponent } from '../farm/others-agricol-product-components/pre-product-selector/pre-product-selector.component';
import { LotsOverviewComponent } from '../farm/lots-components/lots-overview/lots-overview.component';
import { LotManagerComponent } from '../farm/lots-components/lot-manager/lot-manager.component';
import Owner from 'src/app/models/owner.model';
import { AnalysisCashflowComponent } from './cashflow/analysis-cashflow/analysis-cashflow.component';
import { CoffeeSalesComponent } from '../coffee-sales/coffee-sales.component';
import { PartnerMainViewComponent } from '../partner/partner-main-view/partner-main-view.component';
import { CashflowSummaryDiaryComponent } from './cashflow/cashflow-summary-diary/cashflow-summary-diary.component';
import { CoffeeKpisComponent } from './kpis/coffee-kpis/coffee-kpis.component';
import { AnalysisHarvestComponent } from './cashflow/analysis-harvest/analysis-harvest.component';
import { AnalysisInputsComponent } from './cashflow/analysis-inputs/analysis-inputs.component';
import { AnalysisWorkforceComponent } from './cashflow/analysis-workforce/analysis-workforce.component';
import { Store } from '@ngrx/store';
import {
  LOGOUT,
  setCheckPointRoute,
} from 'src/app/utils/states-management/actions/owner.action';
import { CalculetteComponentComponent } from './calculette-component/calculette-component.component';
import { loadUnit } from 'src/app/utils/states-management/actions/units-action';
import { ProgrammesListComponent } from '../programmes-Info/programmes-list/programmes-list.component';
import { loadCountries } from 'src/app/utils/states-management/actions/countries.action';
import { getDashboardData } from 'src/app/utils/states-management/actions/dashboard.action';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
import { cloneDeep } from 'lodash';
import { QuestionAnswersComponent } from './question-answers/question-answers.component';
import { selectAllUnits } from 'src/app/utils/states-management/reducers/units.reducer';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  implements OnInit, AfterViewInit, OnDestroy, ViewWillLeave
{
  base_url = environment.appRoot;
  image = '/assets/icon/app-icon/icn-profile-pic.svg';
  user_name: String = '';
  owner: Owner;
  @ViewChild('nav') nav: IonNav;
  @ViewChild('backButton') backButton: IonBackButton;
  menu: any[];
  rootParams;
  title = 'Fynka';
  lang: any;
  showFooter = false;
  cashflowCategories$: Observable<any>;
  select_langage;
  isPriorDashboardComponent;
  list = [
    { value: 'Español', key: 'es' },
    { value: 'English', key: 'en' },
  ];
  monthYear = getCurrentMonthAndYear(new Date());
  formConfig;
  activeTab = 1;
  isMenuOpened: boolean = false;
  profile_info;
  prevView;
  subscriptions: Array<Subscription> = [];
  navigationChain = [];
  isBackClicked = false;
  country_id;
  currentScreen;
  headerTitle;
  constructor(
    private store: Store<{
      owner: Owner;
      accountingEntries: Array<AccountingEntryCategory>;
    }>,
    private menuController: MenuController,
    private animationUtils: AnimationUtils,
    private router: Router,
    private popover: PopoverController,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private cashflowAPI: CashflowAPI,
    private goodAPI: GoodsAPI,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig
  ) {
    this.store.dispatch(getOwnerById());
  }

  async ionViewWillLeave() {
    try {
      this.subscriptions.forEach((element) => element.unsubscribe());
      this.rootParams = {
        nav: this.nav,
        rootStack: PriorDashboardComponent,
        isDashboardView: false,
      };
      this.nav.setRoot(PriorDashboardComponent, {
        nav: this.nav,
        rootStack: SummaryComponent,
      });
    } catch (error) {}
  }

  @HostListener('unloaded')
  async ngOnDestroy() {
    this.ownerSubscription.unsubscribe();
    this.subscriptions.forEach((element) => element.unsubscribe());
  }

  HandelHomeClicked() {
    this.nav.setRoot(PriorDashboardComponent, {
      nav: this.nav,
      rootStack: SummaryComponent,
    });
  }

  headerOption(activeComponent) {
    if (activeComponent == BookComponent || activeComponent == SummaryComponent)
      this.headerTitle = 'cashflow';
    else if (activeComponent == ProgrammesListComponent)
      this.headerTitle = 'btn2';
    else if (activeComponent == CalculetteComponentComponent) {
      this.headerTitle = 'btn5';
      this.showFooter = true;
    } else this.headerTitle = '';
  }
  ngAfterViewInit(): void {
    // this.rootParams = {
    //   nav: this.nav,
    //   rootStack: PriorDashboardComponent,
    //   isDashboardView: false,
    // };
    // this.nav.setRoot(PriorDashboardComponent, {
    //   nav: this.nav,
    //   rootStack: SummaryComponent,
    // });
    // this.nav.ionNavDidChange.subscribe((nav) => {
      this.nav.getActive().then((active) => {
        this.currentScreen = active.component;
        this.selectTab(active);
        this.headerOption(active.component);
        var exist = this.navigationChain.find((nav) => {
          return nav.name == active.component.name;
        });
        if (!exist || (exist && active.component != PriorDashboardComponent)) {
          if (!this.isBackClicked)
            this.navigationChain.push({
              component: active.component,
              params: active.params,
            });
          else {
            this.isBackClicked = false;
          }
        }
      });
    // });
  }

  async atrasClicked() {
    this.isBackClicked = true;
    const isPriorDashboardComponent = await this.nav
      .getActive()
      .then((value) => {
        return value.component;
      });
    if (isPriorDashboardComponent == PriorDashboardComponent) {
      this.logOut();
    } else {
      this.navigationChain.pop();

      let comp = this.navigationChain[this.navigationChain.length - 1];
      comp.params.nav = this.nav;
      comp.params.rootStack = PriorDashboardComponent;
      this.nav.setRoot(comp.component, comp.params);
    }
  }

  selectTab(active) {
    if (
      [
        CashflowSummaryDiaryComponent,
        CoffeeKpisComponent,
        AnalysisHarvestComponent,
        AnalysisInputsComponent,
        AnalysisWorkforceComponent,
        CashflowSignleEntrySummaryComponent,
      ].includes(active.component)
    ) {
      this.activeTab = 3;
    } else if (active.component == BookComponent) {
      this.activeTab = 2;
    } else if (active.component == SummaryComponent) this.activeTab = 1;
  }

  handelMenuClicked() {
    if (Array.isArray(this.menu)) {
      this.menu.forEach((element) => (element.is_expanded = false));
    }
  }
  ownerSubscription;
  async ngOnInit() {
    this.formConfig = await this.routingConfig.getProfileFormConfig();
    this.store.select(selectAllUnits).pipe(take(1)).subscribe(async (value) => {
      if (!value.length) {
        this.store.dispatch(loadUnit());
      }
    });
    this.ownerSubscription = this.store
      .select('owner')
      .subscribe((value: any) => {
        this.owner = cloneDeep(value);
        if (value.first_name && value.last_name) {
          this.user_name = value.first_name + '  ' + value.last_name;
          this.country_id = value.country_id;
        }
        if (value.profile_info)
          this.profile_info = JSON.parse(value.profile_info);

        if (value.file && value.file != null && value.file?.path) {
          this.image = environment.appRoot + value.file?.path;
        } else {
          this.image = '/assets/icon/app-icon/icn-profile-pic.svg';
        }
        if (
          value &&
          value?.checkpoint_path &&
          value?.checkpoint_path != '/dashboard'
        ) {
          this.store.dispatch(setCheckPointRoute({ url: '/dashboard' }));
        }
        if (value && value.country_id)
          this.store.dispatch(
            accountingEntriesLoading({ country_id: value.country_id })
          );
      });

    this.translate();
  }

  loadStore() {
    // this.store.dispatch(getOwnerById());
    // this.store.dispatch(loadFarm());
    // this.store.dispatch(loadUnit());

    // this.store.dispatch(loadFamilyMember());
    // this.store.dispatch(loadPartnerType());
    // this.store.dispatch(loadPartner());
    // this.store.dispatch(loadTotalAccountingCategories({ data: data }));
    // this.store.dispatch(getDashboardData({ data: data }));
    // this.store.dispatch(loadWorkForceStats({data:data}))
    // this.store.dispatch(loadInsumosBought({ data :data }))
    // this.store.dispatch(loadHarvestSummary({ data :data }));
    // this.store.dispatch(paginateSales(this.saleFilter));
  }

  async translate() {
    this.lang = this.translateService.currentLang;

    if (Array.isArray(this.menu))
      this.menu.forEach((element) => (element.is_expanded = true));
  }

  async itemClicked(item) {
    if (item.sub_options[0].label != item.label) {
      item.is_expanded = !item.is_expanded;
      if (!item.is_expanded) {
        await this.animationUtils.expandAnimation(item);
      } else {
        await this.animationUtils.collapsAnimation(item);
      }
    } else {
      this.subItemClicked(item, item);
    }
  }

  async subItemClicked(item, sub_item) {
    this.menuController.toggle();
    switch (true) {
      case sub_item.id == 'sub-1':
        this.nav.setRoot(ProfilePhotoComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-2':
        this.nav.setRoot(ProfileInfoComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-3':
        this.nav.setRoot(UpdateAccessWidgetComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-4':
        this.nav.setRoot(PersonalDataComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-5':
        this.nav.setRoot(DeleteAccountComponent, this.rootParams);
        break;
      case item.id == 'm-3':
        this.nav.setRoot(FamilyViewTwoComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-7':
        this.nav.setRoot(FarmAddressDetailsComponent, this.rootParams);
        break;

      case sub_item.id == 'sub-8':
        this.nav.setRoot(CoffeProductionViewComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-9':
        this.nav.setRoot(PreProductSelectorComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-10':
        this.nav.setRoot(AnimalesComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-11':
        this.nav.setRoot(LotsOverviewComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-12':
        this.nav.setRoot(LotManagerComponent, {
          ...this.rootParams,
          used_for: USAGE_TYPE.agricol,
          title: await await this.translateService
            .get('titles.coffe_prod')
            .toPromise()
            .then((value) => value),
          isDashboardView: false,
        });
        break;
      case sub_item.id == 'sub-13':
        this.nav.setRoot(LotManagerComponent, {
          ...this.rootParams,

          used_for: 'other_use',
          title: await await this.translateService
            .get('titles.other_use')
            .toPromise()
            .then((value) => value),
          isDashboardView: false,
        });
        break;
      case sub_item.id == 'sub-14':
        this.nav.setRoot(TeamFamilyComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-15':
        this.nav.setRoot(TeamPermanenteComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-16':
        this.nav.setRoot(TeamTemporelComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-15-lite':
        this.nav.setRoot(TeamInterfaceLiteComponent, this.rootParams);
        break;
      case item.id == 'm-7':
        this.nav.setRoot(MaterialGoodsComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-18':
        this.nav.setRoot(BankAccountsComponent, {
          ...this.rootParams,
          claimed_tab: 0,
        });
        break;
      case sub_item.id == 'sub-19':
        this.nav.setRoot(GarenteeComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-20':
        this.nav.setRoot(MoneyLoanComponent, this.rootParams);
        break;

      case sub_item.id == 'sub-21':
        this.nav.setRoot(DebtFromComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-22':
        this.nav.setRoot(PensionPlanTrackerComponent, this.rootParams);
        break;

      case item.id == 'm-10':
        this.nav.setRoot(ProgramsComponent, this.rootParams);
        break;
      case sub_item.id == 'sub-26':
        this.nav.setRoot(
          CommunityPermissionComponentComponent,
          this.rootParams
        );
        break;
      case sub_item.id == 'sub-27':
        this.nav.setRoot(LicenseAgreementComponent, {
          ...this.rootParams,
          isLicense: true,
          submit: this.lang === 'es' ? 'Confirmar' : 'Submit',
        });
        break;
      case sub_item.id == 'sub-28':
        this.nav.setRoot(LicenseAgreementComponent, {
          ...this.rootParams,
          isLicense: false,
          submit: this.lang === 'es' ? 'Confirmar' : 'Submit',
        });
        break;
      case sub_item.id == 'sub-24':
        this.nav.setRoot(FamilyAccessComponent, this.rootParams);

        break;

      case item.id == 'm-13':
        this.logOut();
        break;

      case sub_item.id == 'sub-25':
        this.nav.setRoot(AmbassadorCofidentialityComponent, this.rootParams);
        break;
      case item.id == 'm-14':
        this.ChangeLang();
        break;
      case item.id == 'm-16':
        this.nav.setRoot(PartnerMainViewComponent, this.rootParams);
        break;

      case item.id == 'm-17':
        this.nav.setRoot(CoffeeSalesComponent, {
          nav: this.nav,
          rootStack: PriorDashboardComponent,
          isDashboardView: false,
          product_id: [16, 17, 18],
          mainUnit: 'Kilo(s)',
        });
        break;
      case item.id == 'm-18':
        this.nav.setRoot(CalculetteComponentComponent, {
          nav: this.nav,
          rootStack: PriorDashboardComponent,
        });
        break;
    }
  }
  async handelLangage() {
    await this.userInfo.setSelectedLangage(this.select_langage);
    // window.location.reload();
  }

  async ChangeLang() {
    const popover = await this.toastUtils.popOverBuilder(
      { coutry: this.owner?.countrie },
      LangPopoverComponent,
      'pop-over-style-languages-selection'
    );
    const { data } = await popover.onDidDismiss();
    if (data?.langKey) {
      this.owner.preferred_language = data?.langKey;
      this.store.dispatch(updateOwnerData({ owner: this.owner }));
    }
  }

  async ionNavDidChange() {
    this.isPriorDashboardComponent = await this.nav
      .getActive()
      .then((value) => {
        return value.component === PriorDashboardComponent;
      });

    this.showFooter = this.isPriorDashboardComponent;
  }

  async logOut() {
    const confirm = await this.askConfirmation();
    if (confirm) {
      // this.ownerSubscription.unsubscribe();
      this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
    }
  }

  async openModal(id, title) {
    let componentProps = {
      paramID: id,
      paramTitle: await this.translateService
        .get(title)
        .toPromise()
        .then((value) => value),
    };
    let modal = await this.toastUtils.modalBuilder(
      TermsModalPage,
      componentProps,
      ['my-custom-modal-css ', 'modal-wrapper']
    );

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
      }
    });
  }
  redirectToQA() {
    this.menuController.toggle();
    this.nav.setRoot(QuestionAnswersComponent, this.rootParams);
  }
  async askConfirmation() {
    const modal = await this.popover.create({
      component: ConfirmModalComponent,
      componentProps: {
        title: await this.translateService.get('log_out_title').toPromise(),
        modalController: this.popover,
        confirmation_text: await this.translateService
          .get('log_out')
          .toPromise(),
        cancel_text: await this.translateService.get('cancel').toPromise(),
        submit_text: await this.translateService.get('confirm').toPromise(),
      },
      cssClass: ['pop-over-style-confirm-logout'],
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  clickedTabBarClick(item) {
    this.activeTab = item;
    switch (item) {
      case 1:
        this.nav.setRoot(SummaryComponent, {
          nav: this.nav,
          rootStack: PriorDashboardComponent,
        });
        break;
      case 2:
        this.nav.setRoot(BookComponent, {
          nav: this.nav,
          rootStack: SummaryComponent,
        });
        break;
      case 3:
        this.nav.setRoot(AnalysisCashflowComponent, this.rootParams);
        break;
    }
    this.nav.getActive().then((active) => {});
  }
}

@Pipe({ name: 'filterMenu', pure: false })
export class FilterArrayPerfil {
  transform(arr, formConfig) {
    if (formConfig)
      arr.forEach((value, i: any) => {
        arr[i].sub_options = value.sub_options.filter((val) => {
          return (
            formConfig.items_ids.includes(val.id) &&
            // value.id != 'm-11' &&
            value.id != 'm-12'
          );
        });
      });

    return arr;
  }
}
