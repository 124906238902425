import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { Store } from '@ngrx/store';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { AccountingCategoriesAPI } from '../../../apis/accounting_categories.service';
import { ModalCashflowEntryListComponent } from '../../dashboard/modal-cashflow-entry-list/modal-cashflow-entry-list.component';
import { PopoverController } from '@ionic/angular';
import { getCurrentMonthAndYear } from 'src/app/utils/pipes-utils/date_formatter';
import {
  ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY,
  AHOROS_ACCOUNTING_ENTRY_CATEGORY,
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { TranslateService } from '@ngx-translate/core';
import Owner from 'src/app/models/owner.model';
import { cloneDeep } from 'lodash';
import { getDashboardData, loadTotalAccountingCategories } from 'src/app/utils/states-management/actions/dashboard.action';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { Subscription, take } from 'rxjs';
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  categories: any = [];
  @Input() nav;
  MAX_PERCENT = 9999999; // DISUCSS with ines
  summaryCoffe;
  summaryPropiedas;
  summaryOtros;

  summaryAdministration;
  summaryAnimal;
  summaryPersonal;
  summaryAhoros;
  summaryHogar;
  allSummaries = new Array();
  operations;
  farmSummaries;
  subscribtions: Array<Subscription> = [];
  money_reportings;
  monthYear = getCurrentMonthAndYear(new Date());
  routingConfig;
  accountings_entries = new Array();
  isAddBtnClicked = false;
  summaryStructure: SummaryStructure = {
    id: 0,
    expenses: 0.0,
    income: 0.0,
    net: 0.0,
    entries: [],
    perc: 0,
  };
  profile_info;
  from;
  to;
  @ViewChild('cashflow-summary') summary!: ElementRef;
is_scrolling_summary=false;
  constructor(
    private popover: PopoverController,
    private accountingCategoriesAPI: AccountingCategoriesAPI,
    public translateService: TranslateService,
    public RoutingConfig: RoutingConfig,
    private store: Store<{
      owner: Owner;
      TotalAccountings: Array<AccountingEntryCategorie>;
    }>,
    private elementRef: ElementRef
  ) {}
  indicatorOpacity
  async ngOnInit() {
    this.accountings_entries = JSON.parse(
      (await Storage.get({ key: dev.CASHFLOW_CATEGORIES })).value
    );
    this.translateService.onLangChange.subscribe((val) => this.orderList());
    
    this.routingConfig = await this.RoutingConfig.getDashboardConfig();
    this.store.select('owner').pipe(take(1)).subscribe((owner: Owner) => {
      if (owner.profile_info) {
        this.profile_info = JSON.parse(owner?.profile_info);
        this.fetchResumee();

      }
    });
 
    document.getElementById('cashflow-summary').addEventListener('scroll', (e)=>{
        if(e.target['scrollLeft']<70){
        this.indicatorOpacity=1-(e.target['scrollLeft']/70)

          this.is_scrolling_summary = false
        }else{
          this.is_scrolling_summary = true
        }
    }, true);  
  
  this.routingConfig = await this.RoutingConfig.getDashboardConfig();
  }

  async fetchResumee() {
    const startDate = new Date(
      this.monthYear.year,
      this.monthYear.month - 1,
      1
    ).toISOString();
    const endDate = new Date(
      this.monthYear.year,
      this.monthYear.month,
      0 + 1
    ).toISOString();
    this.from = startDate;
    this.to = endDate;

    let data = { startDate: this.from, endDate: this.to };
    this.store.dispatch(
      loadTotalAccountingCategories({ data :data })
    );
    this.store.dispatch(getDashboardData({ data :data }));

    this.store
      .select(getTotalsAccountingCategoriesResult)
      .subscribe({
        next: async ({data}: any) => {
          this.allSummaries =[];
          var totalsAccountingCategories = [];
          let values = cloneDeep(data);
          values.forEach((element) => {
            if (element?.morphable_type != null && element?.id != null) {
              let cropExist = this.profile_info.cropConfig.find(
                (item) =>
                  item.id == element.morphable_id &&
                  element.morphable_type.toLowerCase().includes(item.entity)
              );
              if (cropExist) {
                totalsAccountingCategories.push(element);
              }
            } else {
              totalsAccountingCategories.push(element);
            }

            if (element.id === CAFE_ACCOUNTING_ENTRY_CATEGORY)
              this.summaryCoffe = element;
            else if (element.id === PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY)
              this.summaryPropiedas = element;
            else if (element.id === OTROS_ACCOUNTING_ENTRY_CATEGORY)
              this.summaryOtros = element;
            else if (element.id === ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY)
              this.summaryAdministration = element;
            else if (element.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY)
              this.summaryAnimal = element;
            else if (element.id == PERSONAL_ACCOUNTING_ENTRY_CATEGORY)
              this.summaryPersonal = element;
            else if (element.id == AHOROS_ACCOUNTING_ENTRY_CATEGORY)
              this.summaryAhoros = element;
            else if (element.id == HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID) this.summaryHogar = element;
          });

          this.allSummaries = totalsAccountingCategories;
          this.orderList();
          this.farmSummaries = this.allSummaries.filter(
            (element) => element.is_farm_expenses == 1
          );
          // values.forEach((element) => {});
          await Storage.set({
            key: dev.SUMMARIES_KEY,
            value: JSON.stringify(values),
          });
        },
      });
    // this.accountingCategoriesAPI
    // .getTotalsAccountingCategories(startDate, endDate)
    // .subscribe({
    // next:  async (values: any) => {
    //     values.forEach((element) => {
    //       if (element?.morphable_type != null && element?.id != null) {
    //         let cropExist = this.profile_info.cropConfig.find(
    //           (item) =>
    //             item.id == element.morphable_id &&
    //             element.morphable_type.includes(item.morph)
    //         );
    //         if (cropExist) {
    //           totalsAccountingCategories.push(element);
    //         }
    //       } else {
    //         totalsAccountingCategories.push(element);
    //       }
    //       if (element.id === CAFE_ACCOUNTING_ENTRY_CATEGORY)
    //         this.summaryCoffe = element;
    //       else if (element.id === PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY)
    //         this.summaryPropiedas = element;
    //       else if (element.id === OTROS_ACCOUNTING_ENTRY_CATEGORY)
    //         this.summaryOtros = element;
    //       else if (element.id === ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY)
    //         this.summaryAdministration = element;
    //       else if (element.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY)
    //         this.summaryAnimal = element;
    //       else if (element.id == PERSONAL_ACCOUNTING_ENTRY_CATEGORY)
    //         this.summaryPersonal = element;
    //       else if (element.id == AHOROS_ACCOUNTING_ENTRY_CATEGORY)
    //         this.summaryAhoros = element;
    //       else this.summaryHogar = element;
    //     });

    //     this.allSummaries = totalsAccountingCategories;
    //     this.orderList();
    //     this.farmSummaries = this.allSummaries.filter(
    //       (element) => element.is_farm_expenses == 1
    //     );
    //     // values.forEach((element) => {});
    //     await Storage.set({
    //       key: dev.SUMMARIES_KEY,
    //       value: JSON.stringify(values),
    //     });
    //   }});
  }


  orderList() {
    this.allSummaries.sort((a, b) => {
      return this.translateService
        .instant('accounting_entries.' + a.id)
        .normalize('NFD')
        .toLowerCase() ===
        this.translateService
          .instant('accounting_entries.' + b.id)
          .normalize('NFD')
          .toLowerCase()
        ? 0
        : this.translateService
          .instant('accounting_entries.' + a.id)
          .normalize('NFD')
          .toLowerCase() <
          this.translateService
            .instant('accounting_entries.' + b.id)
            .normalize('NFD')
            .toLowerCase()
          ? -1
          : 1;
    });
  }

  async presentCashFlowInputModal() {
    this.isAddBtnClicked = true;

    let modal = await this.popover.create({
      component: ModalCashflowEntryListComponent,
      componentProps: {
        modalController: this.popover,
        nav: this.nav,
      },
      cssClass: ['popover_class', 'diario'],
    });
    modal.present();
    await modal.onWillDismiss();
    this.isAddBtnClicked = false;
    this.fetchResumee();
  }

  nextPress() {
    this.monthYear.month += 1;
    if (this.monthYear.month > 11) {
      this.monthYear.month = 0;
      this.monthYear.year += 1;
    }

    this.fetchResumee();
  }

  backPress() {
    this.monthYear.month -= 1;
    if (this.monthYear.month < 1) {
      this.monthYear.month = 12;
      this.monthYear.year -= 1;
    }
    this.fetchResumee();
  }

  selectedFromCalendar(event) {
    this.monthYear = getCurrentMonthAndYear(new Date(event));
    this.fetchResumee();
  }

  showCashflowCard(accountingEntry) {
    if (accountingEntry?.morphable_type && accountingEntry?.id) {
      let cropExist = this.profile_info.cropConfig.find(
        (item) =>
          item.id == accountingEntry.morphable_id &&
          accountingEntry.morphable_type.toLowerCase().includes(item.entity)
      );
      if (cropExist) return true;
      else return false;
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach((element: Subscription) =>
      element.unsubscribe()
    );
  }
}

interface SummaryStructure {
  id;
  expenses;
  income;
  net;
  entries: [];
  perc;
}
