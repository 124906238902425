import { TranslateService } from '@ngx-translate/core';
import { pushOtrosToEnd } from './../../../utils/array-filtrer';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import { ObjectiveApi } from 'src/app/apis/objectives.service';
import { Objective } from 'src/app/models/objective.model';
import { dev } from '../../../config/offlines_keys.json';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-objectives-forms',
  templateUrl: './objectives-forms.component.html',
  styleUrls: ['./objectives-forms.component.scss'],
})
export class ObjectivesFormsComponent implements OnInit, AfterViewInit {
  @Input() objective: Objective;
  @Input() modalController: ModalController;
  @Input() objectiveApi: ObjectiveApi;
  @Input() selected_category;
  currency;
  categories;
  category;
  editable = true;
  constructor(private store:Store,private translateService: TranslateService) {}

  async ngOnInit() {
    this.objectiveApi.getObjectiveCategories().subscribe((val) => {
      this.categories = pushOtrosToEnd(
        val,
        this.translateService.instant('other_key')
      );

      //TODO : to check
        if (this.objective.id) {
          this.category = this.categories.find(
            (e) => e.id === this.objective.cash_flow_categories_id
          );
          this.objective.cash_flow_categories_id = this.category.id;
          this.editable = false;
        }
        this.categories.splice(0, 3);
      },
      (e) => console.log(e)
    );
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.currency = country.currency;
  }

  ngAfterViewInit(): void {}

  dismiss() {
    this.modalController.dismiss({
      new_object: null,
    });
  }

  confirm_method() {
    if (this.objective.id) {
      this.objectiveApi.updateObjective(this.objective).subscribe(
        (value: any) => {
          this.modalController.dismiss({
            new_objective: this.objective,
          });
        },
        (error) => {

          this.modalController.dismiss({
            new_objective: null,
          });
        }
      );
    } else {
      this.objectiveApi.insertObjective(this.objective).subscribe(
        (value: any) => {
          this.objective.id = value.id;

          this.modalController.dismiss({
            new_objective: this.objective,
          });
        },
        (error) => {

          this.modalController.dismiss({
            new_objective: null,
          });
        }
      );
    }
  }

  objectifSet(value) {
    this.objective.cash_flow_categories_id = value.id;
    this.category = value;
  }

  onChangeAmount(event) {
    this.objective.required_amount = event;
  }
}
