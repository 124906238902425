import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { platform } from 'os';
@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  public photoTaken: PhotoTaken = {
    webviewPath: '../../assets/empty_avatar.png',
    filepath: '',
    url: '',
  };
  constructor(
    public translateService: TranslateService,
    private platform: Platform
  ) {}

  async savePicture() {
    const base64Data = await this.readAsBase64();

    const fileName = new Date().getTime() + '.jpeg';
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });

    return {
      filepath: fileName,
      webviewPath: this.photoTaken.webviewPath,
      url: this.photoTaken.url,
    };
  }
  async savePictureLocally(): Promise<string> {
    const base64Data = await this.readAsBase64();
    const fileName = new Date().getTime() + '.jpeg';

    try {
      const savedFile = await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Data,
      });

      return fileName;
    } catch (error) {
      console.error('Failed to save picture locally:', error);
      throw error;
    }
  }
  async getCameraPermission(): Promise<boolean> {
    if (this.hasCameraPermission(Camera.checkPermissions())) {
      return true;
    }

    const result = this.hasCameraPermission(
      await Camera.requestPermissions({ permissions: ['camera'] })
    );

    // We need to wait for a moment to let the UI catch up on iOS so that the user doesn't have to request the camera more than once. This
    // will only occur where camera permissions have not been requested or revoked. Once permissions have been granted, this code will be
    // unreachable.
    // await sleep(500);

    return result;
  }

  hasCameraPermission(camera): boolean {
    switch (camera) {
      case 'granted':
        return true;
    }
    return false;
  }

  async addNewToGallery() {
    if (this.platform.is('ios') || this.platform.is('android')) {
      let permission = await this.getCameraPermission();
    }

    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      promptLabelHeader: this.translateService.instant('select_photo'),
      promptLabelPhoto: this.translateService.instant('from_gallery'),
      promptLabelPicture: this.translateService.instant('from_camera'),
      quality: 50,
      saveToGallery: true,
      allowEditing: false,
      // width: 400,
    })
      .then((value: any) => value)
      .catch((err) => {});

    this.photoTaken = {
      filepath: capturedPhoto?.base64String,
      webviewPath: capturedPhoto?.webPath,
      url: capturedPhoto?.dataUrl,
    };

    // const savedImage = await Filesystem.writeFile({
    //   path: 'photos/' + new Date().getTime() + '.jpeg',
    //   data: capturedPhoto.base64String,
    // });
    // console.log(this.photoTaken)
    // console.log(savedImage)
    return this.photoTaken;
    // if (capturedPhoto) {
    //   return capturedPhoto;
    // }
    // }

    // throw new Error(
    //   'Permission to access the camera was denied or the camera returned nothing.'
    // );
  }

  // async addNewToGallery() {
  //   try {
  //     const capturedPhoto = await Camera.getPhoto({
  //       resultType: CameraResultType.Uri,
  //       source: CameraSource.Prompt,
  //       promptLabelHeader: this.translateService.instant('select_photo'),
  //       promptLabelPhoto: this.translateService.instant('from_gallery'),
  //       promptLabelPicture: this.translateService.instant('from_camera'),
  //       quality: 80,
  //       saveToGallery: true,
  //       allowEditing: false,
  //       width: 400,
  //     })
  //       .then((value: any) => value)
  //       .catch((err) => {});

  //     this.photoTaken = {
  //       filepath: capturedPhoto.base64String,
  //       webviewPath: capturedPhoto.webPath,
  //       url: capturedPhoto.dataUrl,
  //     };

  //     // const savedImage = await Filesystem.writeFile({
  //     //   path: 'photos/' + new Date().getTime() + '.jpeg',
  //     //   data: capturedPhoto.base64String,
  //     // });
  //     // console.log(this.photoTaken)
  //     // console.log(savedImage)
  //     return this.photoTaken;
  //   } catch (error) {
  //     // call toast for error message
  //   }
  // }

  async readAsBase64() {
    const response = await fetch(this.photoTaken.webviewPath!);
    const blob = await response.blob();
    return (await this.convertBlobToBase64(blob)) as string;
  }

  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
}

export interface PhotoTaken {
  filepath: String;
  webviewPath: string;
  url: String;
}
