import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OwnerState } from '../reducers/owner.reducer';
import Owner from 'src/app/models/owner.model';

export const OwnerSelector = createFeatureSelector<Owner>('owner');

export const ownerState = (state: OwnerState) => state.owner;

export const getProductGenetics = createSelector(
  OwnerSelector,
  (state: Owner) => {
    if (state?.countrie?.genetics) return state?.countrie?.genetics;
    else return null;
  }
);
