import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import {
  deleteCashflow,
  deleteCashflowSuccess,
  paginateCashflow,
  paginateCashflowSuccess,
  saveCashflow,
  saveCashflowSuccess,
  updateCashflow,
  updateCashflowSuccess,
} from '../actions/cashflow.action';

export interface CashFlowListState extends EntityState<CashFlow> {
  selectedSaleId: number | null;
  paginationResult: PaginationResult<CashFlow> | null;
  isLoading: boolean;
}

export function selectedCashflow(a: CashFlow): number {
  return a?.id ? a.id : 0;
}

export const cashflowListAdapter: EntityAdapter<CashFlow> =
  createEntityAdapter<CashFlow>({
    selectId: selectedCashflow,
    sortComparer: (a: CashFlow, b: CashFlow) => {
      if (Date.parse(a.date) == Date.parse(b.date)) {
        return Date.parse(b.created_at) - Date.parse(a.created_at);
      } else {
        return Date.parse(b.date) - Date.parse(a.date);
      }
    },
  });

export const initialCashflow: CashFlowListState =
  cashflowListAdapter.getInitialState({
    selectedSaleId: null,
    paginationResult: null,
    isLoading: false,
  });

export const cashflowListReducer = createReducer(
  initialCashflow,
  on(paginateCashflow, (state, action) => {
    return {
      ...state,
      isLoading: action.page == 1 ? true : false,
    };
  }),
  on(paginateCashflowSuccess, (state, { paginationResult }) => {
    if (cashflowListAdapter) {
      let sortedPaginationResult = cloneDeep(paginationResult);
      sortedPaginationResult.data.sort((a, b) => {
        if (Date.parse(a.date) == Date.parse(b.date)) {
          return Date.parse(b.id) - Date.parse(a.id);
        } else {
          return Date.parse(b.date) - Date.parse(a.date);
        }
      });
      if (paginationResult.meta && paginationResult.meta.current_page == 1) {
        return cashflowListAdapter.setAll(sortedPaginationResult.data, {
          ...state,
          paginationResult: sortedPaginationResult,
          isLoading: false,
        });
      } else
        return cashflowListAdapter?.addMany(sortedPaginationResult.data, {
          ...state,
          paginationResult: sortedPaginationResult,
          isLoading: false,
        });
    } else return undefined;
  }),
  on(deleteCashflow, (state, action) => {
    let newPaginationResult = cloneDeep(state.paginationResult);
    newPaginationResult.data = newPaginationResult.data.splice(action.id, 1);
    return cashflowListAdapter.removeOne(action.id, {
      ...state,
      paginationResult: newPaginationResult,
    });
  }),
  on(saveCashflow, (state, action:any) => {
    let cashflow = cloneDeep(action.data)
    cashflow.id = 'offline-'+action?.meta?.offlineId;

    return cashflowListAdapter.addOne(cashflow, {
      ...state,
      isLoading: true,
    });
  }),
  // on(saveCashflowSuccess, (state, action) => {
  //   return cashflowListAdapter.addOne(action.cashflow, {
  //     ...state,
  //     isLoading: true,
  //   });
  // }),
  on(updateCashflow, (state, action) => {
    return cashflowListAdapter.updateOne(
      { id: action.data.id, changes: action.data },
      state
    );
  }),
  on(updateCashflowSuccess, (state, action) => {
    return cashflowListAdapter.updateOne(
      { id: action.data.id, changes: action.data },
      state
    );
  })
);
