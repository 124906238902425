import { nextOfflineIndex } from './../../../utils/states-management/reducers/offline.reducer';
import {
  addFamilyMember,
  updateFamilyMember,
} from './../../../utils/states-management/actions/family-members.action';
import { Store } from '@ngrx/store';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { mapLangeNameObjects } from 'src/app/utils/array-filtrer';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { IonContent, LoadingController, ModalController, ToastController } from '@ionic/angular';

import {
  formatDate,
  PickerOptionFormater,
} from '../../../utils/pipes-utils/date_formatter';
import { Family_membersAPI } from '../../../apis/family_members.service';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { ToastUtils } from '../../../utils/toast_builder';
import { Family_member } from 'src/app/models/family_member.model';

import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { PhotoService, PhotoTaken } from 'src/app/services/photo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PhotoEditorComponent } from 'src/app/components/personal-data/profile-photo/photo-editor/photo-editor.component';
import { environment } from 'src/environments/environment';
import Employee from 'src/app/models/employee.model';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { TranslateService } from '@ngx-translate/core';
import { take, map } from 'rxjs/operators';
import { from, lastValueFrom, of } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-modal-page',
  templateUrl: './modal-page.component.html',
  styleUrls: ['./modal-page.component.scss'],
  providers: [Family_membersAPI],
})
export class ModalPageComponent implements OnInit {
  @Input() id: number;
  @Input() oldFamilyMember;
  @Input() should_add_employee = false;
  prefix;
  owner_id: number;
  currentYear;
  dateString;
  isDashboardView;
  image_path;
  progressModal;
  customPickerOptions;
  token;
  selected_langage;
  familyMember = new Family_member();
  formConfig;
  base_url = environment.appRoot;
  initialDate = new Date().toISOString();
  dateSelectionclicked = false;
  @Input('openedFromCashflow') openedFromCashflow = false;
  genders = [];

  async getowner_id() {
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      this.owner_id = JSON.parse(value.value)?.id;
    });
  }
  async translate() {
    this.genders = [
      {
        label: 'masculin',
        value: 'male',
      },
      {
        label: 'femnin',
        value: 'female',
      },
      {
        label: 'not_endicated',
        value: 'other',
      },
    ];
  }
  country;
  constructor(
    // private family_membersAPI: Family_membersAPI,
    public modalController: ModalController,
    private router: Router,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private progressDialog: LoadingController,
    private familyMemberAPI: Family_membersAPI,
    private employeeAPI: EmployeesAPI,
    private customPicker: PickerOptionFormater,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private store: Store<{ familyMember: Family_member }>,
    private toastController: ToastController
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
    let ClassName = 'ModalPageComponent';
    this.country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);
    this.currentYear = new Date();
    this.dateString = new Date(
      this.currentYear.getTime() - this.currentYear.getTimezoneOffset() * 60000
    );
    this.dateString = this.dateString.toISOString().split('T')[0];
    await this.translate();

    this.selected_langage = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    this.progressModal = await this.progressDialog
      .create()
      .then((value) => value);

    this.langageChanger(this.selected_langage);

    await this.getowner_id();
    this.familyMember.owner_id = this.owner_id;
    await this.getToken();
    //updating familyMember
    if (this.oldFamilyMember?.id) {
      //recuppérer f member
      const obj = this.oldFamilyMember;
      this.familyMember.id = obj?.id || null;
      this.familyMember.name = obj.name;
      this.familyMember.birth_date = obj.birth_date;
      this.familyMember.sex = obj.sex;
      this.familyMember.relation_type = obj.relation_type
        ? obj.relation_type
        : '';
      this.familyMember.mobile_number = obj.mobile_number;
      this.familyMember.is_receiving_salary =
        obj?.is_receiving_salary?.toString();
      if (obj?.file?.path) {
        this.image_path = this.base_url + obj?.file?.path;
      }else {
        this.image_path = 'assets/photo-placeholder.svg';
      }
    } else {
      this.familyMember.birth_date = new Date().toISOString();
    }

    this.customPickerOptions = this.customPicker.custom_picker_options(
      this.familyMember,
      'birth_date'
    );
  }

  async getToken() {
    this.token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
  }
  langageChanger(lang) {}

  confirm_method() {
    this.submitForm();
  }

  async submitForm() {
    if (this.familyMember?.hasSelectedPhoto) {
      this.familyMember.blobFile = await fetch(this.image_path).then((result) =>
        result.blob()
      );
    }
    this.familyMember.file = this.familyMember.blobFile;
    
    this.progressModal.present();



    if (!this.familyMember.name) {
      this.progressModal.dismiss();

      this.toastUtils.toastBuilder(
        this.translateService.instant('errors.all_fields'),
        '',
        '',
        'danger'
      );


      return;
    } else {
      // if (this.familyMember.birth_date != '') {
      //   this.familyMember.birth_date = formatDate(this.familyMember.birth_date);
      // }
      if (this.familyMember.is_receiving_salary === null) {
        this.familyMember.is_receiving_salary = 0;
      }
      
      if (this.familyMember.id) {
 
        this.store.dispatch(updateFamilyMember({ data: this.familyMember }));
        this.progressModal.dismiss();

        this.modalController.dismiss({
          dismissed: true,
          // person: value,
        });
        // this.familyMemberAPI
        //   .updateFamilyMembers(this.familyMember, this.id)
        //   .pipe(
        //     take(1),
        //     map((value) => {
        //       if (this.familyMember.hasSelectedPhoto) {
        //         if (this.familyMember?.file?.id) {
        //           this.updateImage(this.familyMember?.file?.id);
        //         } else {
        //           this.insertImage();
        //         }
        //       } else {
        //         this.progressModal.dismiss();
        //         this.modalController.dismiss({
        //           dismissed: true,
        //           person: value,
        //         });
        //       }
        //     })
        //   )
        //   .subscribe();
      } else {
        //TODO offline
        const farm = await lastValueFrom(
          this.store.select(getFarm).pipe(take(1))
        );
        // this.store.select(nextOfflineIndex).subscribe((value) =>
        let props = {
          hasSelectedPhoto: this.familyMember.hasSelectedPhoto,
          should_add_employee: this.should_add_employee,
          farm_id: farm?.id,
        };

        this.store.dispatch(

          addFamilyMember({ data: this.familyMember, props: props })
        );
        this.progressModal.dismiss();
        this.modalController.dismiss({
          dismissed: true,
        });

        // //TODO
        // if (!this.familyMember.birth_date) {
        //   this.familyMember.birth_date = null;
        // }
        // this.familMemberAPI
        //   .storeFamilyMembers(this.familyMember)
        //   .pipe(
        //     take(1),
        //     map((value) => {
        //       this.familyMember = value;
        //       if (this.hasSelectedPhoto) this.insertImage();
        //       else {
        //         if (this.should_add_employee) {
        //           this.addFamilyEmployee(value);
        //         } else {
        //           return value
        //           // this.progressModal.dismiss();
        //           // this.modalController.dismiss({
        //           //   dismissed: true,
        //           //   person: value,
        //           // });
        //         }
        //       }
        //     })
        //   )
        //   .subscribe((value)=>{
        //     this.progressModal.dismiss();
        //     this.modalController.dismiss({
        //       dismissed: true,
        //       person: value,
        //     });
        //   });
      }
    }
  }

  async choosePhoto() {
    await this.photoService
      .addNewToGallery()
      .then(async (value: PhotoTaken) => {
        const blob = await fetch(value?.webviewPath).then((response) =>
          response.blob()
        );
           if (value.webviewPath) {
          await this.showModificationModal(
            this.sanitizer.bypassSecurityTrustResourceUrl(value?.webviewPath)
          );
        }
      });

    this.familyMember.hasSelectedPhoto = true;
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async insertImage() {
    const file = await fetch(this.image_path).then((result) => result.blob());
    this.familyMemberAPI
      .storeFamilyMemberProfilePhoto(this.familyMember.id, file)
      .subscribe({
        next: async (value) => {
          this.familyMember.file = value;
          if (this.should_add_employee) {
            this.addFamilyEmployee(value);
          } else {
            this.progressModal.dismiss();
            this.modalController.dismiss({
              dismissed: true,
              person: value,
            });
          }
        },
        error: (err) => {
          this.progressModal.dismiss();
          console.log(err);
        },
      });
  }

  async updateImage(id) {
    const file = await fetch(this.image_path).then((result) => result.blob());
    this.familyMemberAPI.updateFamilyMemberPhoto(id, file).subscribe(
      async (value) => {
        this.familyMember.file = value;
        this.progressModal.dismiss();

        this.modalController.dismiss({
          dismissed: true,
          person: this.familyMember,
        });
      },
      (err) => {
        this.progressModal.dismiss();
      }
    );
  }

  async showModificationModal(image_path) {
      let componentProps = {
        image_path: image_path,
        modalController: this.modalController,
        cancel: await this.translateService
          .get('cancel')
          .toPromise()
          .then((value) => value),
        confirm: await this.translateService
          .get('confirm')
          .toPromise()
          .then((value) => value),
      };
      let modal = await this.toastUtils.modalBuilder(
        PhotoEditorComponent,
        componentProps,
        ['my-custom-modal-css', 'modal-wrapper']
      );

      const { data } = await modal.onWillDismiss();
      if (data?.cancel) this.cancelar();
      if (data?.image && data?.img64String) {
        if (data.img64String.length * 2 > 10 * 2 ** 40) {
          this.toastUtils.toastBuilder(
            this.translateService.instant('errors.img_size'),
            '',
            '',
            'danger'
          );
        }  else {
          let props = {
            hasSelectedPhoto: this.familyMember.hasSelectedPhoto,
          };
          this.image_path = data?.image;
        }
      } else {
        await modal.dismiss();
      }
    
  }

  async addFamilyEmployee(member) {
    const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));
    if (!member.employee && farm?.id) {
      member.farm_id = farm?.id;
      member.morphable_type = 'FamilyMember';
      member.morphable_id = member.id;
      this.employeeAPI.saveEmployee(member).subscribe(
        (val) => {
          this.progressModal.dismiss();
          this.modalController.dismiss({
            dismissed: true,
            person: member,
          });
          member.employee = val;
        },
        (e) => {
          this.progressModal.dismiss();
          this.modalController.dismiss({
            dismissed: true,
          });
        }
      );
    }
  }

  dateSelectionClicked() {
    this.dateSelectionclicked = true;
    this.scrollBottom();
  }

  instantiateEmployeeFamily(family, farm): Employee {
    const employee = new Employee();
    employee.farm_id = farm?.id;
    employee.is_permanent = 1;
    employee.is_receiving_salary = null;
    employee.morphable_type = 'FamilyMember';
    employee.morphable_id = family.id;
    employee.name = family.name;
    employee.sex = family.sex;
    return employee;
  }

  scrollBottom() {
    // try {
    //   this.content.scrollToBottom(100);
    // } catch (err) {
    //
    // }
  }
  hasSelectedPhoto;
  async cancelar() {
    this.image_path = await Storage.get({ key: dev.FILE_KEY }).then((value) => {
      const savedPath = JSON.parse(value.value);
      if (savedPath?.path) {
        return environment.appRoot + JSON.parse(value.value).path;
      } else return 'assets/photo-placeholder.svg';
    });
    this.hasSelectedPhoto = false;
  }
}
