import { map, switchMap, take } from 'rxjs/operators';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { Router } from '@angular/router';
import { ToastUtils } from '../../../utils/toast_builder';
import { Family_membersAPI } from '../../../apis/family_members.service';
import routes from '../../../config/routing.json';
import { TranslateService } from '@ngx-translate/core';
import { dev } from 'src/app/config/offlines_keys.json';
import Owner from 'src/app/models/owner.model';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { from, lastValueFrom, of } from 'rxjs';
import { getOwnerById } from 'src/app/utils/states-management/actions/owner.action';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
@Component({
  selector: 'app-family-view-one',
  templateUrl: './family-view-one.component.html',
  styleUrls: ['./family-view-one.component.scss'],
  providers: [Family_membersAPI],
})
export class FamilyViewOneComponent implements OnInit {
  title;
  family_situation_opt1;
  family_situation_opt2;
  situation = null;
  next;
  return;
  state: string;
  owner;
  profile_info={
    has_family:null,
    
  };
  valbutton;
  previsoulySelected;
  user;
  icon;
  displayAloneIcon = false;
  family_length;
  loader = true;
  constructor(
    private ownerAPI: OwnerAPI,
    private router: Router,
    public translateService: TranslateService,
    private toastController: ToastUtils,
    private routingConfig: RoutingConfig,
    private familyMemberAPI: Family_membersAPI,
    private store: Store<{ owner: Owner }>
  ) {
    this.store.dispatch(getOwnerById())
  }

  async ngOnInit() {
    await this.langageChanger();
    this.store.select('owner').subscribe((value) => {
      this.owner = cloneDeep(value);
      if(this.owner?.profile_info){
      this.profile_info = JSON.parse(this.owner?.profile_info);
      if (this.profile_info?.has_family) {
        this.situation = true;
      } else if (
        this.profile_info?.has_family &&
        this.profile_info?.has_family != null
      ) {
        this.situation = false;
      }
    }
    })

    this.familyMemberAPI.getFamilyMembers().subscribe((value) => {
      this.family_length = value.length;
    });
    this.user = await Storage.get({ key: dev.USER_KEY }).then((value) => {
      let val = JSON.parse(value.value);
      if (val?.sex == 'male') {
        this.icon = 'man-outline';
        this.displayAloneIcon = true;
      } else if (val?.sex == 'female') {
        this.icon = 'woman-outline';
        this.displayAloneIcon = true;
      }
      return val;
    });
  }

  async langageChanger() {
    this.title = await this.translateService
      .get('titles.family_situation_question')
      .toPromise();
    this.family_situation_opt1 = await this.translateService
      .get('titles.family_situation_opt1')
      .toPromise();

    this.family_situation_opt2 = await this.translateService
      .get('titles.family_situation_opt2')
      .toPromise();

    this.next = await this.translateService.get('next').toPromise();
    this.return = await this.translateService.get('return').toPromise();
  }

  async mnext() {
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.owner.country_id = country.id;
    if (this.situation == null) {
      await this.toastController
        .toastBuilder(
          '',
          'bottom',
          await this.translateService
            .instant('errors.select_option'),
          'danger'
        );
    } else {
      this.profile_info['has_family'] = this.situation;
      this.owner.profile_info = JSON.stringify(this.profile_info)

      this.ownerAPI
        .updateOwnerData(this.owner)
        .pipe(
          map((user: Owner) => user),
          take(1),
          switchMap((user: Owner) =>
            from(
              Storage.set({ key: dev.USER_KEY, value: JSON.stringify(user) })
            )
          ),
          switchMap((value) => {
            if (JSON.parse(this.situation) == false && this.family_length > 0)
              return this.familyMemberAPI.deleteFamilyMembers();
            else return of({});
          })
        )
        .subscribe((val) => {
          if (JSON.parse(this.situation) == true) {
            this.router.navigateByUrl('/family/members');
          } else if (JSON.parse(this.situation) == false) {
            this.router.navigateByUrl('/farm');
            // this.router.navigateByUrl(await this.routingConfig.getNextUrl());
          }
        });
    }
  }
  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }
}
