import { updateFarm } from './../../../utils/states-management/actions/farm.action';
import { Farm } from './../../../models/farm/farm.model';
import { dev } from 'src/app/config/offlines_keys.json';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import routes from '../../../config/routing.json';
import { Storage } from '@capacitor/storage';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FarmAPI } from 'src/app/apis/farm.service';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { SummaryComponent } from '../../dashboard/summary/summary.component';
import { IonNav } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-team-interface-lite',
  templateUrl: './team-interface-lite.component.html',
  styleUrls: ['./team-interface-lite.component.scss'],
})
export class TeamInterfaceLiteComponent {
  @Input() empType: string;
  @Input() nav: IonNav;
  language;
  has_permanent_employees = true;
  has_temporal_employees = true;
  isDashboardView: boolean;
  farm:Farm;
  @Output() ChangePermanentValueEvent: EventEmitter<any> = new EventEmitter();
  @Output() ChangeTemporalValueValueEvent: EventEmitter<any> =
    new EventEmitter();

  constructor(
    private store: Store<{
      farm: Farm;
    }>,
    public router: Router,
    public translateService: TranslateService,
  ) {
    this.language = this.translateService
      .get('screens.question_person')
      .subscribe((value) => (this.language = value));
  }

  async ngOnInit(): Promise<void> {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    // let f = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    this.farm = cloneDeep(await lastValueFrom(this.store.select(getFarm).pipe(take(1))));
    if (this.farm?.number_permanent_employee) {
      this.has_permanent_employees = true;
    }
    if (this.farm?.number_temporal_employee) {
      this.has_temporal_employees = true;
    }
  }
  handelPermanentChange() {
    if (this.has_permanent_employees) {
      this.ChangePermanentValueEvent.emit(0);
      this.farm.number_permanent_employee = 0;
    }
  }
  handelTemporalChange() {
    if (this.has_permanent_employees) {
      this.ChangeTemporalValueValueEvent.emit(0);
      this.farm.number_temporal_employee = 0;
    }
  }
  HandelChangePermanentValue() {
    this.ChangePermanentValueEvent.emit(this.farm?.number_permanent_employee);
  }
  HandelChangeTemporalValue() {
    this.ChangeTemporalValueValueEvent.emit(this.farm.number_temporal_employee);
  }
  async saveUpdate() {
    // const owner = JSON.parse((await Storage.get({ key: dev.USER_KEY })).value);
    this.store.dispatch(updateFarm({ data: this.farm,id: this.farm.id }));
    // this.nav.setRoot(SummaryComponent, {
    //   nav: this.nav,
    //   rootStack: PriorDashboardComponent,
    // });
    // this.farmAPI.upDateFarm(this.farm).subscribe(async (value) => {
    //   await Storage.set({
    //     key: dev.FARM_KEY,
    //     value: JSON.stringify(this.farm),
    //   });

    // });
  }
}
