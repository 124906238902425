import { ToastUtils } from 'src/app/utils/toast_builder';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { IonContent, ModalController, PopoverController } from '@ionic/angular';
import { DateFilterModalComponent } from '../../widgets/date-filter-modal/date-filter-modal.component';
import { getCurrentMonthAndYear } from 'src/app/utils/pipes-utils/date_formatter';
import { ModalCashflowEntryListComponent } from '../../modal-cashflow-entry-list/modal-cashflow-entry-list.component';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Store } from '@ngrx/store';
import {
  getAllListableCashflows,
  getCashflowPaginationResult,
} from 'src/app/utils/states-management/selector/cashflow-selector';
import { cloneDeep } from 'lodash';
import {
  deleteCashflow,
  paginateCashflow,
} from 'src/app/utils/states-management/actions/cashflow.action';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import { Subscription, lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

export interface BookCashflowListFilter {
  startDate: string;
  endDate: string;
  filterCategory: any;
  filterType: any;
  categorieIsFiltred: any;
  cashflowTypeIsFiltred: any;
  is_loading: boolean;
  isFiltred: boolean;
  page: number;
  page_size: number;
  isHarvestingFilter: boolean;
}

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
})
export class BookComponent implements OnInit, OnDestroy {
  @Input() nav;
  @Input() rootStack;
  @ViewChild(IonContent) content: IonContent;
  isAddBtnClicked = false;
  entries: Array<CashFlow> = null;
  monthYear = getCurrentMonthAndYear(new Date());
  country;
  productionsPerfarm: Array<ProductionsPerfarm>;
  showBottomProgress: boolean = false;
  paginationResult;
  subscriptions: Array<Subscription> = [];
  @Input() bookCashflowListFilter: BookCashflowListFilter = {
    startDate: null,
    endDate: null,
    filterCategory: [],
    filterType: [],
    categorieIsFiltred: null,
    cashflowTypeIsFiltred: null,
    is_loading: true,
    isFiltred: false,
    page: 1,
    page_size: 100,
    isHarvestingFilter: false,
  };
  constructor(
    private modalController: ModalController,
    private popOverController: PopoverController,
    private toastUtils: ToastUtils,
    private store: Store<{ cashflowList: any }>,
    private changeDetector: ChangeDetectorRef
  ) {}
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  async ngOnInit() {
    // this.store.dispatch(
    //   paginateCashflow({
    //     startDate: this.bookCashflowListFilter.startDate,
    //     endDate: this.bookCashflowListFilter.endDate,
    //     page: this.bookCashflowListFilter.page,
    //     page_size: this.bookCashflowListFilter.page_size,
    //     accountings_entries: this.bookCashflowListFilter.filterCategory,
    //     natures: this.bookCashflowListFilter.filterType,
    //     is_harvesting_filter: this.bookCashflowListFilter.isHarvestingFilter,
    //   })
    // );
    // this.country = await lastValueFrom(
    //   this.store.select(getCountry).pipe(take(1))
    // );
    // this.store.select('cashflowList').subscribe((value: any) => {
    //   this.bookCashflowListFilter.is_loading = value.isLoading;
    //   this.paginationResult = cloneDeep(value.entities);
    // });
    // this.store.select(getAllListableCashflows).subscribe((value:any) => {
    //   this.paginationResult = cloneDeep(value);
    //   // this.bookCashflowListFilter.is_loading = false;
    //   // var filtredEntries = cloneDeep(this.paginationResult.data);
    //   // this.showBottomProgress = false;
    //   // if (
    //   //   this.paginationResult &&
    //   //   this.paginationResult.meta &&
    //   //   this.paginationResult.meta.current_page == 1
    //   // ) {
    //   //   this.entries = [];
    //   //   this.entries = filtredEntries;
    //   // } else {
    //   //   if (Array.isArray(this.entries))
    //   //     this.entries = this.entries.concat(filtredEntries);
    //   //   else {
    //   //     this.entries = cloneDeep(filtredEntries);
    //   //   }
    //   // }
    //   // this.bookCashflowListFilter.is_loading = false;
    // });

    // this.prepareList();
  }

  async prepareList() {
    this.bookCashflowListFilter.is_loading = true;
    this.subscriptions.push(
      this.store
        .select(getAllListableCashflows)
        .pipe(
          filter((value) => (value && Array.isArray(value) ? true : false))
        )
        .subscribe((value) => {
          this.paginationResult = cloneDeep(value);
          this.bookCashflowListFilter.is_loading = false;
          var filtredEntries = cloneDeep(this.paginationResult.data);
          this.showBottomProgress = false;
          if (
            this.paginationResult &&
            this.paginationResult.meta &&
            this.paginationResult.meta.current_page == 1
          ) {
            this.entries = [];
            this.entries = filtredEntries;
          } else {
            if (Array.isArray(this.entries))
              this.entries = this.entries.concat(filtredEntries);
            else {
              this.entries = cloneDeep(filtredEntries);
            }
          }
          this.bookCashflowListFilter.is_loading = false;
        })
    );
  }

  async filter() {
    let componentProps = {
      modalController: this.modalController,
    };
    let modal = await this.toastUtils.modalBuilder(
      DateFilterModalComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );
    const { data } = await modal.onWillDismiss();

    if (data?.bookCashflowListFilter) {
      this.bookCashflowListFilter = data.bookCashflowListFilter;
      this.store.dispatch(
        paginateCashflow({
          startDate: this.bookCashflowListFilter.startDate,
          endDate: this.bookCashflowListFilter.endDate,
          page: 1,
          page_size: this.bookCashflowListFilter.page_size,
          accountings_entries: this.bookCashflowListFilter.filterCategory,
          natures: this.bookCashflowListFilter.filterType,
          is_harvesting_filter: false,
        })
      );
      if (
        (this.bookCashflowListFilter.startDate != null &&
          this.bookCashflowListFilter.endDate != null) ||
        this.bookCashflowListFilter.filterCategory.length > 0 ||
        this.bookCashflowListFilter.filterType.length > 0
      ) {
        this.bookCashflowListFilter.isFiltred = true;
      }
    }
  }

  submitForm() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: this.rootStack,
    });
  }

  async presentCashFlowInputModal() {
    this.isAddBtnClicked = true;
    let componentProps = {
      modalController: this.popOverController,
      nav: this.nav,
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      ModalCashflowEntryListComponent,
      ['popover_class', 'diario']
    );
    await popover.onWillDismiss();
    this.bookCashflowListFilter.page = 1;
    this.isAddBtnClicked = false;
  }

  async deleteEntry(value) {
    this.bookCashflowListFilter.page = 1;
    if (value && value.id) {
      this.store.dispatch(deleteCashflow({ id: value.id }));
      // this.entries.splice(this.entries.indexOf(value), 1);
      // } else
      // this.store.dispatch(
      //   paginateCashflow({
      //     startDate: this.bookCashflowListFilter.startDate,
      //     endDate: this.bookCashflowListFilter.endDate,
      //     page: 1,
      //     page_size: 10,
      //     accountings_entries: this.bookCashflowListFilter.filterCategory,
      //     natures: this.bookCashflowListFilter.filterType,
      //   })
      // );
    }
  }

  async logScrollEnd(event) {
    let pos = event.target.scrollTop + event.target.offsetHeight;
    let max = event.target.scrollHeight;
    if (Math.round(pos) >= max) {
      if (
        this.bookCashflowListFilter.page + 1 <=
        this.paginationResult.meta.last_page
      ) {
        this.bookCashflowListFilter.page += 1;
        this.showBottomProgress = true;
        // this.bookCashflowListFilter.is_loading = true
        this.store.dispatch(
          paginateCashflow({
            startDate: this.bookCashflowListFilter.startDate,
            endDate: this.bookCashflowListFilter.endDate,
            page: this.bookCashflowListFilter.page,
            page_size: this.bookCashflowListFilter.page_size,
            accountings_entries: this.bookCashflowListFilter.filterCategory,
            natures: this.bookCashflowListFilter.filterType,
            is_harvesting_filter: false,
          })
        );
      }
    }

    // } else {
    //   this.infiniteScrollEvent.target.complete();
    // }
  }
  clearFilter() {
    this.bookCashflowListFilter = {
      startDate: null,
      endDate: null,
      filterCategory: [],
      filterType: [],
      categorieIsFiltred: null,
      cashflowTypeIsFiltred: null,
      is_loading: true,
      isFiltred: false,
      page: 1,
      page_size: 10,
      isHarvestingFilter: false,
    };
    this.store.dispatch(
      paginateCashflow({
        startDate: this.bookCashflowListFilter.startDate,
        endDate: this.bookCashflowListFilter.endDate,
        page: this.bookCashflowListFilter.page,
        page_size: this.bookCashflowListFilter.page_size,
        accountings_entries: this.bookCashflowListFilter.filterCategory,
        natures: this.bookCashflowListFilter.filterType,
        is_harvesting_filter: false,
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((element: Subscription) =>
      element.unsubscribe()
    );
  }
}
