import {
  Account,
  BUY_AGRICOLAS_LOT,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_IDS,
  CASHFLOW_SECOND_COFFEE_SELLS_ID,
  CASHFLOW_SECOND_SELLS_CEREZA_COFFEE_ID,
  CASHFLOW_SECOND_SELLS_GRANO_CACAO_ID,
  CASHFLOW_SECOND_SELLS_MOJADO_COFFEE_ID,
  CASHFLOW_SECOND_SELLS_SECO_CACAO_ID,
  CASHFLOW_SECOND_SELLS_SECO_COFFEE_ID,
  COCOA_ACCOUNTING_ENTRY_CATEGORY,
  COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES,
  MORPH_Coffe_Sell,
  MORPH_Family_member_personal,
  MORPH_Lot,
} from './../../../../utils/consts';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController, ToastController } from '@ionic/angular';
import { LotAPI } from 'src/app/apis/lot.service';
import { MoneyReportingsAPI } from 'src/app/apis/money_reportings.service';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { ModalBankAccountComponent } from 'src/app/modules/goods/modal-bank-account/modal-bank-account.component';
import { dev } from '../../../../config/offlines_keys.json';
import {
  COMPRA_ARBOLES_CASHFLOW_CATEGORIES,
  PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
  SALARIO_MENSUALES_ID,
  SELL_AGRICOLAS_LOT_CASHFLOW_CATEGORIE,
  SELL_OTRO_USO_LOT_CASHFLOW_CATEGORIE,
} from 'src/app/utils/consts';
import { LotsCreatorComponent } from '../../../farm/lots-components/lots-creator/lots-creator.component';
import { Lot, USAGE_TYPE } from 'src/app/models/lots.model';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { TranslateService } from '@ngx-translate/core';
import { Family_membersAPI } from 'src/app/apis/family_members.service';
import { ModalPageComponent } from 'src/app/modules/family/modal-page/modal-page.component';
import { PopoverImgComponent } from 'src/app/widgets/popover-img/popover-img.component';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Store } from '@ngrx/store';
import { selectAllFamilyMembers } from 'src/app/utils/states-management/selector/family-members.selector';
import { cloneDeep } from 'lodash';
interface FormConfig {
  show_propiedas_value_tracker: boolean;
  show_add_thrid_model_button: boolean;
  show_caution_form: boolean;
  show_debt_form: boolean;
  show_money_loan_form: boolean;
}

@Component({
  selector: 'app-third-model-selector',
  templateUrl: './third-model-selector.component.html',
  styleUrls: ['./third-model-selector.component.scss'],
})
export class ThirdModelSelectorComponent implements OnInit, OnChanges {
  @Input() model_required_object;
  @Input() cashflow: any;
  @Input() cashflowDate: any;

  @Output() selectedObjectEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cashflowMorphableChange: EventEmitter<CashFlow> =
    new EventEmitter();
  @Output() maxQuantityAllowedValueChange: EventEmitter<Number> =
    new EventEmitter();
  @Output() navigateToAnotherCashflowCategories: EventEmitter<any> =
    new EventEmitter();
  @Output() forceDismissEvent: EventEmitter<any> = new EventEmitter();
  @Output() pagoPercantitadCoechadaTriggered: EventEmitter<void> =
    new EventEmitter();
  @Output() showFormChange: EventEmitter<boolean> = new EventEmitter();
  title;
  is_list = true;
  desiredModel = null;
  objectList = [];
  is_loading = false;
  selectedFamilyMember;
  is_add_required = true;
  thridModelSelected;
  owner;
  hide_progress_bar = false;
  COMPRA_ARBOLES_CASHFLOW_CATEGORIES = COMPRA_ARBOLES_CASHFLOW_CATEGORIES;
  COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES =
    COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES;
  PERDIDA_ARBOLES_CASHFLOW_CATEGORIES = PERDIDA_ARBOLES_CASHFLOW_CATEGORIES;
  selected_product;
  preselect_production;
  emptyFirstSale = false;
  formConfing: FormConfig = {
    show_propiedas_value_tracker: false,
    show_add_thrid_model_button: false,
    show_caution_form: false,
    show_debt_form: false,
    show_money_loan_form: false,
  };
  qty_total_label = '';
  MORPH_Lot = MORPH_Lot;
  constructor(
    private lotAPI: LotAPI,
    private modalCtrl: ModalController,
    private cashflowAPI: CashflowAPI,
    public translateService: TranslateService,
    private toastController: ToastController,
    private moneyReportingAPI: MoneyReportingsAPI,
    private familyMemberAPI: Family_membersAPI,
    private toastUtils: ToastUtils,
    private changeDetector: ChangeDetectorRef,
    private store: Store
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (
      this.model_required_object?.model_name &&
      this.model_required_object?.model_name.includes('cashflow')
    ) {
      // this.is_list = false;
      // this.is_add_required = false;
      this.is_loading = true;

      this.getCashflow();
    }
  }
  ionViewDidLoad() {}

  async ngOnInit() {
    this.owner = JSON.parse((await Storage.get({ key: dev.USER_KEY })).value);
    this.selectedModelLoading();
    this.preselect_production = await Storage.get({
      key: dev.PRESELECTED_PRODUCT_KEY,
    }).then((value) => JSON.parse(value.value));
    this.setLabels();
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  setLabels() {
    if (this.cashflow.is_a_loss) {
      this.qty_total_label = 'Cantidad restante';
    } else if (this.cashflow.is_a_loss === false) {
      this.qty_total_label = 'Cantidad total';
    }

    if (this.cashflow.is_income) {
      this.qty_total_label = 'Cantidad total';
    } else if (this.cashflow.is_income === false) {
      this.qty_total_label = 'Cantidad total';

      if (this.cashflow.is_income) {
        this.qty_total_label = 'Cantidad total';
      } else if (this.cashflow.is_income === false) {
        this.qty_total_label = 'Cantidad total';
      }
    }
  }

  async selectedModelLoading() {
    this.is_loading = true;
    this.is_add_required = true;

    if (this.model_required_object.model_name) {
      if (this.model_required_object.model_name.includes(MORPH_Lot)) {
        if (this.cashflow.is_income !== false) this.getLots();
        else {
          this.hide_progress_bar = true;
          this.selectedObjectEventEmitter.emit({
            model: this.model_required_object.model_name,
          });
        }
      } else if (this.model_required_object.model_name.includes('cashflow')) {
        this.is_list = false;
        this.is_add_required = false;
        this.getCashflow();
        this.title = this.translateService.instant('label.coffee_sales');
      } else if (
        this.model_required_object.model_name.includes('money_reporting')
      ) {
        this.getMoneyReporting();
      } else if (this.model_required_object.model_name.includes('family')) {
        this.getFamilyMember();
        this.is_loading = false;
      } else {
        this.is_loading = false;
      }
    }
  }

  getFamilyMember() {
    this.store.select(selectAllFamilyMembers).subscribe((value) => {
      this.objectList = cloneDeep(value);
      if (!Array.isArray(this.objectList)) this.objectList = new Array();
      this.objectList.push({
        name: this.owner.first_name,
        id: this.owner.id,
        is_owner: true,
        birth_date: this.owner.birth_date,
        file: this.owner.file,
      });
      if (this.cashflow.morphable)
        this.desiredModel = this.objectList.find(
          (element) =>
            (element.name === this.cashflow.morphable.name ||
              element.name === this.owner.first_name) &&
            element.id === this.cashflow.morphable.id
        );
      if (this.desiredModel) this.selectedFamilyMember = this.desiredModel.name;
    });
    // this.familyMemberAPI.getFamilyMembers().subscribe((values) => {
    //   this.objectList = values;
    //   if (!Array.isArray(this.objectList)) this.objectList = new Array();

    // });
  }

  findThirdModel() {
    // this.thridModelSelected = this.cashflow.morphable
    if (this.objectList) {
      this.thridModelSelected = this.objectList.find((element) =>
        this.cashflow.morphable && this.cashflow.morphable?.id
          ? element.id === this.cashflow.morphable.id
          : element.id === this.cashflow.morphable_id
      );
    }
  }

  redirectToCoffeProduction() {
    if (
      this.model_required_object.model_name &&
      this.model_required_object.model_name.includes('product') &&
      this.model_required_object.query == 'null'
    ) {
      this.cashflow.amount = 0;
      this.cashflow.qty = 0;
      if (this.model_required_object.id == '16') {
        this.cashflow.cash_flow_categorie_id = 9;
        this.navigateToAnotherCashflowCategories.emit(9);
      } else if (this.model_required_object.id == '17') {
        this.cashflow.cash_flow_categorie_id = 10;

        this.navigateToAnotherCashflowCategories.emit(10);
      } else if (this.model_required_object.id == '18') {
        this.cashflow.cash_flow_categorie_id = 11;
        this.navigateToAnotherCashflowCategories.emit(11);
      }
      this.cashflow.goToProduction = true;
      this.cashflow.is_income = null;
      this.showFormChange.emit(true);

      //   this.cashflow.cash_flow_categorie_id = this.model_required_object.related_cashflow_categorie
      //   this.navigateToAnotherCashflowCategories.emit(
      //   this.model_required_object.related_cashflow_categorie
      // );
    }
  }
  async addThridPartModel() {
    let component;
    let componentProps;
    if (
      this.model_required_object.model_name &&
      this.model_required_object.model_name.includes('cashflow') &&
      this.model_required_object.query == 'is_first_sale'
    ) {
      this.cashflow.cash_flow_categorie_id = 304;
      this.model_required_object.related_cashflow_categorie;

      this.navigateToAnotherCashflowCategories.emit(
        this.model_required_object.related_cashflow_categorie
      );
      return;
    }

    if (
      this.model_required_object.model_name &&
      this.model_required_object.model_name.includes(MORPH_Lot)
    ) {
      component = LotsCreatorComponent;
      componentProps = {
        modalMain: this.modalCtrl,
        lot: new Lot(),
        used_for: [
          SELL_AGRICOLAS_LOT_CASHFLOW_CATEGORIE,
          BUY_AGRICOLAS_LOT,
        ].includes(this.cashflow.cash_flow_categorie_id)
          ? USAGE_TYPE.agricol
          : USAGE_TYPE.other,
        just_end: true,
      };
    } else if (
      this.model_required_object.model_name &&
      this.model_required_object.model_name.includes('money_reporting')
    ) {
      component = ModalBankAccountComponent;
      if (this.model_required_object.query.includes('cash')) {
        componentProps = {
          bank_type: 'cash_money',
        };
      } else if (this.model_required_object.query.includes)
        componentProps = {
          bank_type: 'current_account',
        };
    }
    if (component && componentProps) {
      const modal = await this.modalCtrl.create({
        component: component,
        cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
        componentProps: componentProps,
        id: '2',
      });
      await modal.present();
      await modal.onDidDismiss().then(async (val) => {
        if (val?.data?.loan) {
          this.thridModelSelected = val.data.loan;
          this.thridModelSelected.amount = parseFloat(val.data.loan.amount);
          this.thridModelSelected.paid_so_far = parseFloat(
            val.data.loan.paid_so_far
          );

          this.selectedModelLoading();

          this.modelSelectChanged(val.data.loan);
        } else if (val.data.lot) {
          this.thridModelSelected = val.data.lot;
          this.selectedModelLoading();
          this.modelSelectChanged(val.data.lot);
        }
      });
    }
    //change from ing to produccion
    this.redirectToCoffeProduction();
  }

  modelSelectChanged(vlaue) {
    if (
      this.model_required_object.model_name &&
      this.model_required_object.model_name.includes(MORPH_Lot)
    ) {
      this.selectedObjectEventEmitter.emit({
        id: vlaue.id,
        model: this.model_required_object.model_name,
      });
      this.cashflow.morphable_type = MORPH_Lot;
      this.cashflow.morphable_id = vlaue.id;
      this.cashflowMorphableChange.emit(this.cashflow);
    } else if (
      this.model_required_object.model_name &&
      this.model_required_object.model_name.includes('cashflow')
    ) {
      this.cashflow.morphable_id = vlaue.id;
      this.cashflow.morphable_type = MORPH_Coffe_Sell;
      this.cashflowMorphableChange.emit(this.cashflow);
    } else if (
      this.model_required_object.model_name &&
      this.model_required_object.model_name.includes('money_reporting')
    ) {
      this.title = this.translateService.instant('label.account_selection');
      this.cashflow.morphable_id = vlaue.id;
      this.cashflow.morphable_type = Account;
      this.cashflowMorphableChange.emit(this.cashflow);
    } else if (this.model_required_object.model_name === 'operation') {
      this.cashflow = vlaue;
      this.cashflowMorphableChange.emit(vlaue);
    }
  }

  async getLots() {
    this.title = 'Lotes';

    this.lotAPI.getLotByFarm().subscribe(
      (val) => {
        if (Array.isArray(val))
          this.objectList = val.filter((lot) => {
            if (lot.is_owned === 1) {
              if (
                this.cashflow.cash_flow_categorie_id ===
                  SELL_AGRICOLAS_LOT_CASHFLOW_CATEGORIE &&
                lot.type === USAGE_TYPE.agricol
              )
                return lot;
              else if (
                this.cashflow.cash_flow_categorie_id ===
                  SELL_OTRO_USO_LOT_CASHFLOW_CATEGORIE &&
                lot.type === USAGE_TYPE.other
              )
                return lot;
            }
          });
        this.is_loading = false;
        if (
          this.is_list &&
          (this.cashflow.morphable || this.cashflow.morphable_id)
        )
          this.findThirdModel();
      },
      (e) => {
        this.is_loading = false;
      }
    );
  }

  calculatePercent(amount, paid_so_far) {
    if (!paid_so_far) {
      paid_so_far = 0;
    }
    return paid_so_far / amount;
  }

  getCashflow() {
    this.cashflowAPI.getFirstSalesByOwnerId().subscribe(async (val: any) => {
      if (
        [
          COCOA_ACCOUNTING_ENTRY_CATEGORY,
          CAFE_ACCOUNTING_ENTRY_CATEGORY,
        ].includes(this.cashflow.accounting_entries_categories)
      ) {
        var cropId = CAFE_IDS[0];
        if (
          CASHFLOW_SECOND_COFFEE_SELLS_ID.includes(
            this.cashflow.cash_flow_categorie_id
          )
        ) {

          if (
            CASHFLOW_SECOND_SELLS_CEREZA_COFFEE_ID.includes(
              this.cashflow.cash_flow_categorie_id
            )
          ) {
            cropId = CAFE_IDS[0]; // CEREZA_COFFEE_ID
          } else if (
            this.cashflow.cash_flow_categorie_id ==
            CASHFLOW_SECOND_SELLS_MOJADO_COFFEE_ID
          ) {
            cropId = CAFE_IDS[1]; // MOJADO_COFFEE_ID
          } else if (
            this.cashflow.cash_flow_categorie_id ==
            CASHFLOW_SECOND_SELLS_SECO_COFFEE_ID
          ) {
            cropId = CAFE_IDS[2]; // SECO_COFFEE_ID
          } else if (
            this.cashflow.cash_flow_categorie_id ==
            CASHFLOW_SECOND_SELLS_SECO_CACAO_ID
          ) {
            cropId = CAFE_IDS[3]; // SECO_CACAO_FRESH
          } else if (
            this.cashflow.cash_flow_categorie_id ==
            CASHFLOW_SECOND_SELLS_GRANO_CACAO_ID
          ) {
            cropId = CAFE_IDS[4]; // SECO_CACAO_SEC
          }
        }
        this.objectList = await val.filter(
          (value) =>
            (this.cashflow?.id &&
              value.cashflow &&
              value.product_id == cropId) ||
            (!this.cashflow?.id &&
              value?.cashflow?.length == 0 &&
              value.product_id == cropId &&
              new Date(this.cashflow.date.split('T')[0]) >=
                new Date(value.date))
        );
      } else {
        this.objectList = await val.filter(
          (value) =>
            (this.cashflow?.id && value.cashflow && value.product_id == 1) ||
            (!this.cashflow?.id &&
              value?.cashflow?.length == 0 &&
              value.product_id == 1 &&
              new Date(this.cashflow.date.split('T')[0]) >=
                new Date(value.date))
        );
      }
      if (this.cashflow?.id) {
        this.thridModelSelected = this.cashflow.morphable;
      }

      this.is_list = true;
      if (this.is_list && this.cashflow.morphable) {
        this.findThirdModel();
        this.objectList.push(this.cashflow.morphable);
      }
      if (
        Array.isArray(this.objectList) &&
        this.objectList.length < 1 &&
        this.model_required_object.related_cashflow_categorie
      ) {
        this.emptyFirstSale = true;
        this.is_add_required = true;
        await this.presentNavigationToast();
      }

      this.is_loading = false;
    });
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ngDoCheck(): void {
    this.configForm();
  }

  async presentNavigationToast() {
    let toast = await this.toastController.create({
      header: '',
      message: await this.translateService
        .get('error_seconde_vente')
        .toPromise()
        .then((value) => value),
      position: 'top',
      duration: 1000,
      buttons: [
        {
          side: 'end',
          text: 'Añadir',
          handler: () => {
            this.navigateToAnotherCashflowCategories.emit(
              this.model_required_object.related_cashflow_categorie
            );
            toast.dismiss();
          },
        },
      ],
    });
  }

  manoDeObraSetChanged(value) {
    this.cashflow = value;
    this.cashflowMorphableChange.emit(this.cashflow);
  }

  getMoneyReporting() {
    this.moneyReportingAPI.getMoneyReporting().subscribe(
      (val) => {
        this.objectList = val;
        this.is_loading = false;
        if (this.is_list && this.cashflow.morphable) this.findThirdModel();
      },
      (e) => {
        this.is_loading = false;
      }
    );
  }

  compareWithList(o1, o2) {
    return o1.id == o2.id;
  }

  navigagteToMonthlySalariesEvent() {
    this.navigateToAnotherCashflowCategories.emit(SALARIO_MENSUALES_ID);
  }

  pagoPercantitadCoechadaTrigger(event) {
    this.pagoPercantitadCoechadaTriggered.emit(event);
  }

  checkAvailbleObjectList(objectList) {
    if (
      (Array.isArray(objectList) && objectList.length > 0) ||
      objectList?.qty > 0
    ) {
      this.showFormChange.emit(true);
    } else {
      this.showFormChange.emit(false);
    }
  }

  configForm() {
    this.formConfing.show_add_thrid_model_button =
      !this.is_loading &&
      this.is_list &&
      ((this.objectList && this.objectList.length == 0) || !this.objectList) &&
      this.model_required_object?.model_name &&
      this.model_required_object?.model_name.includes('cashflow');
    if (
      !this.formConfing?.show_add_thrid_model_button &&
      this.model_required_object?.model_name &&
      this.model_required_object?.model_name.includes('cashflow')
    )
      this.checkAvailbleObjectList(this.objectList);
  }

  async addFamilyMember() {
    let componentCrop = {
      openedFromCashflow: true,
    };
    let modal = await this.toastUtils.modalBuilder({
      component: ModalPageComponent,
      componentCrop,
      cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
    });

    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.person) {
      if (this.objectList) this.objectList.push(data?.person);
      else this.objectList = [data?.person];
      this.selectedFamilyMember = data?.person.name;
    }
  }

  async familyMemberChanged(value) {
    let componentProps = {
      data: this.objectList,
      component: 'personal-input',
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style'
    );
    const { data } = await popover.onWillDismiss();
    if (data?.data) {
      const person = data.data;
      data.data.is_owner
        ? (this.cashflow.morphable_type = 'Owner')
        : (this.cashflow.morphable_type = MORPH_Family_member_personal);

      this.cashflow.morphable_id = data.data.id;
      this.selectedFamilyMember = data.data.name;
      this.cashflowMorphableChange.emit(this.cashflow);
    }
  }

  emitFormChang(event) {
    this.showFormChange.emit(event);
  }
}
