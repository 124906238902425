import { HttpClient } from '@angular/common/http';
import Owner from '../models/owner.model';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OwnerAPI {
  base_url = environment.appRoot + '/api/';
  headers;
  constructor(private http: HttpClient) {}

  deleteAccess(id) {
    const url = this.base_url + dev.endpoint.owner.deleteAccess;
    const formData = new FormData();
    formData.append('id', id);
    return this.http.post(url, formData);
  }

  storeOwnerDate(owner: Owner) {
    const url = this.base_url + dev.endpoint.owner.create_user_data;
    const formData = new FormData();
    formData.append('first_name', owner.first_name);
    formData.append('last_name', owner.last_name);
    formData.append('birth_date', owner.birth_date);
    formData.append('prefix', owner.prefix);
    formData.append('mobile_number', owner.mobile_number);
    formData.append('sex', owner.sex);
    formData.append('country_id', owner.country_id);
    return this.http.post(url, formData);
  }

  setCheckpointRoute(route: string) {
    const url = this.base_url + dev.endpoint.owner.set_checkpoint_route;
    const formData = new FormData();
    formData.append('route', route);
    return this.http.post(url, formData);
  }

  updateOwnerData(owner: Owner): Observable<any> {
    const url = this.base_url + dev.endpoint.owner.update_owner;
    const formData = new FormData();
    formData.append('first_name', owner.first_name);
    formData.append('last_name', owner.last_name);
    formData.append('birth_date', owner.birth_date);
    formData.append('prefix', owner.prefix);
    formData.append('mobile_number', owner.mobile_number);
    formData.append('sex', owner.sex);
    formData.append('country_id', owner.country_id);
    formData.append('preferred_language', owner.preferred_language);
    if (owner?.blobFile) {
      formData.append(
        'image',
        owner?.blobFile,
        new Date().getUTCMilliseconds().toString()
      );
    }
    formData.append('id', owner.id);
    if (owner?.profile_info)
      formData.append('profile_info', owner.profile_info);

    return this.http.post(url, formData);
  }

  storeOwnerProfilePhoto(file: Blob) {
    const url = this.base_url + dev.endpoint.owner.store_user_image;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    return this.http.post(url, formData);
  }

  updateOwnerPhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.owner.updateOwnerPhoto;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.http.post(url, formData);
  }

  updateOwnerAndFarmCluster(data, profile_info) {
    const { farmId, clustId, clusterNumber, ambassadorId, owner_id } = data;
    const url =
      this.base_url + dev.endpoint.owner.update_owner_and_farm_cluster;
    const formDate = new FormData();
    formDate.append('profile_info', profile_info);
    formDate.append('id', farmId);
    if (owner_id && clusterNumber && clustId && ambassadorId) {
      formDate.append('owner_id', owner_id);
      formDate.append('cluster_number', clusterNumber);
      formDate.append('cluster_id', clustId);
      formDate.append('ambassador_id', ambassadorId);
    }
    return this.http.post(url, formDate);
  }

  deleteOwnerPhoto() {
    const url = this.base_url + dev.endpoint.owner.deleteOwnerPhoto;
    return this.http.get(url);
  }

  getOwnerById() {
    const url = this.base_url + dev.endpoint.owner.get_owner_by_id;
    return this.http.get(url,{withCredentials:true});
  }

  updateUserMobileAndAccess(
    new_number,
    new_prefix,
    new_network,
    auth_id,
    owner_id,
    msg
  ) {
    const url = this.base_url + dev.endpoint.owner.updateUserMobileAndAccess;
    const formData = new FormData();
    formData.append('auth_id', auth_id);
    formData.append('new_number', new_number);
    formData.append('new_prefix', new_prefix);
    formData.append('new_network', new_network);
    formData.append('owner_id', owner_id);
    formData.append('message', msg);
    return this.http.post(url, formData);
  }
  updateFamilyMemberMobileAndAccess(new_number, new_prefix, auth_id, owner_id) {
    const url =
      this.base_url + dev.endpoint.owner.updateFamilyMemberMobileAndAccess;
    const formData = new FormData();
    formData.append('auth_id', auth_id);
    formData.append('new_number', new_number);
    formData.append('new_prefix', new_prefix);
    formData.append('owner_id', owner_id);
    return this.http.post(url, formData);
  }
  confirmedUpdateUserMobile(
    new_number,
    new_prefix,
    new_network,
    auth_id,
    owner_id
  ) {
    const url = this.base_url + dev.endpoint.owner.confirmed_update_user_mobile;
    const formData = new FormData();
    formData.append('auth_id', auth_id);
    formData.append('new_number', new_number);
    formData.append('new_network', new_network);
    formData.append('new_prefix', new_prefix);
    formData.append('owner_id', owner_id);
    return this.http.post(url, formData);
  }
  getAccessByOwners() {
    const url = this.base_url + dev.endpoint.owner.getAccessByOwners;
    return this.http.get(url);
  }

  logout() {
    const url = this.base_url + dev.endpoint.owner.logout;
    return this.http.get(url);
  }

  addAccessToUser(prefix, phone, message, familyId) {
    const url = this.base_url + dev.endpoint.owner.addAccessToUser;
    const formData = new FormData();
    formData.append('prefix', prefix);
    formData.append('mobile_number', phone);
    formData.append('family_member_id', familyId);
    formData.append('message', message);
    return this.http.post(url, formData);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  deleteAccount() {
    const url = this.base_url + dev.endpoint.owner.deleteAccount;
    return this.http.get(url);
  }
}
